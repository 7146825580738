import React from "react";
import { Row, Col } from "react-flexbox-grid";

interface GraphBarProps {
  width: number;
  // styles: positive, negative
  style: string;
}

function GraphBar(props: GraphBarProps) {
  return (
    <Row
      className={
        props.style ? "graphBarContainer " + props.style : "graphBarContainer"
      }
    >
      <Col xs={12} style={{ display: "flex" }}>
        <h5>{props.width}%</h5>
        <div className="graphBar">
          <span style={{ width: props.width + "%" }}></span>
        </div>
      </Col>
    </Row>
  );
}

export default GraphBar;
