import { useState } from "react";
import Modal from "./Modal";
import { Row, Col } from "react-flexbox-grid";
import Button from "./Button";

interface UpgradeAccountModalProps {
  onClose: () => void;
  onConfirm: () => void;
  isActive: boolean;
  title: string;
  message: string;
}

const UpgradeAccountModal = (props: UpgradeAccountModalProps) => {
  return (
    <Modal
      modalTitle={props.title}
      onClose={props.onClose}
      isActive={props.isActive}
    >
      <Row>
        <Col xs={12}>
          <h3 style={{ marginBottom: 20 }}>{props.message}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Button fullWidth onClick={props.onClose}>
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button fullWidth preset="formPrimary" onClick={props.onConfirm}>
            Upgrade
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpgradeAccountModal;
