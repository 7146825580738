import { auth, doFetchUser } from "../Firebase";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { IUser } from "../../models";
import store from "../../store";
import { setUser } from "../../reducers/User/user.actions";

export default function ProtectedRoute({ children }: any) {
  const [pending, setPending] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<User | null>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setCurrentUser(user as User);

        //the user is authenticated, let's get the user details
        // doFetchUser()
        //   .then((user: IUser) => {
        //     store.dispatch(setUser(user));

        //   })
        //   .catch((e) => console.log(e));
        setPending(false);
      },
      (error) => {
        setPending(false);
      }
    );

    return unsubscribe; // <-- clean up subscription
  }, []);

  if (pending) return null;
  return currentUser ? children : <Navigate replace to="/login" />;
}
