import { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Slider as AntdSlider, InputNumber } from "antd";
import { SliderMarks } from "antd/lib/slider";

interface SliderProps {
  title?: string;
  returnRange: (range: number[]) => void;
}

function Slider(props: SliderProps) {
  const [range, setRange] = useState<number[]>([1, 100]);
  const [leftSliderNumber, setLeftSliderNumber] = useState<number>(0);
  const [rightSliderNumber, setRightSliderNumber] = useState<number>(100);

  const marks: SliderMarks = {
    "-100": "Negative",
    0: "0",
    "100": "Positive",
  };

  useEffect(() => {
    setRange([leftSliderNumber, rightSliderNumber]);
  }, [leftSliderNumber, rightSliderNumber]);

  useEffect(() => {
    props.returnRange(range);
  }, [range]);

  const onAfterChange = (theRange: number[]) => {
    setRange(theRange);
  };

  return (
    <>
      {props.title && (
        <h2
          style={{
            fontSize: 18,
            marginBottom: -5,
            marginTop: -8,
            display: "block",
          }}
        >
          {props.title}
        </h2>
      )}
      <div style={{ display: "flex" }}>
        <InputNumber
          min={-100}
          controls={false}
          max={100}
          value={range[0]}
          onChange={(e: any) => setLeftSliderNumber(e)}
        />
        <AntdSlider
          range
          marks={marks}
          min={-100}
          defaultValue={[0, 100]}
          value={[range[0], range[1]]}
          onChange={onAfterChange}
          tooltip={{ formatter: null }}
        />
        <InputNumber
          min={-100}
          max={100}
          controls={false}
          value={range[1]}
          onChange={(e: any) => setRightSliderNumber(e)}
        />
      </div>
    </>
  );
}

export default Slider;
