export type PlaybookPosition = -3 | -2 | -1 | 0 | 1 | 2 | 3;

export default interface PlaybookPositions {
  [key: string]: PlaybookPosition;
  // ABORTIFACIENTS?: PlaybookPosition
  // ACCESS_AND_AFFORDABILITY_BIC?: PlaybookPosition
  // AIR_QUALITY_BIC?: PlaybookPosition
  // ALCOHOL?: PlaybookPosition
  // ALCOHOL_5PERCENT_REVENUE?: PlaybookPosition
  // ANIMAL_TESTING?: PlaybookPosition
  // BUSINESS_ETHICS_BIC?: PlaybookPosition
  // BUSINESS_MODEL_RESILIENCE_BIC?: PlaybookPosition
  // CANNABIS_CBD_ONLY?: PlaybookPosition
  // CANNABIS_CULTIVATION_PROCESSING?: PlaybookPosition
  // CANNABIS_PHARMACEUTICAL?: PlaybookPosition
  // CANNABIS_RETAIL_THC?: PlaybookPosition
  // CONTRACEPTIVES_BARRIER?: PlaybookPosition
  // CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC?: PlaybookPosition
  // CUSTOMER_PRIVACY_BIC?: PlaybookPosition
  // CUSTOMER_WELFARE_BIC?: PlaybookPosition
  // COMPETITIVE_BEHAVIOR_BIC?: PlaybookPosition
  // DATA_SECURITY_BIC?: PlaybookPosition
  // ECOLOGICAL_IMPACTS_BIC?: PlaybookPosition
  // EMBRYONIC_STEM_CELL?: PlaybookPosition
  // EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC?: PlaybookPosition
  // EMPLOYEE_HEALTH_AND_SAFETY_BIC?: PlaybookPosition
  // ENERGY_MANAGEMENT_BIC?: PlaybookPosition
  // EXPLOITATION?: PlaybookPosition
  // GAMBLING?: PlaybookPosition
  // GAMBLING_5PERCENT_REVENUE?: PlaybookPosition
  // GHG_EMISSIONS_BIC?: PlaybookPosition
  // GMO_PRODUCTS?: PlaybookPosition
  // HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC?: PlaybookPosition
  // INVITRO_FERTILIZATION?: PlaybookPosition
  // LABOR_PRACTICES_BIC?: PlaybookPosition
  // LGBTA_PROMOTION?: PlaybookPosition
  // MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC?: PlaybookPosition
  // MATERIALS_SOURCING_AND_EFFICIENCY_BIC?: PlaybookPosition
  // NUCLEAR?: PlaybookPosition
  // NUCLEAR_5PERCENT_REVENUE?: PlaybookPosition
  // PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC?: PlaybookPosition
  // PORNOGRAPHY?: PlaybookPosition
  // PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC?: PlaybookPosition
  // PRODUCT_QUALITY_AND_SAFETY_BIC?: PlaybookPosition
  // SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC?: PlaybookPosition
  // STATE_OWNED_ENTERPRISE?: PlaybookPosition
  // SUPPLY_CHAIN_MANAGEMENT_BIC?: PlaybookPosition
  // SYSTEMIC_RISK_MANAGEMENT_BIC?: PlaybookPosition
  // TOBACCO?: PlaybookPosition
  // TOBACCO_5PERCENT_REVENUE?: PlaybookPosition
  // WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC?: PlaybookPosition
  // WATER_AND_WASTEWATER_MANAGEMENT_BIC?: PlaybookPosition
  // WEAPONS_COMPONENTS?: PlaybookPosition
  // WEAPONS_COMPONENTS_5PERCENT_REVENUE?: PlaybookPosition
  // WEAPONS_MILITARY?: PlaybookPosition
}

export const categories = {
  ABORTIFACIENTS: "Abortifacients",
  ABORTION_PROMOTION: "Abortion Activism",
  ABORTION_SERVICES: "Abortion Services",
  ACCESS_AND_AFFORDABILITY_BIC: "Product Access and Affordability",
  AIR_QUALITY_BIC: "Air Quality",
  ALCOHOL: "Alcohol",
  ANIMAL_TESTING: "Animal Testing",
  BUSINESS_ETHICS_BIC: "Business Ethics",
  BUSINESS_MODEL_RESILIENCE_BIC: "Business Resilience",
  CANNABIS_CBD_ONLY: "Cannabis: CBD Only",
  CANNABIS_CULTIVATION_PROCESSING: "Cannabis: Cultivation/Processing",
  CANNABIS_PHARMACEUTICAL: "Cannabis: Pharmaceutical",
  CANNABIS_RETAIL_THC: "Cannabis: Retail THC",
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: "Climate Change Mitigation",
  CONTRACEPTIVES_BARRIER: "Contraceptives: Barrier",
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: "Critical Risk Management",
  CUSTOMER_PRIVACY_BIC: "Customer Privacy",
  CUSTOMER_WELFARE_BIC: "Customer Welfare",
  DATA_SECURITY_BIC: "Data Security",
  EMBRYONIC_STEM_CELL: "Embryonic Stem Cells",
  // EMBRYONIC_STEM_CELL_DISTRIBUTING: "Embryonic Stem Cell Distributing",
  // EMBRYONIC_STEM_PRODUCTION: "Embryonic Stem Cell Production",
  // EMBRYONIC_STEM_RESEARCH: "Embryonic Stem Cell Research",
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: "Employee Wellbeing",
  ENERGY_MANAGEMENT_BIC: "Energy Management",
  LABOR_PRACTICES_BIC: "Ethical Labor",
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: "Ethical Sales Practices",
  SUPPLY_CHAIN_MANAGEMENT_BIC: "Ethical Supply Chain",
  EXPLOITATION: "Exploitation",
  COMPETITIVE_BEHAVIOR_BIC: "Fair Competition",
  GAMBLING: "Gambling",
  GHG_EMISSIONS_BIC: "GHG Emissions",
  GMO_PRODUCTS: "GMO Products",
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: "Hiring Ethics",
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: "Human Rights",
  INVITRO_FERTILIZATION: "In-Vitro Fertilization",
  LGBTA_PROMOTION: "LGBT Activism",
  ECOLOGICAL_IMPACTS_BIC: "Low Ecological Impact",
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: "Materials Efficiency",
  NUCLEAR: "Nuclear Energy",
  PORNOGRAPHY: "Pornography",
  PRODUCT_QUALITY_AND_SAFETY_BIC: "Product Safety",
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: "Product Sustainability",
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC:
    "Regulatory Adherence",
  STATE_OWNED_ENTERPRISE: "State Owned Enterprise",
  SYSTEMIC_RISK_MANAGEMENT_BIC: "Systemic Risk Mitigation",
  TOBACCO: "Tobacco",
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: "Waste and Hazmat Management",
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: "Water Conservation",
  // WEAPONS_COMPONENTS: "Weapons and Components",
  WEAPONS_MILITARY: "Weapons: Military",
};
