import { useEffect, useState } from "react";
import { StandalonePlaybook } from "./StandalonePlaybook";
import { doFetchClientPlaybook } from "../Firebase";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";

function StandalonePlaybookContainer() {
  const [data, setData] = useState<{} | null>();
  const { liveLinkId } = useParams();

  useEffect(() => {
    doFetchClientPlaybook(liveLinkId as string).then(
      (res) => res && setData(res)
    );
  }, []);

  return data ? (
    <>
      <ToastContainer position="bottom-center" hideProgressBar />
      <div style={{ overflowX: "hidden" }}>
        {<StandalonePlaybook clientData={data as any} />}
      </div>
    </>
  ) : (
    <></>
  );
}

export default StandalonePlaybookContainer;
