import { Row, Col } from "react-flexbox-grid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

interface ButtonProps {
  preset?: string;
  mini?: boolean;
  style?: any;
  fullWidth?: boolean;
  iconWidth?: boolean;
  disabled?: boolean;
  stopPropagation?: boolean;
  leftAlign?: boolean;
  children: React.ReactNode;
  type?: string;
  stickyHover?: boolean;
  onClick?: () => void;
}

export default function Button(props: ButtonProps) {
  let stickyHoverClass: string;
  props.stickyHover && (stickyHoverClass = "hover");

  const renderType = () => {
    if (props.type !== null) {
      return "type=" + props.type;
    }
  };

  const renderClasses = () => {
    switch (true) {
      case props.mini:
        return "mini button" + " " + props.preset + " " + stickyHoverClass;
        break;
      case props.fullWidth:
        return "fullWidth button" + " " + props.preset + " " + stickyHoverClass;
        break;
      case !props.mini && !props.fullWidth:
        return "button" + " " + props.preset + " " + stickyHoverClass;
    }
  };

  return (
    <>
      <button
        disabled={props.disabled}
        style={{
          border: "none",
          borderRadius: 4,
          textAlign: props.leftAlign ? "left" : "center",
          boxShadow: "none",
          position: "relative",
          minWidth:
            props.iconWidth || props.mini || props.fullWidth
              ? "fit-content"
              : 170,
          width: props.fullWidth
            ? "100%"
            : props.iconWidth || props.mini
            ? "fit-content"
            : "auto",
          ...props.style,
        }}
        className={renderClasses()}
        onClick={(e) => {
          props.stopPropagation && e.stopPropagation();
          props.onClick && props.onClick();
        }}
        {...(renderType() as any)}
      >
        {props.children}
      </button>
    </>
  );
}
