import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import Button from "../../Button";
import {
  db,
  doCreateOrUpdateModel,
  doCreatePortfolio,
  doUpdatePortfolio,
} from "../../Firebase";
import Modal from "../../Modal";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { capitalizeFirstLetter } from "../../../constants";
import { IClient, Model, Portfolio } from "../../../models";
import { PortfolioTemplate } from "../../Portfolios";

import { collection, onSnapshot } from "firebase/firestore";
import SelectMenu from "../../SelectMenu";

interface CreatePortfolioModalProps {
  isActive: boolean;
  onClose: (callbackData?: any) => void;
  client?: IClient | null;
  portfolio?: Portfolio;
  template: any;
  edit?: boolean;
  onCreatePortfolioCallback?: (client: IClient) => void;
}

export function CreatePortfolioModal(props: CreatePortfolioModalProps) {
  const [portfolioName, setPortfolioName] = useState("");
  const [models, setModels] = useState<Array<Model>>([]);
  const [modelFilter, setModelFilter] = useState("");

  const [selectedModel, setSelectedModel] = useState<Model | null>(null);

  useEffect(() => {
    onSnapshot(collection(db, `models`), (snapshot) => {
      setModels(snapshot.docs.map((doc) => doc.data() as Model));
      const models: Model[] = snapshot.docs.map((doc) => doc.data() as Model);
    });
  }, []);

  const { edit } = props;

  const templateName = capitalizeFirstLetter(props.template);

  const handleSubmit = () => {
    const portfolioData: Portfolio = {
      cash: props.portfolio
        ? props.portfolio.cash
        : selectedModel
        ? selectedModel.cash
        : 0,
      holdings: props.portfolio
        ? props.portfolio.holdings
        : selectedModel
        ? selectedModel.holdings
        : [],
      name: portfolioName,
      id: props.portfolio ? props.portfolio.id : uuidv4(),
    };
    if (props.template === PortfolioTemplate.MODEL) {
      let model: Model = {
        ...portfolioData,
        tags: ["my-models"],
      };
      doCreateOrUpdateModel(model)
        .then(() => {
          toast.success(`${templateName}` + `${edit ? " updated" : " created"}`, {autoClose: 1000 });
          if (props.onCreatePortfolioCallback && props.client)
            props.onCreatePortfolioCallback(props.client);
          props.onClose(props.client);
        })
        .catch((error) => {
          console.log(error);
          toast.error(`${templateName} creation failed`, { autoClose: 1000 });
        });
    } else {
      if (edit) {
        props.client &&
          doUpdatePortfolio(props.client, portfolioData, props.template)
            .then(() => {
              toast.success(`${templateName} updated`, {autoClose: 1000 });
              if (props.onCreatePortfolioCallback && props.client)
                props.onCreatePortfolioCallback(props.client);
              props.onClose(props.client);
            })
            .catch((e) => {
              toast.error(`${templateName} update failed`, {autoClose: 1000 });
            });
      } else {
        props.client &&
          doCreatePortfolio(props.client, portfolioData, props.template)
            .then(() => {
              toast.success(`${templateName} created`, {autoClose: 1000 });
              if (props.onCreatePortfolioCallback && props.client)
                props.onCreatePortfolioCallback(props.client);
              props.onClose();
            })
            .catch(() => {
              toast.error(`${templateName} creation failed`, { autoClose: 1000 });
              props.onClose();
            });
      }
    }

    setPortfolioName("");
  };

  useEffect(() => {
    if (props.portfolio) setPortfolioName(props.portfolio.name);
  }, [props.portfolio]);

  return (
    <Modal
      modalTitle={edit ? `Edit ${templateName}` : `Add New ${templateName}`}
      isActive={props.isActive}
      onClose={() => (props.onClose(), setPortfolioName(""))}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="portfolio-name">{`${templateName} Name:`}</label>
              <input
                type="text"
                id="portfolio-name"
                value={portfolioName}
                onChange={(e) => setPortfolioName(e.target.value)}
                placeholder={props.portfolio ? portfolioName : "-"}
              />
            </div>
          </Col>
        </Row>
        {props.template === "model" ||
          (!edit && (
            <Row>
              <Col xs={12}>
                <SelectMenu
                  title="Create From Model"
                  onSearch={(value) => setModelFilter(value)}
                  fullWidth
                  onClick={(modelId: string) => {
                    const model = models.find((m) => m.id === modelId);
                    if (model) setSelectedModel(model);
                  }}
                  options={models
                    .filter((model: Model) =>
                      model.name
                        .toLowerCase()
                        .includes(modelFilter.toLowerCase())
                    )
                    .map((model) => ({
                      title: model.name,
                      value: model.id,
                    }))}
                />
              </Col>
            </Row>
          ))}
      </form>
      <Row style={{ paddingTop: 30 }}>
        <Col xs={12} sm={6}>
          <Button
            fullWidth
            onClick={() => (
              props.onClose(), setPortfolioName(""), setSelectedModel(null)
            )}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            preset="formPrimary"
            fullWidth
            onClick={() => (handleSubmit(), setSelectedModel(null))}
          >
            {edit ? "Save" : "Add"}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
