import React, { useState, useEffect } from "react";
import LoadingIcon from "../images/dual-ring-loading-grey.svg";

interface DollarInputProps {
  onSave: (value: number, portfolio: any, holdingId: number) => void;
  onChange?: (value: number) => void;
  isLoading?: boolean;
  onLoadValue?: any;
  portfolio?: any;
  holdingId?: any;
  holdingTicker?: string;
  autoFocus?: boolean;
  isAddTickerInput?: boolean;
  disabled?: boolean;
}

export default function DollarInput(props: DollarInputProps) {
  const [value, setValue] = useState(props.onLoadValue);
  // const textInput: React.RefObject<HTMLInputElement> =
  //   props.ref  useRef(null);

  const addCommaSeparators = (inputValue: string) => {
    setValue(
      inputValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  };

  const confirmOnSave = (target: any) => {
    const cleanedValue = parseInt(target.value.replaceAll(",", ""));
    if (props.onLoadValue !== cleanedValue || props.isAddTickerInput) {
      props.onSave(cleanedValue, props.portfolio, props.holdingId);
    }
  };

  useEffect(() => {
    props.onLoadValue !== null && addCommaSeparators(props.onLoadValue);
  }, [props.onLoadValue, props.portfolio, props.holdingId]);

  const handleFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    (event.target as HTMLInputElement).select();
  };

  return (
    <>
      {props.isLoading ? (
        <img style={{ marginLeft: 10 }} width="30" src={LoadingIcon} />
      ) : (
        <>
          <span className="dollarPrefix">$</span>
          <input
            type="text"
            placeholder="0"
            disabled={props.disabled}
            className="dollarAmount"
            autoFocus={props.autoFocus}
            value={props.isLoading ? "..." : value}
            onChange={(e) => {
              addCommaSeparators(e.target.value);
              props.onChange &&
                props.onChange(parseInt(e.target.value.replaceAll(",", "")));
            }}
            onBlur={(e) => {
              !props.isAddTickerInput && confirmOnSave(e.target);
            }}
            onKeyDownCapture={(k: any) => {
              if (k.keyCode === 13) {
                !props.isAddTickerInput
                  ? k.target.blur()
                  : confirmOnSave(k.target);
              }
            }}
            onClick={handleFocus}
            style={{
              paddingTop: 5,
              minWidth: "fit-content",
              width: "100%",
            }}
          />
        </>
      )}
    </>
  );
}
