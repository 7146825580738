import { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import Button from "../../Button";
import { doUpdateUser } from "../../Firebase";
import Modal from "../../Modal";

import { toast } from "react-toastify";
import { validatePhone } from "../../../constants";
import { IUser } from "../../../models";

interface EditUserModalProps {
  isActive: boolean;
  onClose: (callbackData?: any) => void;
  user: IUser;
}

export function EditUserModal(props: EditUserModalProps) {
  const { onClose, isActive, user } = props;
  const [firstName, setFirstName] = useState(user.firstName ?? "");
  const [lastName, setLastName] = useState(user.lastName ?? "");
  const [userEmail, setUserEmail] = useState(user.email);
  const [userPhoneNumber, setUserPhoneNumber] = useState<number | undefined>(
    user.phone
  );
  const [userEndPageDisclaimer, setUserEndPageDisclaimer] = useState(
    user.endPageDisclaimer
  );
  const [userFooterDisclaimer, setUserFooterDisclaimer] = useState(
    user.footerDisclaimer
  );
  const [userFirm, setUserFirm] = useState(user.firm);

  const [phoneError, setPhoneError] = useState<boolean>(false);

  const handlePhoneChange = (e: any) => {
    const validatedPhoneNumber = validatePhone(
      e,
      true,
      () => {
        setPhoneError(false);
      },
      () => {
        setPhoneError(true);
      }
    );

    if (validatedPhoneNumber != null) {
      setUserPhoneNumber(validatedPhoneNumber);
    }
  };

  const handleSubmit = () => {
    if (!phoneError && userPhoneNumber !== undefined) {
      const userData: IUser = user;
      user.firstName = firstName;
      user.lastName = lastName;
      user.email = userEmail;
      user.endPageDisclaimer = userEndPageDisclaimer;
      user.footerDisclaimer = userFooterDisclaimer;
      user.firm = userFirm;
      user.phone = userPhoneNumber;

      doUpdateUser(userData)
        .then(() => {
          toast.success("User updated", {autoClose: 1000 });
          onClose && onClose();
        })
        .catch((e) => {
          toast.error("User update failed", {autoClose: 1000 });
        });
    } else {
      setPhoneError(true);
      return false;
    }
  };

  return (
    <Modal
      modalTitle={"Edit Account Details"}
      isActive={props.isActive}
      onClose={props.onClose}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="first-name">First Name:</label>
              <input
                type="text"
                id="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="last-name">Last Name:</label>
              <input
                type="text"
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="user-email">Email:</label>
              <input
                type="email"
                id="user-email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="user-phone">Phone Number:</label>
              <input
                type="tel"
                id="user-phone"
                name="phone"
                placeholder={userPhoneNumber?.toString()}
                className={phoneError ? "error" : ""}
                onChange={(e) => handlePhoneChange(e.target.value)}
              />
              {phoneError ? (
                <p className="input-message error">
                  Mobile Number must be 10 digits and correctly formatted.
                </p>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="user-firm">Firm:</label>
              <input
                type="text"
                id="user-firm"
                value={userFirm}
                onChange={(e) => setUserFirm(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer textareaContainer">
              <label htmlFor="user-footerdisclaimer">Footer Disclaimer:</label>
              <textarea
                id="user-footerdisclaimer"
                value={userFooterDisclaimer}
                style={{ minHeight: "100px" }}
                onChange={(e) => setUserFooterDisclaimer(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer textareaContainer">
              <label htmlFor="user-endpagedisclaimer">
                Last Page Disclaimer:
              </label>
              <textarea
                id="user-endpagedisclaimer"
                value={userEndPageDisclaimer}
                onChange={(e) => setUserEndPageDisclaimer(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
        </Row>
      </form>
      <Row>
        <Col xs={12} sm={6}>
          <Button fullWidth onClick={props.onClose}>
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button preset="formPrimary" fullWidth onClick={handleSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
