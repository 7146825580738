import { Row, Col } from "react-flexbox-grid";
import { useState } from "react";
import { useNavigate } from "react-router";
import ChevronDown from "../images/chevron-down.svg";
import Button from "../components/Button";
import { isMobile, isTablet } from "react-device-detect";

interface ContextMenuProps {
  icon: string;
  iconWidth: string;
  alignRight?: boolean;
  options: Array<{ title: string; value: string }>;
  onClick?: (value: string) => void;
}

export default function ContextMenu(props: ContextMenuProps) {
  const [viewMenu, setViewMenu] = useState(false);
  return (
    <div
      className="contextMenuContainer"
      style={{ width: 84 }}
      onMouseDown={() => {
        if (!isMobile || !isTablet) !viewMenu && setViewMenu(true);
      }}
      onMouseLeave={() => {
        setViewMenu(false);
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        onTouchStart={() => {
          viewMenu ? setViewMenu(false) : setViewMenu(true);
        }}
      >
        <Button preset="transparent" iconWidth stickyHover={viewMenu}>
          <img
            src={props.icon}
            width={props.iconWidth}
            style={{
              marginRight: 8,
              marginLeft: 8,
              position: "relative",
              bottom: 1,
            }}
          />
        </Button>
      </div>
      {!viewMenu ? (
        <></>
      ) : (
        <ul>
          {props.options.map((option) => (
            <li key={option.value}>
              <a
                onClick={(e) => {
                  if (props.onClick) props.onClick(option.value);
                  setViewMenu(false);
                }}
              >
                {option.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
