import { Row, Col } from "react-flexbox-grid";
import { css, hover } from "glamor";
import { IClient, Portfolio } from "../../models";
import { IPortfolioAPIResponse } from "../../models/Resonate/api";
import DollarInput from "../DollarInput";
import { doUpdatePortfolio } from "../Firebase";
import { themeState } from "../../contexts/ThemeContext";

const holdingRow = css({
  ":hover div.closeHover": { display: "block !important" },
});

interface CashProps {
  portfolio: Portfolio;
  holdings: {
    [key: string]: IPortfolioAPIResponse;
  };
  client: IClient;
  disabled?: boolean;
  onSaveCallback: () => void;
  template: any;
}

export default function Cash(props: CashProps) {
  const { portfolio, holdings, disabled } = props;

  const renderCashAssetPercentage = () => {
    const cashAssetPercentage = (
      (portfolio.cash / holdings[portfolio.id].totalMonetaryValue) *
      100
    ).toFixed(2);

    switch (cashAssetPercentage) {
      case "NaN":
        return "0";
      case "Infinity":
        return "0";
      default:
        return cashAssetPercentage;
    }
  };

  return (
    <div className="cardRowContent">
      <Row {...holdingRow}>
        <Col
          xs={2}
          sm={2}
          md={themeState.theme === "resonate" ? 4 : 6}
          className="holdingTitle"
        >
          <h3>
            <strong>CASH</strong>
          </h3>
        </Col>
        <Col xs={6} sm={3} md={2} xl={2}>
          <div
            className="inputContainer dollarContainer"
            style={{ marginBottom: 0 }}
          >
            <DollarInput
              onLoadValue={portfolio.cash}
              portfolio={portfolio}
              disabled={disabled}
              holdingId={0}
              onSave={(v) => {
                let currentPortfolio = portfolio;
                currentPortfolio.cash = v;
                doUpdatePortfolio(
                  props.client,
                  currentPortfolio,
                  props.template
                );
                props.onSaveCallback();
              }}
            />
          </div>
        </Col>
        <Col xl={1} sm={1} xs={6} className="hideOnMobile hideOnTablet">
          <h5>{renderCashAssetPercentage()}%</h5>
        </Col>
        <Col xs={8} sm={3}></Col>
        <Col
          xs={4}
          sm={1}
          style={{
            textAlign: "right",
            paddingRight: 30,
            display: "none",
            opacity: 0.4,
          }}
          className="closeHover"
        ></Col>
      </Row>
    </div>
  );
}
