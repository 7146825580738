import { motion, AnimatePresence } from "framer-motion";

interface ViewIssuesProps {
  issues?: any;
  ticker?: any;
}

function ViewIssues(props: ViewIssuesProps) {
  return (
    <AnimatePresence>
      {props.ticker ? (
        <div className="issuesContainer">
          <motion.div
            initial={{ y: -5, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.1,
              },
            }}
            exit={{}}
          >
            {props.issues
              .sort((a: any, b: any) => {
                const aDate = new Date(a.date);
                const bDate = new Date(b.date);
                if (aDate > bDate) {
                  return -1;
                } else if (aDate < bDate) {
                  return 1;
                }
                return 0;
              })
              .map((issue: any, i: number) => (
                <>
                  {i === 0 && <h2 style={{ marginBottom: 0 }}>{issue.name}</h2>}
                  <div className="issueBox">
                    <>
                      <h3>
                        <span>
                          {issue.date
                            ? new Date(issue.date).toLocaleDateString()
                            : "No Date"}
                        </span>
                      </h3>
                      {issue.detail ? <p>{issue.detail}</p> : ""}
                    </>
                    {issue.url && (
                      <>
                        {issue.source ? (
                          <h4 style={{ marginBottom: 10 }}>
                            Source: {issue.source}
                          </h4>
                        ) : (
                          ""
                        )}
                        <h4 className="issueUrlCondensed">
                          <a href={issue.url} target="new">
                            {issue.url}
                          </a>
                        </h4>
                      </>
                    )}
                  </div>
                </>
              ))}
          </motion.div>
        </div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  );
}

export default ViewIssues;
