import { useEffect } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Button from "../components/Button";
import { doSignInWithEmailAndPassword } from "../components/Firebase";
import { themeState } from "../contexts/ThemeContext";
import InsightLogo from "../images/Insight/insight-logo.svg";
import Logo from "../images/scoreboard-logo.svg";

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    themeState.theme === "resonate"
      ? (document.title = "Resonate - Login")
      : (document.title = "InspireInsight.com - Login");
  }, []);

  const handleLogin = (e: any) => {
    e.preventDefault();
    const username = e.target.elements[0].value;
    const password = e.target.elements[1].value;
    doSignInWithEmailAndPassword(username, password, navigate)
      .then((response) => {})
      .catch((e) => {
        toast.error("Invalid username or password", { autoClose: 1000 });
      });
  };

  return (
    <>
      <div className="fullWidthContainer whiteBg">
        <ToastContainer position="bottom-center" hideProgressBar />
        <div className="fullWidthContent">
          <Row>
            <Col>
              <a href="#">
                <img
                  src={themeState.theme === "resonate" ? Logo : InsightLogo}
                  className="logoImg"
                />
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div id="mainContent" className="fullWidthContainer">
        <div className="fullWidthContent">
          {/* <Row>{auth.currentUser?.uid}</Row> */}

          <form onSubmit={handleLogin}>
            <Row>
              <Col mdOffset={3} md={6} xs={12}>
                <h2>Login</h2>
                <div className="inputContainer">
                  <label htmlFor="login-email">Email:</label>
                  <input id="login-email" type="text" name="email" />
                </div>
                <div className="inputContainer">
                  <label htmlFor="login-password">Password:</label>
                  <input id="login-password" type="password" name="password" />
                </div>
                <Row>
                  <Col xs={12} sm={6}>
                    <Button type="submit" fullWidth preset="formPrimary">
                      Login
                    </Button>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Button
                      onClick={() => {
                        navigate("/register");
                      }}
                      fullWidth
                    >
                      Register
                    </Button>
                  </Col>
                </Row>
                <Row style={{ textAlign: "center", paddingTop: 20 }}>
                  <Col xs={12} style={{ textAlign: "center" }}>
                    <span>
                      Forgot password? <a href="/forgot-password">Reset</a>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
