import { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import { isMobile, isTablet } from "react-device-detect";
import { Portfolio } from "../../models";
import give50logos from "../../images/give50/give50logos.svg";
import give50logosDark from "../../images/give50/give50logos-dark.svg";
import backdrop from "../../images/give50/impactCalculator/give50backdrop.jpeg";
import give50 from "../../images/give50/impactCalculator/give50logo.svg";
import cleanwater from "../../images/give50/impactCalculator/cleanWater.png";
import bibles from "../../images/give50/impactCalculator/bibles.png";
import shoebox from "../../images/give50/impactCalculator/shoebox.png";
import gospel from "../../images/give50/impactCalculator/gospel.png";
import legal from "../../images/give50/impactCalculator/legal.png";
import babies from "../../images/give50/impactCalculator/babies.png";
import { ArrowRight } from "@styled-icons/bootstrap/ArrowRight";
import Button from "../Button";

interface ReportGive50Props {
  activePortfolio: Portfolio | null;
  urlPortfolioId?: string;
  portfoliosReady: boolean;
  holdings: any;
}

function ReportGive50(props: ReportGive50Props) {
  const [totalGiving, setTotalGiving] = useState(0);

  const meta: Array<{
    img: any;
    text: string;
    key: string;
    dividedRate: number;
  }> = [
    {
      key: "cleanwater",
      img: cleanwater,
      text: "People provided with a clean water source",
      dividedRate: 15,
    },
    {
      key: "bibles",
      img: bibles,
      text: "Bibles given to the persecuted Church",
      dividedRate: 5,
    },
    {
      key: "shoebox",
      img: shoebox,
      text: "Operation shoebox gifts delivered",
      dividedRate: 25,
    },
    {
      key: "gospel",
      img: gospel,
      text: "People reached for the gospel",
      dividedRate: 4.36,
    },
    {
      key: "legal",
      img: legal,
      text: "Cases of legal representation for the oppressed",
      dividedRate: 105,
    },
    {
      key: "babies",
      img: babies,
      text: "Potential babies spared through pro-life counsel",
      dividedRate: 4,
    },
  ];

  // carried over functions from homepage give50 calculator

  const getValues = () => {
    var inspire = 0.000268100423828553;
    var pt =
      props.activePortfolio &&
      props.holdings[props.activePortfolio.id].totalMonetaryValue;
    var rate = 7 / 100;

    var growth = pt;
    var total = 0;

    for (let i = 1; i <= 20; i++) {
      growth = product([growth, 1 + rate]);
      let giving = growth * inspire;
      total += giving;
    }

    return total;
  };

  const product = (input: any) => {
    if (toString.call(input) !== "[object Array]") return false;
    var total = 1;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total *= Number(input[i]);
    }
    return total;
  };

  const addCommas = (nStr: any) => {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  };

  // loading up values for calculations

  let portfolioTotal = 0;

  if (
    props.activePortfolio &&
    Object.keys(props.holdings).includes(props.urlPortfolioId as string)
  ) {
    portfolioTotal = 100000;
    // props.holdings[
    //   props.activePortfolio.id
    // ].totalMonetaryValue.toLocaleString("en-US");
  }

  useEffect(() => {
    props.holdings && setTotalGiving(getValues());
  }, [props.holdings]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          // height: isMobile ? 1200 : printing ? '96%' : 800,
          zIndex: 1,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${backdrop})`,
            backgroundSize: "200%",
            backgroundPosition: "41% 0%",
            backgroundRepeat: "repeat",
            padding: "30px 12px 0",
            height: "950px",
          }}
        >
          <Row center="xs" style={{ marginBottom: 35 }}>
            <img src={give50} width={270} alt="give50" />
          </Row>
          {isMobile ? (
            <></>
          ) : (
            <Row center="xs">
              <h1
                style={{
                  color: "white",
                  fontSize: 60,
                  lineHeight: "55px",
                  margin: "0 0 15px",
                }}
              >
                Inspire's Giving Pledge
              </h1>
            </Row>
          )}

          <Row center="xs">
            <Col lg={10} md={12}>
              <span
                style={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: 18,
                  lineHeight: 1.3,
                  padding: "8px 0 0",
                  margin: "8px 0 25px",
                  display: "block",
                }}
              >
                Inspire believes that good returns and good values are not
                mutually exclusive. Not only does Inspire invests in some of the
                most impactful companies in the world, but they also seek to be
                one of the most impactful companies in the world. One way
                Inspire works to make a meaningful impact in the world is
                through generosity.&nbsp;
                <span style={{ fontWeight: 800 }}>
                  Inspire donates at least 50% of the net profits from their
                  management fees to impactful charities with our Give50
                  campaign.
                </span>
              </span>
            </Col>
          </Row>
          {/* {!printing && (
            <Row center="xs" style={{ marginTop: 30 }}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.inspireinvesting.com/"
              >
                <Button type="primary">
                  Learn More About Inspire <ArrowRight width={25} />
                </Button>
              </a>
            </Row>
          )} */}

          <div
            style={{
              textAlign: "center",
              backgroundColor: "white",
              zIndex: 1,
              // height: isMobile ? 1500 : printing ? 320 : 500,
              // top: printing ? 420 : 600,
              position: "relative",
              padding: "30px 0",
            }}
          >
            <p style={{ fontSize: 20, fontWeight: 700, marginBottom: 5 }}>
              <span style={{ color: "#ce7a36" }}>Discover the impact</span> your
              investment portfolio can make over 20 years by investing with
              Inspire
            </p>
            <p style={{ fontWeight: 600, marginBottom: 20 }}>
              Potential impact of a{" "}
              <span
                style={{
                  width: 90,
                  border: "none",
                  borderBottom: "4px solid #2d7eb1",
                  color: "#2d7eb1",
                  fontWeight: 700,
                  marginRight: 5,
                  fontSize: 20,
                }}
              >
                ${portfolioTotal}
              </span>
              portfolio with a{" "}
              <span
                style={{
                  width: 35,
                  border: "none",
                  borderBottom: "4px solid #2d7eb1",
                  color: "#2d7eb1",
                  textAlign: "right",
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                7%
              </span>{" "}
              expected annual rate of return
            </p>
            <Row
              style={{
                paddingTop: 10,
                padding: 10,
              }}
            >
              {meta.map((item: any, i: number) => (
                <Col
                  md={2}
                  sm={2}
                  xs={2}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0",
                    position: "relative",
                  }}
                >
                  {i !== 5 && (
                    <span
                      style={{
                        position: "absolute",
                        right: 0,
                        fontSize: 18,
                        top: 25,
                        fontWeight: "bold",
                      }}
                    >
                      or
                    </span>
                  )}
                  <Row center="xs">
                    <img alt="give50" src={item.img} width={60} height={60} />
                  </Row>
                  <Row center="xs">
                    <span
                      style={{
                        fontWeight: 700,
                        paddingTop: 0,
                        paddingBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      {addCommas((totalGiving / item.dividedRate).toFixed(0))}
                    </span>
                  </Row>
                  <Row center="xs" style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontSize: 15,
                        lineHeight: "16px",
                        padding: "0 10px",
                      }}
                    >
                      {item.text}
                    </span>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
          <div
            style={{
              position: "relative",
              // top: printing ? 400 : 600,
              zIndex: 10,
              padding: "5px 10px 0",
            }}
          >
            <Row
              style={{
                justifyContent: "center",
                textAlign: "center",
                margin: "10px 0",
                padding: 0,
              }}
            >
              <img src={give50logos} width="80%" />
            </Row>
            <Row>
              <Col xs={6} style={{ padding: 5 }}>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    lineHeight: "16px",
                    marginBottom: 0,
                  }}
                >
                  Impact estimates based on Inspire donating an average of 0.10%
                  of initial investment for 20 years. Does not include
                  calculation for potential growth rate of assets over time.
                  Actual donations may be higher or lower depending on growth
                  rate, length of time assets are held at Inspire, and other
                  factors. Donation amounts, performance, profit and other
                  factors are not quaranteed. Donations are paid by Inspire from
                  the revenues generated by all of our products and services.
                  Calculation of minimum annual donation amount is (Revenue -
                  Expenses)*50%
                </p>
              </Col>
              <Col xs={6} style={{ padding: 5 }}>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    lineHeight: "16px",
                    marginBottom: 0,
                  }}
                >
                  IMPACT DATA SOURCES: 1 WorldHelp.net: $15 provides clean water
                  for 1 person 2 JesusCares.com: Cost per visitor to
                  JesusCares.com ($4.36 each) 3 WorldHelp.net: $5 provides one
                  Bible to Christians in persecution 4 International Justice
                  Mission (www.ijm.org): $105 to cover trial fees for one case
                  defending a victim of human trafficking 5 Samaritanspurse.org:
                  $25 provides a shoebox to one needy child.
                  Heartbeatinternational.org: $4 provides one consultant call to
                  a frightened pregnant woman
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportGive50;
