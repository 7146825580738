import { Row, Col } from "react-flexbox-grid";
import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../contexts/ThemeContext";
import { IClient, Portfolio } from "../../models";
import ImpactScore from "../Insight/ImpactScore";
import LoadingIconGrey from "../../images/dual-ring-loading-grey.svg";
import GraphBar from "../Insight/GraphBar";
import axios from "axios";
import { hostUrl } from "../Firebase";
import { categories } from "../../models/Resonate/playbook";
import { CHRISTIAN } from "../../constants";
import Button from "../Button";
import { Printer } from "@styled-icons/feather/Printer";
import Loader from "../Insight/Loader";

interface ReportHeaderProps {
  client: IClient;
  portfoliosReady: boolean;
  activePortfolio: Portfolio | null;
  holdings: any;
  urlPortfolioId?: string;
  handlePrint: () => void;
}

function ReportHeader(props: ReportHeaderProps) {
  const { theme } = useContext(ThemeContext);
  const [exposure, setExposure] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.activePortfolio) {
      axios.post(`${hostUrl}/exposure`, props.activePortfolio).then((res) => {
        setExposure(res.data);
        setLoading(false);
      });
    }
  }, [props.holdings]);

  return (
    <>
      <div
        className="fullWidthContent insightReportHeader"
        style={{
          position: "relative",
        }}
      >
        <Row>
          <Col xs={10}>
            {theme === "insight" &&
              props.client?.portfolios[0] &&
              (props.portfoliosReady ? (
                <ImpactScore
                  type="large"
                  bgColor={"rgb(251, 239, 229)"}
                  score={
                    props.activePortfolio &&
                    Object.keys(props.holdings).includes(
                      props.urlPortfolioId as string
                    )
                      ? props.holdings[props.activePortfolio.id].impactScore
                      : 0
                  }
                  footer
                />
              ) : (
                <div
                  style={{
                    float: "left",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: 20,
                    width: 145,
                  }}
                >
                  <img
                    width="70"
                    src={LoadingIconGrey}
                    style={{
                      position: "relative",
                      bottom: 7,
                      margin: "0 10px",
                    }}
                  />
                </div>
              ))}

            <h1>
              {props.activePortfolio?.name}{" "}
              <span
                style={{
                  fontSize: ".5em",
                  marginLeft: 7,
                  fontWeight: "normal",
                }}
              >
                {props.activePortfolio &&
                Object.keys(props.holdings).includes(
                  props.urlPortfolioId as string
                ) ? (
                  <>
                    $10000
                    {/* {props.holdings[
                      props.activePortfolio.id
                    ].totalMonetaryValue.toLocaleString("en-US")} */}
                  </>
                ) : (
                  <>$0</>
                )}
              </span>
            </h1>

            <h4>
              {props.client && props.client.name + " " + props.client.lastName}
            </h4>
          </Col>
          <Col
            xs={2}
            style={{ justifyContent: "flex-end" }}
            className="hideOnPrint"
          >
            <Button preset="formPrimary" onClick={props.handlePrint}>
              <Printer
                style={{
                  width: 18,
                  marginRight: 8,
                  position: "relative",
                  bottom: 0,
                }}
              />
              Export
            </Button>
          </Col>
        </Row>
      </div>
      <div
        className="fullWidthContent insightReportHeader"
        style={{
          position: "relative",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col xs={6} className="insightReportColumn positive">
              <h2>Positive</h2>
              <GraphBar
                width={
                  props.activePortfolio &&
                  Object.keys(props.holdings).includes(
                    props.urlPortfolioId as string
                  )
                    ? Math.abs(
                        props.holdings[props.activePortfolio.id].alignment
                      )
                    : 0
                }
                style="positive"
              />
              <div className="reportStatistics">
                {Object.entries(exposure)
                  .filter(([key, value]) => (CHRISTIAN as any)[key] > 0)
                  .sort((a, b) => (b[1] as number) - (a[1] as number))
                  .slice(0, 7)
                  .map(([key, value]) => (
                    <Row>
                      <Col xs={12}>
                        <h5>
                          <>
                            {value
                              ? Math.abs((value as number) * 100 - 100).toFixed(
                                  0
                                )
                              : "-"}
                            %
                          </>
                          <span>{(categories as any)[key as string]}</span>
                        </h5>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
            <Col xs={6} className="insightReportColumn negative">
              <h2>Negative</h2>
              <GraphBar
                width={
                  props.activePortfolio &&
                  Object.keys(props.holdings).includes(
                    props.urlPortfolioId as string
                  )
                    ? Math.abs(
                        100 - props.holdings[props.activePortfolio.id].alignment
                      )
                    : 0
                }
                style="negative"
              />
              <div className="reportStatistics">
                {Object.entries(exposure)
                  .filter(([key, value]) => (CHRISTIAN as any)[key] < 0)
                  .sort((a, b) => (b[1] as number) - (a[1] as number))
                  .slice(0, 7)
                  .map(([key, value]) => (
                    <Row>
                      <Col xs={12}>
                        <h5>
                          <>
                            {value
                              ? Math.abs((value as number) * 100 - 100).toFixed(
                                  0
                                )
                              : "-"}
                            %
                          </>
                          <span>{(categories as any)[key as string]}</span>
                        </h5>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default ReportHeader;
