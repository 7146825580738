import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import SelectMenu from "../../components/SelectMenu";
import Button from "../../components/Button";
import Slider from "../../components/Insight/Slider";
import { Table } from "antd";
import ImpactScore from "../../components/Insight/ImpactScore";
import { Download } from "@styled-icons/feather/Download";
import { PlusCircle } from "styled-icons/feather";
import axios from "axios";
import Loader from "../../components/Insight/Loader";
import { hostUrl } from "../../components/Firebase";
import { Unverified } from "styled-icons/octicons";
import { AdvancedSearchValues } from "../../components/Insight/AdvancedSearchValues";
import { IPlaybook } from "../../models";
import { v4 as uuidv4 } from "uuid";

interface ExportDataProps {
  playbook: IPlaybook;
  setPlaybook: (playbook: IPlaybook) => void;
  resetPlaybook: () => void;
}

const ExportData = (props: ExportDataProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const [securityType, setSecurityType] = useState<string>("");
  const [marketCap, setMarketCap] = useState<string[]>([]);
  const [location, setLocation] = useState<string[]>(["DOMESTIC"]);
  const [filterByKeyword, setFilterByKeyword] = useState<string>("");
  const [sectors, setSectors] = useState<string[]>([]);
  const [impactScoreRange, setImpactScoreRange] = useState<number[]>([0, 100]);
  const [valuesVisible, setValuesVisible] = useState<boolean>(false);

  const handleFileDownload = () => {
    setLoading(true);

    let postData: any = {};

    postData = {
      impactScoreGreaterThan: impactScoreRange[0],
      impactScoreLessThan: impactScoreRange[1],
    };

    if (securityType !== "") {
      postData = {
        ...postData,
        securityType: securityType,
      };
    }

    if (marketCap.length > 0) {
      postData = {
        ...postData,
        marketCaps: marketCap,
      };
    }

    if (location.length > 0) {
      postData = {
        ...postData,
        location: location,
      };
    }

    if (sectors.length > 0) {
      postData = {
        ...postData,
        sectors: sectors,
      };
    }

    if (filterByKeyword !== "") {
      postData = {
        ...postData,
        filterByKeyword: filterByKeyword,
      };
    }

    if (props.playbook && Object.keys(props.playbook.positions).length > 0) {
      let convertedPlaybook: { [key: string]: string } = {};

      Object.entries(props.playbook.positions).forEach(([key, value]) => {
        convertedPlaybook = {
          ...convertedPlaybook,
          [key]: value < 0 ? "exclude" : value > 0 ? "include" : "off",
        };

        value === 0 && delete convertedPlaybook[key];
      });

      if (Object.keys(convertedPlaybook).length > 0) {
        postData = {
          ...postData,
          values: convertedPlaybook,
        };
      }
    }

    axios
      // .post("https://resonate-nightly.herokuapp.com/advancedsearch", postData)
      .post(`${hostUrl}/advancedsearch`, postData)
      .then((res) => {
        setSearchResults(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <AdvancedSearchValues
        visible={valuesVisible}
        setPlaybook={props.setPlaybook}
        resetPlaybook={props.resetPlaybook}
        playbook={props.playbook}
        onClose={() => setValuesVisible(false)}
      />
      <div className="fullWidthContainer clientPagination whiteBg">
        <div className="fullWidthContent">
          <Row style={{ marginLeft: 0 }}>
            <Col xs={12}>
              <h2>Advanced Search</h2>
            </Col>

            <Col xs={6} md={3} className="hideOnMobile">
              <SelectMenu
                title="Security Type"
                fullWidth
                persisentTitle={securityType}
                activeState={securityType}
                onClick={(title?: string, value?: string) => {
                  setSecurityType(value as string);
                }}
                options={[
                  {
                    title: "Stocks",
                    value: "STOCKS",
                  },
                  {
                    title: "Mutual Funds",
                    value: "MUTUALFUNDS",
                  },
                  {
                    title: "ETFs",
                    value: "ETFS",
                  },
                  {
                    title: "Mutual Funds & ETFs",
                    value: "MUTUALFUNDS_AND_ETFS",
                  },
                ]}
              />
            </Col>
            {securityType !== "" && (
              <>
                <Col xs={6} md={3} className="hideOnMobile">
                  <SelectMenu
                    key={uuidv4()}
                    title={securityType === "Stocks" ? "Market Cap" : "AUM"}
                    fullWidth
                    multiTitles={marketCap}
                    setMultiTitles={setMarketCap}
                    options={[
                      {
                        title:
                          securityType === "Stocks"
                            ? "Mega ($200bn+)"
                            : "$200bn+",
                        value: "MEGA_CAP",
                      },
                      {
                        title:
                          securityType === "Stocks"
                            ? "Large ($100bn+)"
                            : "$100bn+",
                        value: "LARGE_CAP",
                      },
                      {
                        title:
                          securityType === "Stocks" ? "Mid ($2bn+)" : "$2bn+",
                        value: "MID_CAP",
                      },
                      {
                        title:
                          securityType === "Stocks"
                            ? "Small ($250m+)"
                            : "$250m+",
                        value: "SMALL_CAP",
                      },
                      {
                        title:
                          securityType === "Stocks" ? "Micro ($50+m)" : "$50m+",
                        value: "MICRO_CAP",
                      },
                      {
                        title:
                          securityType === "Stocks"
                            ? "Nano ($0 - $50m)"
                            : "$0 - $50m",
                        value: "NANO_CAP",
                      },
                    ]}
                  />
                </Col>
                {securityType === "Stocks" && (
                  <>
                    <Col xs={6} md={3} className="hideOnMobile">
                      <SelectMenu
                        title="Location"
                        fullWidth
                        multiTitles={location}
                        setMultiTitles={setLocation}
                        options={[
                          {
                            title: "Domestic",
                            value: "DOMESTIC",
                          },
                          {
                            title: "International",
                            value: "INTERNATIONAL",
                          },
                        ]}
                      />
                    </Col>
                    <Col xs={6} md={3} className="hideOnMobile">
                      <SelectMenu
                        title="Sectors"
                        fullWidth
                        multiTitles={sectors}
                        setMultiTitles={setSectors}
                        options={[
                          {
                            title: "Consumer Durables",
                            value: "CONSUMER_DURABLES",
                          },
                          {
                            title: "Consumer Non-Durables",
                            value: "CONSUMER_NON_DURABLES",
                          },
                          {
                            title: "Commercial Services",
                            value: "COMMERCIAL_SERVICES",
                          },
                          {
                            title: "Consumer Services",
                            value: "CONSUMER_SERVICES",
                          },
                          {
                            title: "Communications",
                            value: "COMMUNICATIONS",
                          },
                          {
                            title: "Distribution Services",
                            value: "DISTRIBUTION_SERVICES",
                          },
                          {
                            title: "Electronic Technology",
                            value: "ELECTRONIC_TECHNOLOGY",
                          },
                          {
                            title: "Energy Minerals",
                            value: "ENERGY_MINERALS",
                          },
                          {
                            title: "Finance",
                            value: "FINANCE",
                          },
                          {
                            title: "Government",
                            value: "GOVERNMENT",
                          },
                          {
                            title: "Health Services",
                            value: "HEALTH_SERVICES",
                          },
                          {
                            title: "Health Technology",
                            value: "HEALTH_TECHNOLOGY",
                          },
                          {
                            title: "Industrial Services",
                            value: "INDUSTRIAL_SERVICES",
                          },
                          {
                            title: "Retail Trade",
                            value: "RETAIL_TRADE",
                          },
                          {
                            title: "Miscellaneous",
                            value: "MISCELLANEOUS",
                          },
                          {
                            title: "Non-Energy Minerals",
                            value: "NON_ENERGY_MINERALS",
                          },
                          {
                            title: "Process Industries",
                            value: "PROCESS_INDUSTRIES",
                          },
                          {
                            title: "Producer Manufacturing",
                            value: "PRODUCER_MANUFACTURING",
                          },
                          {
                            title: "Technology Services",
                            value: "TECHNOLOGY_SERVICES",
                          },
                          {
                            title: "Transportation",
                            value: "TRANSPORTATION",
                          },
                          {
                            title: "Utilities",
                            value: "UTILITIES",
                          },
                        ]}
                      />
                    </Col>
                  </>
                )}
              </>
            )}
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={6}>
              <Slider
                title="Impact Score Range"
                returnRange={setImpactScoreRange}
              />
            </Col>
            <Col xs={6} md={3}>
              <div className="inputContainer noLabelInputContainer">
                <input
                  id="login-email"
                  type="text"
                  className="plain"
                  value={filterByKeyword}
                  onChange={(e) => setFilterByKeyword(e.target.value)}
                  name="email"
                  placeholder="Keyword Filter by Ticker or Company"
                />
              </div>
            </Col>
            <Col xs={6} md={2}>
              <Button
                fullWidth
                onClick={() => setValuesVisible(!valuesVisible)}
              >
                <PlusCircle
                  width="20"
                  style={{ marginRight: 8, position: "relative", bottom: 2 }}
                />
                Screen By Values
              </Button>
            </Col>
            <Col xs={12} md={1}>
              <Button
                fullWidth
                preset="formPrimary"
                onClick={handleFileDownload}
              >
                {/* <Download
                  width="20"
                  style={{ marginRight: 8, position: "relative", bottom: 2 }}
                /> */}
                Search
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div id="mainContent" className="fullWidthContainer portfolioContainer">
        <div className="fullWidthContent">
          {loading ? (
            <Loader />
          ) : (
            searchResults.length > 0 && (
              <Table
                dataSource={searchResults}
                columns={[
                  {
                    title: "Ticker",
                    dataIndex: "ticker",
                    key: "ticker",
                    sorter: (a: any, b: any) =>
                      a.ticker.localeCompare(b.ticker),
                  },
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                  },
                  {
                    title: "Market Cap",
                    dataIndex: "market_capitalization",
                    key: "market_capitalization",
                    sorter: (a: any, b: any) =>
                      a.market_capitalization - b.market_capitalization,
                    render: (marketCap: number) => {
                      if (marketCap > 100000000000) {
                        return (
                          "$" + (marketCap / 1000000000000).toFixed(2) + "T"
                        );
                      } else if (marketCap > 1000000000) {
                        return "$" + (marketCap / 1000000000).toFixed(2) + "B";
                      } else if (marketCap > 1000000) {
                        return "$" + (marketCap / 1000000).toFixed(2) + "M";
                      } else if (marketCap === 0 || marketCap === null) {
                        return "-";
                      } else {
                        return "$" + (marketCap / 1000).toFixed(2) + "K";
                      }
                    },
                  },
                  {
                    title: "Impact Score",
                    dataIndex: "impact_score",
                    key: "impact_score",
                    sorter: (a: any, b: any) => a.impact_score - b.impact_score,
                    render: (score: number, _: any, index: number) => (
                      <ImpactScore
                        key={Math.random() * 10000}
                        score={score}
                        type="small"
                      />
                    ),
                  },
                ]}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default ExportData;
