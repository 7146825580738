import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import models, { PLAYBOOK_DEFINITIONS } from "../../constants";
import circleInfo from "../../images/circle-info-light.svg";
import { IClient, IPlaybook, IUser } from "../../models";
import {
  PlaybookPosition,
  categories,
} from "../../models/Resonate/playbook";
import Button from "../Button";
import Confirm from "../Confirm";
import SelectMenu from "../SelectMenu";
import { ShareProfileModal } from "./ShareProfileModal";
import { PlaybookButton } from "./PlaybookButton";

interface PlaybookProps {
  visible: boolean;
  setPlaybookVisible: (arg0: boolean) => void;
  onClose: (
    playbookIsZero: boolean,
    playbookMenuTitle: string,
    isClientCreatedPlaybook: boolean
  ) => void;
  client: IClient;
  user: IUser;
  onPlaybookChange: (
    category: string,
    value: PlaybookPosition,
    playbookMenuTitle: string,
    clientCreatedProfileId?: string,
    prebuiltProfile?: {}
  ) => void;
  resetPlaybook: () => void;
  activePlaybook: IPlaybook | null;
  setActivePlaybook: (playbook: IPlaybook) => void;
}

export function Playbook(props: PlaybookProps) {
  const {
    client,
    onPlaybookChange,
    setPlaybookVisible,
    user,
    activePlaybook,
  } = props;
  const [playbookIsZero, setPlaybookIsZero] = useState<boolean>(false);
  const [playbookMenuTitle, setPlaybookMenuTitle] = useState<string>(
    activePlaybook ? activePlaybook.playbookName : ""
  );
  const [clientMobileModalVisible, setShareProfileModalVisible] =
    useState<boolean>(false);
  const [screeningDefinition, setScreeningDefinition] = useState("");
  const [saveProfileVisible, setSaveProfileVisible] = useState<boolean>(false);
  const [playbookEdited, setPlaybookEdited] = useState<boolean>(false);
  const [clientCreatedPlaybookIsLoaded, setClientCreatedPlaybookIsLoaded] =
    useState<boolean>(false);
  const [explainPlaybook, setExplainPlaybook] = useState<boolean>(false);

  let clientCreatedPlaybooks = props.client.playbooks.filter(
    (p) => p.clientCreated === true
  );

  useEffect(() => {
    if (activePlaybook) {
      Object.keys(activePlaybook.positions).length === 0 &&
        setPlaybookIsZero(true);
      activePlaybook.clientCreated && setClientCreatedPlaybookIsLoaded(true);
    }
  }, []);

  let playbookClientName = `${client.name}'s Screening Profile`;

  const checkSelected = (
    category: string,
    value: PlaybookPosition
  ): boolean => {
    if (
      activePlaybook &&
      activePlaybook !== ({} as IPlaybook) &&
      Object.keys(activePlaybook?.positions).includes(category)
    ) {
      return activePlaybook?.positions[category] === value;
    } else {
      return value === 0;
    }
  };

  const setProfile = (
    profile: { [key: string]: PlaybookPosition },
    playbookMenuTitle: string,
    clientCreatedProfileId?: string
  ) => {
    if (clientCreatedPlaybookIsLoaded) {
      Object.entries(profile).forEach(([key, value]) => {
        onPlaybookChange(
          key,
          value,
          playbookMenuTitle,
          clientCreatedProfileId,
          profile
        );
      });
    } else {
      onPlaybookChange(
        "",
        0,
        playbookMenuTitle,
        clientCreatedProfileId,
        profile
      );
    }
  };

  const retrieveDefinition = (key: any) => {
    setScreeningDefinition((PLAYBOOK_DEFINITIONS as any)[key]);
  };

  const renderProfileOptions = () => {
    let clientPlaybooks: {
      title: string;
      positions: {};
      name: string;
      value: string;
    }[] = [];

    clientCreatedPlaybooks.forEach((p) => {
      let thisPlaybook = {
        title: p.playbookName,
        positions: p.positions,
        name: p.playbookName,
        value: p.playbookName.toLowerCase(),
        clientCreated: p.clientCreated,
        id: p.id,
      };

      clientPlaybooks.push(thisPlaybook);
    });

    let renderedProfiles = [
      {
        title: "Christian (Catholic USCCB)",
        value: "catholic",
      },
      {
        title: "Christian (Protestant)",
        value: "christian",
      },
      {
        title: "Environmental",
        value: "environmental",
      },
    ];

    if (clientCreatedPlaybooks.length > 0) {
      let dividerProfile = [{ title: "--", value: "" }];
      Array.prototype.push.apply(renderedProfiles, dividerProfile);
    }

    Array.prototype.push.apply(renderedProfiles, clientPlaybooks);
    return renderedProfiles;
  };

  return (
    <>
      <Confirm
        isActive={saveProfileVisible}
        onClose={() => setSaveProfileVisible(false)}
        onCancel={() => (
          setSaveProfileVisible(false),
          setPlaybookVisible(false),
          setPlaybookMenuTitle(activePlaybook?.playbookName as string),
          toast.info("Profile changes discarded")
        )}
        confirmText="Save Changes"
        cancelText="Discard Changes"
        onConfirm={() => (
          setSaveProfileVisible(false),
          props.onClose(
            playbookIsZero,
            playbookMenuTitle,
            clientCreatedPlaybookIsLoaded
          ),
          setPlaybookMenuTitle(""),
          setPlaybookVisible(false)
        )}
        message="There are unsaved changes to your screening profile. What would you like to do?"
      />
      <ShareProfileModal
        isActive={clientMobileModalVisible}
        clientId={client.id}
        advisorUserId={user.uid}
        clientName={client.name}
        setShareProfileModalVisible={setShareProfileModalVisible}
        onClose={() => {
          setShareProfileModalVisible(false);
          setPlaybookVisible(false);
        }}
      />
      <Drawer
        open={props.visible}
        afterOpenChange={() => {
          setPlaybookEdited(false);
          setPlaybookMenuTitle(activePlaybook?.playbookName as string);
        }}
        placement="left"
        width={window.innerWidth < 1030 ? "100%" : "70%"}
        title={playbookClientName}
        onClose={() => {
          if (playbookIsZero) {
            setPlaybookVisible(false);
            setPlaybookMenuTitle(activePlaybook?.playbookName as string);
          } else if (playbookEdited) {
            setSaveProfileVisible(true);
          } else if (clientCreatedPlaybookIsLoaded) {
            setPlaybookVisible(false);
            setPlaybookMenuTitle(activePlaybook?.playbookName as string);
          } else {
            setPlaybookVisible(false);
            setPlaybookMenuTitle(activePlaybook?.playbookName as string);
          }
        }}
      >
        <div>
          <Row style={{ backgroundColor: "#ffffff", padding: "20px" }}>
            <Col xs={12} sm={6}>
              <SelectMenu
                title="Preset Profiles"
                persisentTitle={
                  playbookMenuTitle !== ""
                    ? playbookMenuTitle
                    : activePlaybook?.playbookName
                }
                activeState={playbookMenuTitle}
                fullWidth
                onClick={(
                  value: string,
                  title?: string,
                  name?: string,
                  positions?: any,
                  clientCreated?: boolean,
                  id?: string
                ) => {
                  if (title === "--") {
                    return false;
                  } else if (clientCreated && id) {
                    setClientCreatedPlaybookIsLoaded(true);
                    positions && setProfile(positions, title as string, id);
                    title && setPlaybookMenuTitle(title as string);
                  } else {
                    setPlaybookMenuTitle(title as string);
                    setProfile(models[value], title as string);
                    setClientCreatedPlaybookIsLoaded(false);
                  }
                  setPlaybookIsZero(false);
                  setPlaybookEdited(true);
                }}
                options={renderProfileOptions()}
              />
            </Col>
            <Col
              mdOffset={1}
              md={5}
              xs={8}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                fullWidth
                onClick={() => {
                  setShareProfileModalVisible(true);
                }}
              >
                Send To Client
              </Button>
              <Button
                fullWidth
                style={{ marginLeft: 10 }}
                onClick={() => {
                  props.resetPlaybook();
                  setPlaybookIsZero(true);
                  setPlaybookEdited(true);
                  setPlaybookMenuTitle("Custom Profile");
                }}
              >
                Reset Profile
              </Button>

              <Button
                fullWidth
                preset="formPrimary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if (playbookIsZero) {
                    toast.error("Please customize profile before closing", { autoClose: 1000 });
                  } else if (clientCreatedPlaybookIsLoaded) {
                    setSaveProfileVisible(false);
                    props.onClose(
                      playbookIsZero,
                      playbookMenuTitle,
                      clientCreatedPlaybookIsLoaded
                    );
                  } else if (playbookEdited) {
                    setSaveProfileVisible(false);
                    props.onClose(
                      playbookIsZero,
                      playbookMenuTitle,
                      clientCreatedPlaybookIsLoaded
                    );
                    setPlaybookMenuTitle("");
                    setPlaybookVisible(false);
                  } else {
                    toast.warning("No changes were made to profile");
                  }
                  setPlaybookEdited(false);
                }}
              >
                Save Profile
              </Button>
            </Col>
          </Row>
          <Row style={{ padding: "20px 30px" }}>
            <Col>
              {/* <h1
              style={{
                fontWeight: 700,
                fontSize: "2.5em",
                marginBottom: 10,
                color: "#062a49",
              }}
            >
              Let's get personal.
            </h1> */}
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  color: "#062a49",
                  marginBottom: 5,
                  marginRight: 15,
                  display: "inline-block",
                }}
              >
                {`How many positives or negatives are each of the following worth
                in ${client.name}'s profile?`}
              </p>

              <Button
                mini
                style={{ display: "inline-block" }}
                onClick={() => setExplainPlaybook(!explainPlaybook)}
              >
                How do I use this?
              </Button>

              {explainPlaybook && (
                <>
                  <Row
                    style={{
                      marginTop: 20,
                      paddingTop: 20,
                      borderTop: "1px solid rgb(224, 231, 231)",
                    }}
                  >
                    <Col xs={12} sm={6}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 5,
                        }}
                      >
                        The selections you make below will generate your
                        client's Resonate Score algorithm that can be used to
                        quickly and easily identify which stocks or funds are
                        most aligned and least aligned with your client's
                        personal values and empower them to make more informed
                        investment decisions to support what they care about
                        most.
                      </p>
                    </Col>
                    <Col xs={12} sm={6}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 15,
                        }}
                      >
                        For example, selecting <strong>-3</strong> for
                        “Abortifacients” means that your client “Strongly
                        Opposes” abortifacient manufacturing and distribution
                        companies and want them minimized or excluded from their
                        portfolio. Selecting <strong>2</strong> for “Business
                        Ethics” means they want to “Support” companies which
                        demonstrate above average business ethics and elevate
                        those positions in their portfolio.
                      </p>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "rgb(232 235 235)",
                      padding: 0,
                      marginTop: 10,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "#062a49",
                        marginBottom: 0,
                        textAlign: "center",
                        padding: "5px 0",
                        display: "block",
                        width: "100%",
                        borderBottom: "2px solid rgb(215, 222, 222)",
                      }}
                    >
                      Screening Profile Legend:
                    </p>
                    <Col xs={12}>
                      <Row>
                        {[
                          { label: "Strongly Oppose", value: -3 },
                          { label: "Oppose", value: -2 },
                          { label: "Somewhat Oppose", value: -1 },
                          { label: "Not Important", value: 0 },
                          { label: "Somewhat Support", value: 1 },
                          { label: "Support", value: 2 },
                          { label: "Strongly Support", value: 3 },
                        ].map((button) => (
                          <Col
                            xs={12}
                            sm={1.5}
                            style={{
                              borderRight:
                                button.value === 3
                                  ? "0"
                                  : "2px solid rgb(215, 222, 222)",
                              padding: 10,
                              borderBottom: "2px solid rgb(215, 222, 222)",
                              textAlign: "center",
                            }}
                          >
                            <Row>
                              <Col
                                xs={4}
                                sm={12}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                  paddingTop: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PlaybookButton
                                    title={button ? button?.value : 0}
                                    style={{ marginRight: 5 }}
                                    selected={checkSelected(
                                      "yo",
                                      button?.value as PlaybookPosition
                                    )}
                                    onClick={() => {}}
                                  />
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                sm={12}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "0.9em",
                                    fontWeight: 600,
                                    height: 30,
                                    lineHeight: "1.1em",
                                    margin: "5px 0",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {button?.label}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {/* <Row>
          <form>
            <div className="inputContainer">
              <label htmlFor="client-name">Filter Categories:</label>
              <input
                type="text"
                id="client-name"
                onChange={(e) => {}}
                placeholder="–"
              />
            </div>
          </form>
        </Row> */}
          {Object.entries(categories).map(([key, value]) => (
            <div className="playbookCriteriaRow">
              <Row
                style={{
                  padding: "7px 20px 0",
                  borderTop: "1px solid #e0e7e7",
                  marginTop: 0,
                }}
              >
                {[-3, -2, -1, 0, 1, 2, 3].map((number) => (
                  <Col>
                    <PlaybookButton
                      title={number}
                      selected={checkSelected(key, number as PlaybookPosition)}
                      onClick={() => {
                        setPlaybookMenuTitle("Custom Profile");
                        onPlaybookChange(
                          key,
                          number as PlaybookPosition,
                          "Custom Profile"
                        );
                        setPlaybookIsZero(false);
                        setPlaybookEdited(true);
                        setClientCreatedPlaybookIsLoaded(false);
                      }}
                    />
                  </Col>
                ))}
                <Col xs={12} sm={5} style={{ paddingTop: 14 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {screeningDefinition ===
                    (PLAYBOOK_DEFINITIONS as any)[key] ? (
                      <a
                        href="#"
                        style={{
                          color: "rgb(6, 42, 73)",
                          textDecoration: "none",
                        }}
                        onClick={() => setScreeningDefinition("")}
                      >
                        {value}{" "}
                        <img
                          src={circleInfo}
                          width="16"
                          style={{ marginLeft: 2, marginTop: -2 }}
                        />
                      </a>
                    ) : (
                      <a
                        href="#"
                        style={{
                          color: "rgb(6, 42, 73)",
                          textDecoration: "none",
                        }}
                        onClick={() => retrieveDefinition(key)}
                      >
                        {value}{" "}
                        <img
                          src={circleInfo}
                          width="16"
                          style={{ marginLeft: 2, marginTop: -2 }}
                        />
                      </a>
                    )}
                  </p>
                </Col>
              </Row>
              {screeningDefinition === (PLAYBOOK_DEFINITIONS as any)[key] && (
                <Row style={{ padding: "7px 20px 3px" }}>
                  <Col xs={12}>
                    <p style={{ fontSize: "1.1em", marginBottom: 0 }}>
                      {screeningDefinition}
                    </p>
                  </Col>
                </Row>
              )}
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
}
