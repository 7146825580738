import { Row, Col } from "react-flexbox-grid";
import Button from "../Button";
import { auth } from "../Firebase";
import Logo from "../../images/Insight/insight-logo.svg";
import TickerSearch from "../../components/Insight/TickerSearch";
import { ArrowRight } from "@styled-icons/remix-line/ArrowRight";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

interface HeaderProps {
  isHome?: boolean;
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className="fullWidthContainer whiteBg" id="insightHeader">
      <div className="fullWidthContent">
        {props.isHome ? (
          // home page header
          <Row>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
              }}
              xs={3}
              sm={4}
            >
              <p style={{ margin: 0 }}>
                <a href="https://legacy.inspireinsight.com/about">About</a>
              </p>
            </Col>
            {!auth.currentUser ? (
              // home page logged out
              <>
                <Col
                  smOffset={2}
                  xs={9}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <a href={"/login"} style={{ marginRight: 20 }}>
                    Login
                  </a>
                  {/* <Button>
                    Start Investing Biblically{" "}
                    <ArrowRight style={{ marginLeft: 5, width: 25 }} />
                  </Button> */}
                </Col>
              </>
            ) : (
              // home page logged in
              <>
                <Col
                  smOffset={2}
                  xs={9}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <a onClick={() => auth.signOut()} style={{ marginRight: 20 }}>
                    Sign Out
                  </a>
                  <Link to="/dashboard">
                    {auth.currentUser && (
                      <Button preset="formPrimary">Go To Dashboard</Button>
                    )}
                  </Link>
                </Col>
              </>
            )}
          </Row>
        ) : (
          // sub page header
          <Row>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
              }}
              xs={!auth.currentUser ? 6 : 4}
              sm={3}
              md={3}
              lg={2}
            >
              <a onClick={() => navigate(`/`)}>
                <img src={Logo} alt="Insight Logo" width="160" />
              </a>
            </Col>
            <Col
              xs={1}
              md={!auth.currentUser ? 7 : 5}
              lg={!auth.currentUser ? 8 : 6}
            >
              <TickerSearch />
            </Col>
            {!auth.currentUser ? (
              // sub page logged out
              <>
                <Col
                  xs={5}
                  sm={8}
                  md={2}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <a href="/login" style={{ marginRight: 20 }}>
                    Login
                  </a>
                  {/* <Button>
                    Start Investing Biblically{" "}
                    <ArrowRight style={{ marginLeft: 5, width: 25 }} />
                  </Button> */}
                </Col>
              </>
            ) : (
              // sub page logged in
              <>
                <Col
                  xs={7}
                  sm={8}
                  md={4}
                  lg={4}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <a
                    onClick={() =>
                      auth.signOut().then(() => {
                        navigate(`/`);
                      })
                    }
                    style={{ marginRight: 20 }}
                  >
                    Sign Out
                  </a>
                  <Link to="/dashboard">
                    {auth.currentUser && (
                      <Button preset="formPrimary">Go To Dashboard</Button>
                    )}
                  </Link>
                </Col>
              </>
            )}
          </Row>
        )}
      </div>
    </div>
  );
};

export default Header;
