import React, { Component } from "react";
import { globals } from "./globals";
// import Tooltip from "../images/info-circle-light.svg";

import * as d3 from "d3";

const ANIMATION_STATE: { stateindex: number } = {
  stateindex: 0,
};

enum ArcProperties {
  DefaultStrokeWidth = 5,
  DefaultSize = 35,
}

enum ArcLayers {
  InnerArc,
  MiddleArc,
  OuterArc,
}

enum ArcThresholds {
  InnerArc = 0,
  MiddleArc = 30,
  OuterArc = 60,
  Max = 100,
}

interface ImpactArcProps {
  height?: number;
  width?: number;
  stateindex?: number;
  speedMS?: number;
  steps?: number;
  type?: string;
  score: number;
  key?: number;
  size?: number;
  style?: object;
  withAnimation?: boolean;
}

function getSizeDynamic(size?: number) {
  if (!size) return ArcProperties.DefaultSize;
  const growth: number = (size - ArcProperties.DefaultStrokeWidth) / 3;
  const strokeWidth: number = growth / 2;
  const numberofArcs: number = 3;
  const computedSize: number = growth * numberofArcs + strokeWidth;
  return computedSize;
}

class ImpactArc extends Component<ImpactArcProps> {
  size: number = this.props.size ? this.props.size : ArcProperties.DefaultSize;
  boxWidth: number = getSizeDynamic(this.size);
  boxHeight: number = getSizeDynamic(this.size);
  svgLeft: number = getSizeDynamic(this.size);
  svgTop: number = getSizeDynamic(this.size);
  maxValue: number = 4;
  minValue: number = 2;
  boxID: string = "svgcontainer" + Math.floor(Math.random() * 10000);
  isNeg: boolean = this.props.score * 1 < 0;
  color: string = this.isNeg
    ? globals.colors.negative.red
    : globals.colors.positive.blue;

  overrideStyles: any = this.props.style ? this.props.style : {};

  state = {
    stateindex: 0,
    angle: 3,
  };

  shouldComponentUpdate(
    nextProps: Readonly<ImpactArcProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return true;
  }

  componentDidMount() {
    d3.select(`#${this.boxID}`).selectAll("*").remove();
    const offset: number = -1;
    const steps: number = 100 + offset;
    const speedMS: number = this.props.speedMS ? this.props.speedMS : 20;
    const newIntervalId = setInterval(() => {
      if (ANIMATION_STATE.stateindex >= steps) clearInterval(newIntervalId);
      ANIMATION_STATE.stateindex++;
      const factor: number = ANIMATION_STATE.stateindex / steps;
      const growth: number = factor * (this.maxValue - this.minValue);
      const newVal: number = this.minValue + growth;
      this.renderArc(newVal);
    }, speedMS);
  }

  getEndAngle(animate: boolean, angle: number, index: number) {
    const trig = Math.PI / 4;
    const score = Math.abs(this.props.score);

    if (index === ArcLayers.InnerArc) {
      const portion: number =
        Math.min(score, ArcThresholds.MiddleArc) / ArcThresholds.MiddleArc;
      const maxAngle: number = 2 + (this.maxValue - this.minValue) * portion;
      const finished = !animate || angle >= maxAngle;
      if (finished) return maxAngle * trig;
    }

    if (index === ArcLayers.MiddleArc) {
      const preportion: number = score - ArcThresholds.MiddleArc;
      const max = ArcThresholds.MiddleArc - ArcThresholds.InnerArc;
      const portion: number = Math.min(preportion, max) / max;
      const maxAngle: number = 2 + (this.maxValue - this.minValue) * portion;
      const finished = !animate || angle >= maxAngle;
      if (finished) return maxAngle * trig;
    }

    if (index === ArcLayers.OuterArc) {
      const preportion: number = score - ArcThresholds.OuterArc;
      const max = ArcThresholds.Max - ArcThresholds.OuterArc;
      const portion: number = Math.min(preportion, max) / max;
      const maxAngle: number = 2 + (this.maxValue - this.minValue) * portion;
      const finished = !animate || angle >= maxAngle;
      if (finished) return maxAngle * trig;
    }

    return angle * trig;
  }

  renderArc = (angle: number) => {
    const animate = this.props.withAnimation === false ? false : true;

    d3.select("#" + this.boxID)
      .selectAll("*")
      .remove();

    const angleZero: number = (2 * Math.PI) / 4;
    const angleFinal: number = (4 * Math.PI) / 4;
    const score: number = Math.abs(this.props.score);
    const x: number = this.svgLeft;
    const y: number = this.svgTop;
    const w: number = this.boxWidth;
    const h: number = this.boxHeight;
    const growth: number = (this.size - ArcProperties.DefaultStrokeWidth) / 3;
    const strokeWidth: number = growth / 2;

    const svg = d3
      .select("[id='" + this.boxID + "']")
      .append("svg")
      .attr("width", w)
      .attr("height", h);

    const g = svg.append("g");

    // calculate bg (grey) arcs
    const arcInnerBG: any = d3
      .arc()
      .innerRadius(growth)
      .outerRadius(growth + strokeWidth)
      .startAngle(angleZero)
      .endAngle(angleFinal);

    const arcMiddleBG: any = d3
      .arc()
      .innerRadius(growth * 2)
      .outerRadius(growth * 2 + strokeWidth)
      .startAngle(angleZero)
      .endAngle(angleFinal);

    const arcOuterBG: any = d3
      .arc()
      .innerRadius(growth * 3)
      .outerRadius(growth * 3 + strokeWidth)
      .startAngle(angleZero)
      .endAngle(angleFinal);

    // calculate positive/negative arcs
    const arcOuter: any = d3
      .arc()
      .innerRadius(growth * 3)
      .outerRadius(growth * 3 + strokeWidth)
      .startAngle(angleZero)
      .endAngle(this.getEndAngle(animate, angle, ArcLayers.OuterArc));

    const arcInner: any = d3
      .arc()
      .innerRadius(growth)
      .outerRadius(growth + strokeWidth)
      .startAngle(angleZero)
      .endAngle(this.getEndAngle(animate, angle, ArcLayers.InnerArc));

    const arcMiddle: any = d3
      .arc()
      .innerRadius(growth * 2)
      .outerRadius(growth * 2 + strokeWidth)
      .startAngle(angleZero)
      .endAngle(this.getEndAngle(animate, angle, ArcLayers.MiddleArc));

    // draw bg (grey) arcs
    g.append("path")
      .attr("d", arcInnerBG)
      .attr("fill", "#c2d5e4")
      .attr("transform", `translate(${x},${y}) scale(-1,-1)`);

    g.append("path")
      .attr("d", arcMiddleBG)
      .attr("fill", "#c2d5e4")
      .attr("transform", `translate(${x},${y}) scale(-1,-1)`);

    g.append("path")
      .attr("d", arcOuterBG)
      .attr("fill", "#c2d5e4")
      .attr("transform", `translate(${x},${y}) scale(-1,-1)`);

    // draw score (colored) arcs
    if (score >= ArcThresholds.InnerArc)
      g.append("path")
        .attr("d", arcInner)
        .attr("fill", this.color)
        .attr("transform", `translate(${x},${y}) scale(-1,-1)`);

    if (score >= ArcThresholds.MiddleArc)
      g.append("path")
        .attr("d", arcMiddle)
        .attr("fill", this.color)
        .attr("transform", `translate(${x},${y}) scale(-1,-1)`);

    if (score >= ArcThresholds.OuterArc)
      g.append("path")
        .attr("d", arcOuter)
        .attr("fill", this.color)
        .attr("transform", `translate(${x},${y}) scale(-1,-1)`);
  };

  render() {
    this.renderArc(this.state.angle);

    return (
      <>
        <div
          id={this.boxID}
          key={this.props.key}
          style={{
            display: "inline-block",
            verticalAlign: "top",
            //border: '1px solid rgba(255,0,0,0.2)',
            width: this.boxWidth + "px",
            height: this.boxHeight + "px",
            ...this.overrideStyles,
          }}
        ></div>
      </>
    );
  }
}

type ScoreType = "default" | "large" | "small" | "tiny";

interface ImpactScoreProps {
  score: any;
  speed?: number;
  withAnimation?: boolean;
  boxSize?: number;
  fontSize?: string;
  key?: number;
  arcSize?: number;
  type?: ScoreType;
  match?: boolean;
  style?: Record<string, any>;
  footer?: boolean; // hide text underneath
  bgColor?: string;
}

enum ArcSize {
  Small = 25,
  Large = 35,
}

enum CounterSize {
  Small = 25,
  Large = 46,
}

enum TextSize {
  Small = 0,
  Large = 10,
}

const styles: Record<string, any> = {
  text: {
    fontSize: 12,
    color: globals.colors.inspire.orange,
    fontWeight: 700,
    letterSpacing: "0.05",
    marginTop: -5,
    marginLeft: -2,
  },
};

export default class ImpactScore extends React.Component<ImpactScoreProps> {
  getImpactScoreStyle() {
    let sizeBig = 128;
    let leftMargBig = 10;
    let type = this.props.type;
    if (!type) type = "default";
    if (type === "large") return {};
    if (type === "small")
      return {
        width: 65,
        height: 35,
      };
    return {
      backgroundColor: "rgba(0,255,0,0.1)",
    };
  }

  getArcSize() {
    let type = this.props.type;
    if (!type) type = "default";
    if (type === "large") return ArcSize.Large;
    if (type === "small") return ArcSize.Small;
    return this.props.arcSize;
  }

  getNumberRollFontSize(unit?: string) {
    if (!unit) unit = "px";
    let type = this.props.type;
    if (type === "large") return CounterSize.Large + unit;
    if (type === "small") return CounterSize.Small + unit;
    return this.props.fontSize;
  }

  getNumberRollStyles(unit?: string) {
    if (!unit) unit = "px";
    let type = this.props.type;

    if (type === "large")
      return {
        fontSize: CounterSize.Large + unit,
        color:
          this.props.score >= 0 ? globals.colors.inspire.orange : "#bd425a",
        fontWeight: 800,
        marginTop: -12,
        marginLeft: 7,
        marginBottom: -15,
        display: "inline-block",
        letterSpacing: "-3px",
      };
    if (type === "small")
      return {
        fontSize: CounterSize.Small + unit,
        color:
          this.props.score >= 0 ? globals.colors.inspire.orange : "#bd425a",
        marginLeft: 3,
        letterSpacing: -1,
        fontWeight: 700,
        display: "inline-flex",
        marginTop: -5,
      };
    return {
      fontSize: this.props.fontSize,
    };
  }

  getTextStyles(unit?: string) {
    if (!unit) unit = "px";
    let type = this.props.type;
    if (type === "small" || !this.props.footer)
      return {
        display: "none",
        fontSize: TextSize.Small + unit,
      };
    if (type === "large")
      return {
        fontSize: "15px",
        margin: "0",
        ...globals.styles.presets.noselect,
      };
    return {};
  }

  render() {
    const animate = this.props.withAnimation !== false ? true : false;
    const bg: string = this.props.bgColor ? this.props.bgColor : "transparent";
    //console.log('this.props.score: ' + this.props.score)
    return (
      <div
        className={this.props.type === "large" ? "largeImpactScore" : ""}
        style={
          this.props.type === "large"
            ? {
                background: bg,
                paddingTop: "13px",
                paddingRight: "0",
                textAlign: "center",
                float: "left",
                marginRight: "20px",
                width: "145px",
                paddingBottom: "10px",
                border: "1px solid rgba(209, 120, 50, 0.5)",
                borderRadius: 4,
              }
            : {}
        }
      >
        <div
          style={{
            ...this.getImpactScoreStyle(),
            ...this.props.style,
            marginTop: 6,
          }}
        >
          <ImpactArc
            withAnimation={animate}
            score={this.props.score}
            key={Math.random() * 10000}
            size={this.getArcSize()}
          />
          {this.props.score === null ? (
            <span
              style={
                this.props.type === "large"
                  ? {
                      color: globals.colors.inspire.orange,
                      marginLeft: 5,
                      fontSize: 28,
                      fontWeight: 700,
                    }
                  : {
                      color: "lightgrey",
                      marginLeft: 3,
                      fontWeight: 700,
                      fontSize: 16,
                      letterSpacing: -1,
                    }
              }
            >
              NA
            </span>
          ) : (
            <span
              className={
                this.props.score >= 0
                  ? "extended-font-style"
                  : "extended-font-style negativeScore"
              }
              style={this.getNumberRollStyles()}
            >
              {this.props.score}
            </span>
            // <NumberRoll
            //     match={match}
            //     withAnimation={animate}
            //     fontSize={this.getNumberRollFontSize()}
            //     type={this.props.type}
            //     final={this.props.score}
            //     style={this.getNumberRollStyles()}
            // />
          )}
          <h5
            style={{
              ...styles.text,
              ...this.getTextStyles(),
              ...globals.styles.presets.noselect,
              verticalAlign: "top",
              display: "block",
              marginTop: "2",
            }}
          >
            inspire impact
            {this.props.type === "large" && this.props.footer ? (
              <div
                style={{
                  display: "inline-block",
                  marginLeft: 2,
                  verticalAlign: "top",
                }}
              >
                {/* <img src={Tooltip} width={14} height={14} alt="" /> */}
              </div>
            ) : (
              <></>
            )}
          </h5>
        </div>
      </div>
    );
  }
}
