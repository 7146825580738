import { useContext, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import themeContext from "../../../contexts/ThemeContext";
import { IClient } from "../../../models";
import Button from "../../Button";
import { doUpdateClient } from "../../Firebase";
import Modal from "../../Modal";
import { PortfolioTemplate } from "../../Portfolios";

interface EditClientModalProps {
  isActive: boolean;
  onClose: (callbackData?: any) => void;
  client: IClient;
}

export function EditClientModal(props: EditClientModalProps) {
  const { onClose, isActive, client } = props;
  const [clientFirstName, setClientFirstName] = useState(
    props.client ? props.client.name : ""
  );

  const [clientLastName, setClientLastName] = useState(
    props.client ? props.client.lastName : ""
  );

  const [clientEmail, setClientEmail] = useState(client.email);

  const { theme } = useContext(themeContext);

  const handleSubmit = () => {
    const clientData = client;
    clientData.name = clientFirstName;
    clientData.lastName = clientLastName;
    clientData.email = clientEmail;

    doUpdateClient(clientData, PortfolioTemplate.PORTFOLIO)
      .then(() => {
        toast.success("Client updated", {autoClose: 1000 });
        props.onClose(clientData);
      })
      .catch((e) => {
        toast.error("Client update failed", {autoClose: 1000 });
      });
  };

  return (
    <Modal
      modalTitle={"Edit Client"}
      isActive={props.isActive}
      onClose={props.onClose}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="client-name">Client First Name:</label>
              <input
                type="text"
                id="client-name"
                value={clientFirstName}
                onChange={(e) => setClientFirstName(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="client-name">Client Last Name:</label>
              <input
                type="text"
                id="client-name"
                value={clientLastName}
                onChange={(e) => setClientLastName(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="client-email">Client Email:</label>
              <input
                type="email"
                id="client-email"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
        </Row>
      </form>
      <Row>
        <Col xs={12} sm={6}>
          <Button fullWidth onClick={props.onClose}>
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button preset="formPrimary" fullWidth onClick={handleSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
