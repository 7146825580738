import { useEffect, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import searchIcon from "../../images/search-icon-black.svg";
import ImpactScore from "./ImpactScore";
import { UniversalSearchResultItem } from "../../models/Resonate/api";
import { useNavigate } from "react-router";
import { isMobile, isTablet } from "react-device-detect";
import { Col, Row } from "react-flexbox-grid";
import { insightHostUrl } from "../Firebase";

const TickerSearch = () => {
  const [tokens, setTokens] = useState<Array<CancelTokenSource>>([]);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [query, setQuery] = useState<string>("");
  const [lastChange, setLastChange] = useState<Date | null>();
  const navigate = useNavigate();

  const requestFollower = (t: Date) => {
    setTimeout(() => {
      if (t === lastChange) {
        search(query);
      } else {
      }
    }, 200);
  };

  useEffect(() => {
    // setTimeout(() => {
    if (query !== "" && lastChange) {
      requestFollower(lastChange);
      // let currentTime = Date.now();
      // let timeDifference = currentTime - lastChange - 800;
      // console.log(timeDifference);

      // if (timeDifference) {
      //   search(query);
      // }
      setLastChange(new Date());
    }
    // }, 400);
  }, [query]);

  const search = (value: any) => {
    tokens.forEach((source: CancelTokenSource) => source.cancel());
    const token = axios.CancelToken;
    const source: CancelTokenSource = token.source();
    setTokens([...tokens, source]);

    axios
      .get(`${insightHostUrl}/api/search?query=${value}`)
      .then((response) => {
        let results = response.data as any[];
        let filteredResults: any = [];
        results.forEach((data) => {
          data.impactScore = (
            <ImpactScore
              score={data.impactScore}
              type="small"
              key={Math.random() * 10000}
              withAnimation={false}
            />
          );
          filteredResults.push(data);
        });
        setSearchResults(filteredResults);
      })
      .catch((thrown) => {
        //handle error
      });
  };

  const navigateToInstrument = (item: UniversalSearchResultItem) => {
    if (item.isDomestic) {
      navigate(`/${item.ticker}`);
    } else {
      navigate(
        `/${item.ticker}/${item.exchangeLocation
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")}`
      );
    }
  };

  const _handleKeyDown = (e: any) => {
    if (e.key == "Enter") {
      if (searchResults.length) {
        navigateToInstrument(searchResults[0]);
      }
    }
  };

  return (
    <>
      <input
        type="text"
        id="searchmaster"
        placeholder={
          isMobile
            ? "Search by ticker or name"
            : "Search by ticker, company, or fund name"
        }
        onKeyDown={_handleKeyDown}
        style={{
          backgroundImage: `url(${searchIcon})`,
          backgroundPosition: "calc(100% - 25px) 50%",
          backgroundSize: 20,
          backgroundRepeat: "no-repeat",
        }}
        autoFocus={!isMobile && !isTablet}
        onBlur={() => {
          setTimeout(() => {
            setQuery("");
          }, 200);
        }}
        onFocus={(e) => {
          setQuery(e.target.value);
          // setLastChange(new Date());
        }}
        onChange={(event) => {
          setQuery(event.target.value);
          setLastChange(new Date());
        }}
      />
      {query !== "" && searchResults.length > 0 && (
        <>
          <div className="contextMenuContainer" id="tickerSearchResults">
            <ul>
              {searchResults.map((item: UniversalSearchResultItem) => (
                <li>
                  <a>
                    <Row onClick={() => navigateToInstrument(item)}>
                      <Col
                        xs={2}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <p>
                          <strong>{item.ticker}</strong>
                        </p>
                      </Col>
                      <Col
                        xs={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <p>{item.name}</p>
                      </Col>
                      <Col
                        xs={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          paddingTop: 5,
                          paddingRight: 8,
                        }}
                      >
                        {item.impactScore}
                      </Col>
                    </Row>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default TickerSearch;
