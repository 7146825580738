import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Row, Col } from "react-flexbox-grid";
import Pagination from "react-responsive-pagination";
import { useEffect, useState } from "react";
import SelectMenu from "../../components/SelectMenu";
import { Repost } from "styled-icons/boxicons-regular";
import { ArrowCircleDown } from "styled-icons/fluentui-system-filled";
import { doFetchReports } from "../../components/Firebase";
import LoadingIcon from "../../images/dual-ring-loading.svg";
import { IClient, IPlaybook, IReport, IUser } from "../../models";
import { ClientView } from "./Home";
import moment from "moment";

interface ReportsProps {
  addClient: (client: IClient) => void;
  handleClientSelection: (client: IClient) => void;
  user: IUser;
  activePlaybook: IPlaybook;
  fetchClients: () => void;
  handleViewChange: (view: ClientView) => void;
  query: string;
}

const columns: ColumnsType<IReport> = [
  {
    title: "Client Name",
    dataIndex: "clientName",
    key: "clientName",
    sorter: (a, b) => a.clientName.localeCompare(b.clientName),
  },
  {
    title: "Portfolio",
    dataIndex: "household",
    key: "household",
    sorter: (a, b) => a.household.localeCompare(b.household),
  },
  {
    title: "Date",
    dataIndex: "completedDate",
    key: "completedDate",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.completedDate.getTime() - b.completedDate.getTime(),
    render: (date: Date) => (
      <>
        {date.toLocaleDateString()} {date.toLocaleTimeString()}
      </>
    ),
  },
  {
    title: "Download",
    dataIndex: "downloadUrl",
    key: "downloadUrl",
    render: (text: string) => (
      <a href={text}>
        <ArrowCircleDown size="25" />
      </a>
    ),
  },
];

const Reports = (props: ReportsProps) => {
  const {
    addClient,
    handleClientSelection,
    user,
    fetchClients,
    activePlaybook,
    handleViewChange,
  } = props;

  const [reports, setReports] = useState<Array<IReport>>([]);
  const [segmentedReports, setSegmentedReports] = useState<
    Array<IReport> | undefined
  >([]);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportSortBy, setReportSortBy] = useState<string>("Date Created");
  const [reportsPerPage, setReportsPerPage] = useState<number>(10);

  const totalReports = reports.length;
  const totalPages = Math.ceil(totalReports / reportsPerPage);

  useEffect(() => {
    !(reports.length > 0) &&
      !requestSent &&
      doFetchReports(user.uid).then((reports: IReport[]) => {
        setRequestSent(true);
        setReports(reports);
      });
  }, []);

  useEffect(() => {
    handleReportSegmentation();
  }, [reportSortBy, reports, currentPage, reportsPerPage, props.query]);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.query, reportSortBy, reportsPerPage, reports]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleReportSegmentation = () => {
    let sortedReports = reports;

    reportSortBy === "dateCreated"
      ? sortedReports.sort((a, b) => {
          let ad: any = a.completedDate;
          let bd: any = b.completedDate;
          return bd - ad;
        })
      : sortedReports.sort((a, b) => (a.clientName > b.clientName ? 1 : -1));

    // if query, filter out reports that don't match query
    if (props.query !== "") {
      sortedReports = sortedReports.filter((report: IReport) => {
        return (
          report.clientName.toLowerCase().includes(props.query.toLowerCase()) ||
          report.household.toLowerCase().includes(props.query.toLowerCase())
        );
      });
    }

    setSegmentedReports([...sortedReports]);
  };

  return (
    <>
      {requestSent && reports.length > 0 ? (
        <>
          <div className="fullWidthContainer clientPagination whiteBg">
            <div className="fullWidthContent">
              <Row style={{ marginLeft: 0 }}>
                {props.query === "" ? (
                  <>
                    <Col
                      xsOffset={6}
                      mdOffset={props.query === "" ? 3 : 12}
                      xs={6}
                      sm={6}
                      md={5}
                      className="hideOnMobile"
                    >
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={(page) => handlePageChange(page)}
                      />
                    </Col>
                    <Col xs={6} md={2} lg={2} className="hideOnMobile"></Col>

                    <Col xs={6} md={2} lg={2} className="hideOnMobile">
                      <SelectMenu
                        title="Per Page"
                        fullWidth
                        activeState={reportsPerPage}
                        onClick={(value: string) => {
                          setReportsPerPage(parseInt(value));
                          handlePageChange(1);
                        }}
                        options={[
                          {
                            title: "10",
                            value: "10",
                          },
                          {
                            title: "20",
                            value: "20",
                          },
                          { title: "50", value: "50" },
                          { title: "Show All", value: "10000" },
                        ]}
                      />
                    </Col>
                  </>
                ) : (
                  <Col xs>&nbsp;</Col>
                )}
              </Row>
            </div>
          </div>

          <div className="fullWidthContainer" id="mainContent">
            <div className="fullWidthContent">
              <div>
                <Table
                  dataSource={segmentedReports}
                  pagination={{
                    position: [],
                    pageSize: reportsPerPage,
                    current: currentPage,
                  }}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </>
      ) : requestSent && reports.length === 0 ? (
        <div className="fullWidthContainer" id="mainContent">
          <div className="fullWidthContent">
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <h2>No reports found.</h2>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="fullWidthContainer" id="mainContent">
          <div className="fullWidthContent">
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  width="50"
                  style={{ padding: "4px 0" }}
                  src={LoadingIcon}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
