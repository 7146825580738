import { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import Button from "../../components/Button";
import { Plus } from "@styled-icons/feather/Plus";
import { Plus as PlusSmall } from "@styled-icons/fa-solid/Plus";
import Elipsis from "../../images/ellipsis-stroke-light.svg";
import ContextMenu from "../../components/ContextMenu";
import { Minus } from "@styled-icons/fa-solid/Minus";
import { CreateClientModal } from "../../components/Resonate/Home/CreateClientModal";
import Confirm from "../../components/Confirm";
import { IClient, IUser, IPlaybook } from "../../models";
import { ClientView } from "./Home";
import { EditClientModal } from "../../components/Resonate/Home/EditClientModal";
import { doDeleteClient } from "../../components/Firebase";
import Pagination from "react-responsive-pagination";
import SelectMenu from "../../components/SelectMenu";
import UpgradeAccountModal from "../../components/UpgradeAccountModal";
import { LoadingModal } from "../../components/Resonate/Home/LoadingModal";
import { doLaunchCustomerPortal } from "../../components/Firebase";
import { objToDate } from "../../constants";

interface ClientManagementProps {
  addClient: (client: IClient) => void;
  handleClientSelection: (client: IClient) => void;
  user: IUser;
  fetchClients: () => void;
  activePlaybook: IPlaybook | null;
  handleViewChange: (view: ClientView) => void;
  setQuery: (query: string) => void;
  query: string;
  handleFilter: (query: string) => void;
}

export default function ClientManagement(props: ClientManagementProps) {
  const [isActive, setIsActive] = useState(false);

  const [activeClient, setActiveClient] = useState<IClient | null>(null);
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [upgradeAccountModal, setUpgradeAccountModal] =
    useState<boolean>(false);
  const [loadingAccountModal, setLoadingAccountModal] =
    useState<boolean>(false);

  const [segmentedClients, setSegmentedClients] = useState<
    IClient[] | undefined
  >();
  const [currentPage, setCurrentPage] = useState(1);

  // SortBy has two options: by name (default) or dateCreated
  const [clientSortBy, setClientSortBy] = useState<string>("name");
  const [clientsPerPage, setClientsPerPage] = useState<number>(10);

  useEffect(() => {
    handleClientSegmentation();
  }, [clientSortBy, clientsPerPage, currentPage, props.user]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { addClient, user, fetchClients } = props;

  const totalClients = user.clients.length;
  const totalPages = Math.ceil(totalClients / clientsPerPage);

  const handleClientSegmentation = () => {
    let res = [];

    // sorts entire list of clients first
    let sortedClients = user.clients;
    clientSortBy === "name"
      ? sortedClients.sort((a, b) => (a.name > b.name ? 1 : -1))
      : // sorts by dateCreated, converts date object from Firestore
        sortedClients.sort((a, b) => {
          let ad: any = objToDate(a.dateCreated);
          let bd: any = objToDate(b.dateCreated);
          return bd - ad;
        });

    // divides entire clientlist into a new array of chunks
    for (let i = 0; i < sortedClients.length; i += clientsPerPage) {
      const chunk = sortedClients.slice(i, i + clientsPerPage);
      res.push(chunk);
    }

    setSegmentedClients(
      res[currentPage !== 0 ? currentPage - 1 : currentPage] as any
    );
  };

  const handleAddClient = () => {
    if (user.role === "starter" && user.clients.length >= 5) {
      setUpgradeAccountModal(true);
    } else {
      setIsActive(true);
    }
  };

  return (
    <>
      <CreateClientModal
        isActive={isActive}
        onClose={() => setIsActive(false)}
        onCreateClientCallback={addClient}
      />
      <LoadingModal
        message="Loading Account Details"
        isActive={loadingAccountModal}
      />
      <UpgradeAccountModal
        isActive={upgradeAccountModal}
        title="Client Limit Reached"
        message="To continue adding clients, upgrade to the Essential plan."
        onConfirm={() => (
          setUpgradeAccountModal(false),
          setLoadingAccountModal(true),
          doLaunchCustomerPortal()
        )}
        onClose={() => {
          setUpgradeAccountModal(false);
        }}
      />
      {activeClient && (
        <EditClientModal
          isActive={editModalVisible}
          onClose={() => (
            setEditModalVisible(false),
            setTimeout(() => setActiveClient(null), 250)
          )}
          client={activeClient}
        />
      )}
      <Confirm
        isActive={deleteConfirmVisible}
        onClose={() => setDeleteConfirmVisible(false)}
        onConfirm={() => {
          doDeleteClient(activeClient as IClient, fetchClients);
          setDeleteConfirmVisible(false);
        }}
        message="Are you sure you want to delete this client?"
      />

      <div className="fullWidthContainer clientPagination whiteBg">
        <div className="fullWidthContent">
          <Row style={{ marginLeft: 0 }}>
            <Col xs={6} sm={6} md={3} lg={3}>
              <Button onClick={handleAddClient}>
                <Plus
                  width="20"
                  style={{ marginRight: 8, position: "relative", bottom: 2 }}
                />
                Add New Client
              </Button>
            </Col>
            <Col xs={6} sm={6} md={5} lg={5} className="hideOnMobile">
              {props.query === "" && (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  onPageChange={(page) => handlePageChange(page)}
                />
              )}
            </Col>
            {props.query === "" && (
              <>
                <Col xs={6} md={2} lg={2} className="hideOnMobile">
                  <SelectMenu
                    title="Sort By"
                    fullWidth
                    activeState={clientSortBy}
                    onClick={(value: string) => {
                      setClientSortBy(value);
                      handlePageChange(0);
                    }}
                    options={[
                      {
                        title: "Alphabetically",
                        value: "name",
                      },
                      {
                        title: "Date Created",
                        value: "date created",
                      },
                    ]}
                  />
                </Col>
                <Col xs={6} md={2} lg={2} className="hideOnMobile">
                  <SelectMenu
                    title="Per Page"
                    fullWidth
                    activeState={clientsPerPage}
                    onClick={(value: string) => {
                      setClientsPerPage(parseInt(value));
                      handlePageChange(0);
                    }}
                    options={[
                      {
                        title: "10",
                        value: "10",
                      },
                      {
                        title: "20",
                        value: "20",
                      },
                      { title: "50", value: "50" },
                      { title: "Show All", value: "10000" },
                    ]}
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>

      <div id="mainContent" className="fullWidthContainer">
        <div className="fullWidthContent">
          {props.query === "" && user.clients.length > 0
            ? segmentedClients &&
              segmentedClients
                .filter((client: IClient) =>
                  client.name.toLowerCase().includes(props.query.toLowerCase())
                )
                .map((client: IClient) => (
                  <div className="cardRow" key={client.name + client.email}>
                    <div
                      className="cardRowHeader"
                      onClick={() => props.handleClientSelection(client)}
                    >
                      <Row>
                        <Col xs={10}>
                          <h3>
                            {client.name}
                            {client.lastName && " " + client.lastName}
                          </h3>
                        </Col>
                        <Col
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <ContextMenu
                            icon={Elipsis}
                            iconWidth="20"
                            onClick={(value: string) => {
                              setActiveClient(client);
                              if (value === "edit") {
                                setEditModalVisible(true);
                              } else {
                                setDeleteConfirmVisible(true);
                              }
                            }}
                            options={[
                              { title: "Edit", value: "edit" },
                              { title: "Delete", value: "delete" },
                            ]}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))
            : props.query !== "" &&
              user.clients.length > 0 &&
              user.clients
                .filter((client: IClient) =>
                  client.name.toLowerCase().includes(props.query.toLowerCase())
                )
                .map((client: IClient) => (
                  <div className="cardRow" key={client.name + client.email}>
                    <div
                      className="cardRowHeader"
                      onClick={() => props.handleClientSelection(client)}
                    >
                      <Row>
                        <Col xs={10}>
                          <h3>{client.name}</h3>
                        </Col>
                        <Col
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <ContextMenu
                            icon={Elipsis}
                            iconWidth="20"
                            onClick={(value: string) => {
                              setActiveClient(client);
                              if (value === "edit") {
                                setEditModalVisible(true);
                              } else {
                                setDeleteConfirmVisible(true);
                              }
                            }}
                            options={[
                              { title: "Edit", value: "edit" },
                              { title: "Delete", value: "delete" },
                            ]}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
        </div>
      </div>
    </>
  );
}
