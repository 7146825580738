import React from "react";
import { Row, Col } from "react-flexbox-grid";
import Logo from "../../../images/scoreboard-logo.svg";
import { themeState } from "../../../contexts/ThemeContext";
import InsightFooter from "../../Insight/Footer";

function Footer() {
  return themeState.theme === "resonate" ? (
    <div id="footer" className="fullWidthContainer">
      <div className="fullWidthContent">
        <Row>
          <Col xs={12}>
            <img src={Logo} className="logoImg" />
          </Col>
        </Row>
        <Row style={{ margin: "2em 0" }}>
          <Col xs={6}>
            <ul>
              <li>
                <a href="https://www.startresonating.com/about">About</a>
              </li>
              <li>
                <a href="https://www.startresonating.com/support">Contact Us</a>
              </li>
            </ul>
          </Col>
          <Col
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              textAlign: "right",
            }}
          >
            <ul>
              <li>
                <a href="https://www.startresonating.com/terms">Terms</a>
              </li>
              <li>
                <a href="https://www.startresonating.com/internet-privacy-policy">
                  Privacy
                </a>
              </li>
              <li>&copy; Copyright - Inspire Resonate</li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <InsightFooter />
  );
}

export default Footer;
