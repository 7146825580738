import { Row, Col } from "react-flexbox-grid";
import { Plus as PlusSmall } from "@styled-icons/fa-solid/Plus";
import { Minus } from "@styled-icons/fa-solid/Minus";
import { categories } from "../../models/Resonate/playbook";
import { IDetailsAPI } from "./index";
import ViewIssues from "../Resonate/Client/ViewIssues";
import { IHolding } from "../../models";
import { useState } from "react";

interface IssueProps {
  p?: any;
  ckey?: string;
  title: string;
  value?: any;
  handleIssues: (
    financialId: number,
    category: string,
    companyName: string,
    portfolioId: string
  ) => void;
  activeIssueTicker: {
    id: string;
    companyName: string;
    portfolioId: string;
    category: string;
  };
  esgIssues: IDetailsAPI[] | [];
  holding: IHolding;
}

function Issue(props: IssueProps) {
  const {
    p,
    title,
    value,
    handleIssues,
    activeIssueTicker,
    esgIssues,
    holding,
  } = props;

  // const [displayLength, setDisplayLength] = useState<{ [key: string]: number }>(
  //   {}
  // );
  const [displayLength, setDisplayLength] = useState<number>(10);

  return (
    <div className="issueRow">
      <Row>
        <Col xs={12}>
          <div className="pointScore">
            <i className={value.positive ? "pointPositive" : "pointNegative"}>
              {value.positive ? <PlusSmall size="10" /> : <Minus size="10" />}
            </i>
            <span
              className={
                value.playersInvolved.length === 0 ? "issueCategoryClick" : ""
              }
              onClick={() => {
                handleIssues(holding.id, title, holding.ticker, p.id);
              }}
            >
              {(categories as any)[title]}
            </span>
          </div>
        </Col>
      </Row>
      {value.playersInvolved.length > 0 ? (
        <Row>
          <Col xs={12}>
            <ul className="screeningTickers">
              <li className="label">
                {displayLength === 10 && "Top 10"} Companies involved:
              </li>
              {value.playersInvolved
                .slice(0, displayLength)
                .map((item: any) => (
                  <li
                    className="ticker"
                    key={item.id}
                    style={{ marginTop: 15, marginBottom: 5 }}
                  >
                    <a
                      className={
                        activeIssueTicker.id === item.id &&
                        activeIssueTicker.portfolioId === p.id &&
                        activeIssueTicker.category === title
                          ? "activeIssueTicker"
                          : ""
                      }
                      onClick={() =>
                        handleIssues(item.id, title, item.ticker, p.id)
                      }
                    >
                      {item.ticker}
                    </a>
                  </li>
                ))}
              {value.playersInvolved.length > 10 && (
                <li className="ticker view-all">
                  <a
                    onClick={() => {
                      if (displayLength > 10) {
                        setDisplayLength(10);
                      } else {
                        setDisplayLength(value.playersInvolved.length);
                      }
                    }}
                  >
                    {displayLength > 10 ? "VIEW LESS" : "VIEW ALL"}
                  </a>
                </li>
              )}
            </ul>

            {esgIssues.length > 0 &&
              activeIssueTicker.portfolioId === p.id &&
              activeIssueTicker.category === title &&
              Object.values(value.playersInvolved)
                .map((i: any) => i.id)
                .includes(activeIssueTicker.id) && (
                <ViewIssues issues={esgIssues} ticker={activeIssueTicker} />
              )}
          </Col>
        </Row>
      ) : (
        <>
          {esgIssues.length > 0 &&
            activeIssueTicker.portfolioId === p.id &&
            activeIssueTicker.category === title &&
            holding.id === parseInt(activeIssueTicker.id) && (
              <ViewIssues issues={esgIssues} ticker={activeIssueTicker} />
            )}
        </>
      )}
    </div>
  );
}

export default Issue;
