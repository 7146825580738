import { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-flexbox-grid";
import Search from "../images/search-icon.svg";
import { IClient, IUser, IPlaybook } from "../models";
import { ClientView } from "../pages/Resonate/Home";
import { Tooltip } from "antd";
import LoadingIcon from "../images/dual-ring-loading-white.svg";
import LoadingIconGrey from "../images/dual-ring-loading-grey.svg";
import ArrowLineLeft from "../images/arrow-left-line.svg";
import ArrowLineLeftBlack from "../images/arrow-left-line-black.svg";
import Button from "./Button";
import { auth, doGenerateClientReport } from "./Firebase";
import { DocumentOnePage } from "styled-icons/fluentui-system-regular";
import circleInfo from "../images/circle-info-light-white.svg";
import circleInfoBlack from "../images/circle-info-black.svg";
import { toast } from "react-toastify";
import UpgradeAccountModal from "./UpgradeAccountModal";
import { doLaunchCustomerPortal } from "./Firebase";
import { LoadingModal } from "./Resonate/Home/LoadingModal";
import { useNavigate } from "react-router";
import ThemeContext from "../contexts/ThemeContext";
import ImpactScore from "./Insight/ImpactScore";

interface HeroProps {
  user: IUser;
  view: ClientView;

  query: string;

  /* Client Management Page Props*/
  filterItems?: (filterQuery: string) => void;
  onEnterDuringSearch?: () => void;

  /* CLient Page Props */
  onOpenPlaybook?: () => void;
  onBack?: () => void;
  header?: React.ReactNode;
  activeClient?: IClient | null;
  clientScore?: number;
  clientRatio?: number;
  portfoliosReady?: boolean;
  displayRatio?: (ratio: number) => string;
  activePlaybook: IPlaybook | null;
  handleViewChange?: (view: ClientView) => void;
  toastIds?: Array<any>;
  setToastIds?: (toastIds: Array<any>) => void;
}

export default function Hero(props: HeroProps) {
  const [upgradeAccountModal, setUpgradeAccountModal] =
    useState<boolean>(false);
  const [loadingAccountModal, setLoadingAccountModal] =
    useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);

  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    auth.currentUser &&
      auth.currentUser
        .getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims.admin) {
            setAdmin(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <LoadingModal
        message="Loading Account Details"
        isActive={loadingAccountModal}
      />
      <UpgradeAccountModal
        isActive={upgradeAccountModal}
        title="Reports Dashboard Unavailable"
        message="To view reports dashboard, upgrade to the Essential plan."
        onConfirm={() => (
          setUpgradeAccountModal(false),
          setLoadingAccountModal(true),
          doLaunchCustomerPortal()
        )}
        onClose={() => {
          setUpgradeAccountModal(false);
        }}
      />
      <section
        className={
          props.view === ClientView.CLIENT_PAGE ||
          props.view === ClientView.CLIENT_MODELS ||
          props.view === ClientView.CLIENT_FUNDS ||
          props.view === ClientView.PROPOSALS
            ? "clientHeroContainer heroContainer"
            : "heroContainer"
        }
      >
        <div>
          {(props.view === ClientView.CLIENT_PAGE ||
            props.view === ClientView.CLIENT_MODELS ||
            props.view === ClientView.CLIENT_FUNDS ||
            props.view === ClientView.PROPOSALS) && (
            <div
              className="fullWidthContainer"
              style={{
                backgroundColor:
                  theme === "resonate" ? "rgba(255,255,255, 0.06)" : "#f5f5f5",
                position: "absolute",
                zIndex: 2,
              }}
            >
              <div className="fullWidthContent">
                <Row>
                  <Col>
                    <Button
                      preset="heroPrimary"
                      onClick={() => {
                        if (props.onBack) props.onBack();
                      }}
                    >
                      <img
                        src={
                          theme === "resonate"
                            ? ArrowLineLeft
                            : ArrowLineLeftBlack
                        }
                        width="20"
                        style={{
                          marginRight: 8,
                          position: "relative",
                          bottom: 2,
                        }}
                      />
                      Back to All Clients
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <div
            className="fullWidthContent hero"
            style={{
              position: "relative",
            }}
          >
            {props.view === ClientView.CLIENT_PAGE ||
            props.view === ClientView.CLIENT_MODELS ||
            props.view === ClientView.CLIENT_FUNDS ||
            props.view === ClientView.PROPOSALS ? (
              <>
                <Row>
                  <Col xs={12} sm={theme === "resonate" ? 6 : 12}>
                    {theme === "insight" &&
                      props.activeClient?.portfolios[0] &&
                      (props.portfoliosReady ? (
                        <ImpactScore
                          type="large"
                          bgColor={"rgb(251, 239, 229)"}
                          score={props.clientScore?.toFixed(0)}
                          footer
                        />
                      ) : (
                        <div
                          style={{
                            float: "left",
                            display: "flex",
                            justifyContent: "center",
                            marginRight: 20,
                            width: 145,
                          }}
                        >
                          <img
                            width="70"
                            src={LoadingIconGrey}
                            style={{
                              position: "relative",
                              bottom: 7,
                              margin: "0 10px",
                            }}
                          />
                        </div>
                      ))}

                    <h1>
                      {props.activeClient &&
                        props.activeClient.name +
                          " " +
                          props.activeClient.lastName}
                    </h1>
                    {props.header ?? <></>}
                  </Col>

                  <Col xs={12} sm={6}>
                    {props.activeClient &&
                      props.activeClient?.portfolios[0] &&
                      (props.portfoliosReady ? (
                        theme === "resonate" ? (
                          <ul className="heroClientStats">
                            <li>
                              <Tooltip
                                title="The Values Alignment Percentage measures what
                            percentage of an investment is not exposed to any
                            issues deemed 'negative' by the client. For
                            instance, an investment with 75% alignment means
                            that 25% of the investment's assets are exposed to
                            issues deemed 'negative' by the client, while 75% of
                            the investment's assets have no exposure to any
                            issues deemed 'negative' by the client."
                                placement="bottom"
                                color={
                                  theme === "resonate"
                                    ? "rgba(0, 108, 108, 0.9)"
                                    : "rgba(44, 138, 174, 0.9)"
                                }
                              >
                                <h4>
                                  {props.clientScore
                                    ? props.clientScore.toFixed(0)
                                    : "NA"}
                                  <i>%</i>
                                </h4>

                                <span className="scoreLabel">
                                  Values Alignment
                                  <img
                                    style={{ marginTop: -3 }}
                                    src={
                                      theme === "resonate"
                                        ? circleInfo
                                        : circleInfoBlack
                                    }
                                    width="13"
                                  />
                                </span>
                              </Tooltip>
                            </li>
                            <li>
                              <Tooltip
                                title="The Resonate Score&copy; is designed to help
                            investors measure how well a given investment may or
                            may not resonate with the investor's stated values.
                            The Resonate Score is a proprietary calculation that
                            takes into account an investor's opinion on various
                            values-based issues and then calculates a score
                            based on the number of 'negative' and 'positive' (as
                            selected by the investor) categories an investment
                            does or does not have exposure to. The best possible
                            Resonate Score&copy; is '2', the worst possible
                            Resonate Score&copy; is '0'."
                                placement="bottom"
                                color={
                                  theme === "resonate"
                                    ? "rgba(0, 108, 108, 0.9)"
                                    : "rgba(44, 138, 174, 0.9)"
                                }
                              >
                                <h4>
                                  {props.clientRatio && props.displayRatio
                                    ? props.displayRatio(props.clientRatio)
                                    : 0}
                                </h4>

                                <span className="scoreLabel">
                                  Resonate Score{" "}
                                  <img
                                    style={{ marginTop: -3 }}
                                    src={
                                      theme === "resonate"
                                        ? circleInfo
                                        : circleInfoBlack
                                    }
                                    width="13"
                                  />
                                </span>
                              </Tooltip>
                            </li>
                          </ul>
                        ) : (
                          <></>
                        )
                      ) : (
                        theme === "resonate" && (
                          <ul className="heroClientStats">
                            <li>
                              <h4>
                                <img
                                  width="70"
                                  src={
                                    theme === "resonate"
                                      ? LoadingIcon
                                      : LoadingIconGrey
                                  }
                                  style={{
                                    position: "relative",
                                    bottom: 7,
                                    margin: "0 10px",
                                  }}
                                />
                              </h4>

                              <span>Values Alignment</span>
                            </li>
                            <li>
                              <h4>
                                <img
                                  width="70"
                                  src={
                                    theme === "resonate"
                                      ? LoadingIcon
                                      : LoadingIconGrey
                                  }
                                  style={{
                                    position: "relative",
                                    bottom: 7,
                                    margin: "0 10px",
                                  }}
                                />
                              </h4>
                              <span>Resonate Score</span>
                            </li>
                          </ul>
                        )
                      ))}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h1>Welcome, {props.user.firstName}!</h1>
                <h4>{props.user.firm}</h4>
              </>
            )}
          </div>
        </div>

        <div className="heroNav">
          <div className="fullWidthContent">
            <Row>
              <Col
                xs={12}
                sm={theme !== "resonate" ? 12 : 6}
                md={theme !== "resonate" ? 12 : 8}
              >
                {props.view === ClientView.CLIENT_PAGE ||
                props.view === ClientView.CLIENT_MODELS ||
                props.view === ClientView.CLIENT_FUNDS ||
                props.view === ClientView.PROPOSALS ? (
                  <nav className="inlineNavigation">
                    <ul>
                      <li>
                        <a
                          className={
                            props.view === ClientView.CLIENT_PAGE
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            theme === "resonate"
                              ? navigate(`/`)
                              : navigate(`/dashboard`);
                            props.handleViewChange &&
                              props.handleViewChange(ClientView.CLIENT_PAGE);
                          }}
                        >
                          Portfolios
                        </a>
                      </li>
                      {theme === "resonate" && (
                        <>
                          <li className="hideOnMobile">
                            <a onClick={props.onOpenPlaybook}>
                              Screening Profile
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                props.view === ClientView.PROPOSALS
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                props.handleViewChange &&
                                  props.handleViewChange(ClientView.PROPOSALS);
                              }}
                            >
                              Proposals
                            </a>
                          </li>
                        </>
                      )}
                      <li>
                        <a
                          className={
                            props.view === ClientView.CLIENT_MODELS
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            props.handleViewChange &&
                              props.handleViewChange(ClientView.CLIENT_MODELS);
                          }}
                        >
                          Models
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            props.view === ClientView.CLIENT_FUNDS
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            props.handleViewChange &&
                              props.handleViewChange(ClientView.CLIENT_FUNDS);
                          }}
                        >
                          Faith-Based Funds
                        </a>
                      </li>
                    </ul>
                  </nav>
                ) : (
                  <>
                    <nav className="inlineNavigation">
                      <ul>
                        <li>
                          <a
                            className={
                              props.view === ClientView.CLIENT_MANAGEMENT
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              props.handleViewChange &&
                                props.handleViewChange(
                                  ClientView.CLIENT_MANAGEMENT
                                );
                            }}
                          >
                            Clients
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              props.view === ClientView.MODELS ? "active" : ""
                            }
                            onClick={() => {
                              props.handleViewChange &&
                                props.handleViewChange(ClientView.MODELS);
                            }}
                          >
                            Models
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              props.view === ClientView.FUNDS ? "active" : ""
                            }
                            onClick={() => {
                              props.handleViewChange &&
                                props.handleViewChange(ClientView.FUNDS);
                            }}
                          >
                            Faith-Based Funds
                          </a>
                        </li>
                        {theme === "resonate" && (
                          <>
                            <li>
                              <a
                                className={
                                  props.view === ClientView.REPORTS
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  if (props.user.role === "essential") {
                                    props.handleViewChange &&
                                      props.handleViewChange(
                                        ClientView.REPORTS
                                      );
                                  } else {
                                    setUpgradeAccountModal(true);
                                  }
                                }}
                              >
                                Reports Archive
                              </a>
                            </li>
                          </>
                        )}
                        {theme === "insight" && (
                          <li>
                            <a
                              className={
                                props.view === ClientView.EXPORT_DATA
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                if (props.user.role === "essential") {
                                  props.handleViewChange &&
                                    props.handleViewChange(
                                      ClientView.EXPORT_DATA
                                    );
                                } else {
                                  setUpgradeAccountModal(true);
                                }
                              }}
                            >
                              Advanced Search
                            </a>
                          </li>
                        )}
                        {admin && (
                          <li>
                            <a
                              className={
                                props.view === ClientView.ADMIN ? "active" : ""
                              }
                              onClick={() => {
                                if (admin) {
                                  props.handleViewChange &&
                                    props.handleViewChange(ClientView.ADMIN);
                                }
                              }}
                            >
                              Admin
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </>
                )}
              </Col>
              {theme === "resonate" && (
                <Col xs={12} sm={6} md={4} className="alignRight">
                  {props.view === ClientView.CLIENT_PAGE ||
                  props.view === ClientView.PROPOSALS ? (
                    <>
                      <Button
                        preset="heroPrimary"
                        onClick={() => {
                          let requestId = Math.random().toString(16).slice(-4);
                          props.setToastIds &&
                            props.toastIds &&
                            props.activeClient &&
                            props.setToastIds({
                              ...props.toastIds,
                              [requestId]: toast.loading(
                                `Report for ${props.activeClient.name} household is generating`,
                                { closeOnClick: true }
                              ) as any,
                            });
                          props.activeClient &&
                            doGenerateClientReport(
                              props.activeClient,
                              props.activePlaybook,
                              requestId
                            );
                        }}
                      >
                        <DocumentOnePage
                          width="20"
                          style={{
                            marginRight: 8,
                            position: "relative",
                            bottom: 2,
                          }}
                        />
                        Create Household Report
                      </Button>
                    </>
                  ) : (
                    <input
                      type="text"
                      value={props.query}
                      placeholder={
                        props.view === ClientView.REPORTS
                          ? "Search Reports"
                          : props.view === ClientView.CLIENT_MANAGEMENT
                          ? "Search Clients"
                          : props.view === ClientView.FUNDS
                          ? "Search Faith-Based Funds"
                          : props.view === ClientView.CLIENT_FUNDS
                          ? "Search Faith-Based Funds"
                          : "Search Models"
                      }
                      onKeyDown={(event) => {
                        if (
                          event.key == "Enter" &&
                          props.view !== ClientView.REPORTS
                        ) {
                          props.onEnterDuringSearch &&
                            props.onEnterDuringSearch();
                        }
                      }}
                      className="searchClients"
                      style={{
                        background: `url(${Search})`,
                        backgroundPosition: "95% 0.7em",
                        backgroundSize: 20,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(213, 232, 239, 0.4)",
                      }}
                      onChange={(e) => {
                        props.filterItems && props.filterItems(e.target.value);
                      }}
                    />
                  )}
                </Col>
              )}
            </Row>
          </div>
        </div>
      </section>
    </>
  );
}
