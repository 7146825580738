import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { capitalizeFirstLetter } from "../../../constants";
import LoadingIcon from "../../../images/dual-ring-loading-grey.svg";
import { IClient, Model, Portfolio } from "../../../models";
import Button from "../../Button";
import { auth, hostUrl } from "../../Firebase";
import Modal from "../../Modal";
import SelectMenu from "../../SelectMenu";
import { PortfolioTemplate } from "../../Portfolios";

interface UploadPortfolioModalProps {
  isActive: boolean;
  onClose: (callbackData?: any) => void;
  onCancel?: () => void;
  client?: IClient | null;
  portfolio?: Portfolio;
  template: any;
  setActivePortfolio?: (portfolio: Portfolio) => void;
  edit?: boolean;
  onCreatePortfolioCallback?: (client: IClient) => void;
}

export interface IInitPorfolioUpload {
  columns: string[];
  rows: Array<{
    [key: string]: string;
  }>;
}

export interface IFinishPortfolioUpload {
  success: number;
  failed: number;
  errored: Array<{
    reason: string;
    ticker: string;
    "exchange location": string;
    "market value": string;
    fund_id: number | string | null;
  }>;
}

export function UploadPortfolioModal(props: UploadPortfolioModalProps) {
  const [portfolioName, setPortfolioName] = useState("");
  // this activePortfolio is just a local working portfolio. props.setActivePortfolio is the one that is used to set the active portfolio in the parent component
  const [activePortfolio, setActivePortfolio] = useState<Portfolio | null>(
    null
  );

  const [uploadToNewPortfolio, setUploadToNewPortfolio] = useState<
    boolean | null
  >(null);

  const [errorPortfolioNotSelected, setErrorPortfolioNotSelected] =
    useState<boolean>(false);

  // when file extension is correct and ready to upload
  const [uploadReady, setUploadReady] = useState(false);

  // when file is uploading
  const [isUploading, setIsUploading] = useState(false);

  // store the file in the state, 'undefined' throws error
  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(
    null
  );

  // store the results of the /upload-init api request
  const [initUpload, setInitUpload] = useState<IInitPorfolioUpload | null>(
    null
  );

  const [readyToChooseColumns, setReadyToChooseColumns] =
    useState<boolean>(false);

  // user selected column map
  const [columnMap, setColumnMap] = useState<{ [key: string]: string }>({});
  const columnsReadyForUpload =
    Object.values(columnMap).includes("ticker") &&
    Object.values(columnMap).includes("market value");

  const [tickerOrMarketValueError, setTickerOrMarketValueError] =
    useState<boolean>(false);

  // store the results of the /upload-finish api request
  const [finishUpload, setFinishUpload] = useState<IFinishPortfolioUpload>();

  const [parentKeyId, setParentKeyId] = useState(uuidv4());

  const templateName = capitalizeFirstLetter(props.template);

  useEffect(() => {
    if (props.portfolio) {
      setPortfolioName(props.portfolio.name);
      setActivePortfolio(props.portfolio);
    }
  }, [props.portfolio]);

  useEffect(() => {
    columnsReadyForUpload && setTickerOrMarketValueError(false);
  }, [columnsReadyForUpload]);

  const reset = () => {
    setUploadReady(false);
    setIsUploading(false);
    setSelectedFile(null);
    setInitUpload(null);
    setColumnMap({});
  };

  const handleSubmit = () => {
    if (props.client) {
      setIsUploading(true);
      const obj = {
        user_id: auth.currentUser?.uid,
        column_map: columnMap,
        client_id: props.client.id,
        template: props.template,
        ...(activePortfolio
          ? { portfolio_id: activePortfolio.id }
          : { portfolio_name: portfolioName }),
      };

      const json = JSON.stringify(obj);
      const blob = new Blob([json], {
        type: "application/json",
      });
      const data = new FormData();
      data.append("document", blob);
      if (selectedFile) {
        data.append("file", selectedFile);
        axios.post(`${hostUrl}/upload-finish`, data).then((res) => {
          setIsUploading(false);
          setFinishUpload(res.data);
          props.setActivePortfolio &&
            props.setActivePortfolio({
              name: res.data.portfolio.name,
              cash: res.data.portfolio.cash,
              holdings: res.data.portfolio.holdings,
              id: res.data.portfolio.id,
            });
          // setColumnMap({ ...columnMap, [i]: value });
          if (res.data.success > 0) {
            toast.success(`Successfully uploaded ${res.data.success} positions to ${portfolioName}`, {autoClose: 1000 });
          }
          if (res.data.failed > 0) {
            toast.error(`Failed to upload ${res.data.failed} positions to ${portfolioName}`, {autoClose: 1000 });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (props.portfolio) setPortfolioName(props.portfolio.name);
  }, [props.portfolio]);

  useEffect(() => {
    handleInitUpload();
  }, [selectedFile]);

  const handleInitUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      axios.post(`${hostUrl}/upload-init`, formData).then((res) => {
        setInitUpload(res.data);
      });
      setUploadReady(true);
    }
  };

  return (
    <Modal
      fullWidth={true}
      modalTitle={
        finishUpload ? `Upload Summary` : `Upload File To ${templateName}`
      }
      isActive={props.isActive}
      onClose={() => {
        finishUpload ? props.onClose() : props.onCancel && props.onCancel();
        reset();
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        {!readyToChooseColumns ? (
          <>
            <Row>
              <Col xs={12}>
                <div className="inputContainer">
                  <SelectMenu
                    title="Select A Portfolio"
                    fullWidth
                    className={errorPortfolioNotSelected ? "error" : ""}
                    options={
                      props.client?.portfolios
                        ? props.client?.portfolios
                            .filter((p) => (
                              props.template === PortfolioTemplate.PORTFOLIO ||
                              props.template === PortfolioTemplate.PROPOSAL ||
                              (Object.keys(p).includes("tags") && (p as Model).tags.includes("my-models"))
                            ))
                            .map((p) => ({
                              title: p.name,
                              value: p.id,
                            }))
                            .concat({
                              title: "Create New Portfolio From Upload",
                              value: "create-new-portfolio",
                            })
                        : [
                            {
                              title: "No portfolios found",
                              value: "",
                            },
                          ]
                    }
                    onClick={(value) => {
                      const portfolio = props.client?.portfolios?.find(
                        (p) => p.id === value
                      );
                      if (portfolio) {
                        setActivePortfolio(portfolio);
                        props.setActivePortfolio &&
                          props.setActivePortfolio(portfolio);
                        setPortfolioName(portfolio.name);
                        setUploadToNewPortfolio(false);
                      } else {
                        setPortfolioName("");
                        setUploadToNewPortfolio(true);
                      }
                      setErrorPortfolioNotSelected(false);
                    }}
                  />
                  {errorPortfolioNotSelected ? (
                    <p className="input-message error">
                      Please select a portfolio.
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
            {uploadToNewPortfolio && (
              <Row>
                <Col xs={12}>
                  <div className="inputContainer">
                    <label htmlFor="portfolio-name">{`New ${templateName} Name:`}</label>
                    <input
                      type="text"
                      className={portfolioName === "" ? "filled" : ""}
                      id="portfolio-name"
                      value={portfolioName}
                      onChange={(e) => setPortfolioName(e.target.value)}
                      placeholder={props.portfolio ? portfolioName : "-"}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <div className="radioContainer inputContainer">
                  <h3>File types supported: .csv, .xlsx, .xls</h3>
                  <Row>
                    <Col
                      xs={12}
                      className={selectedFile === undefined ? "error" : ""}
                    >
                      <input
                        type="file"
                        id="portfolio-file"
                        onChange={(e: any) => {
                          const file: File = e.target.files[0];
                          setSelectedFile(file);
                        }}
                      />
                    </Col>
                    {selectedFile === undefined && (
                      <p
                        className="input-message error"
                        style={{ marginBottom: 0 }}
                      >
                        Please select a file.
                      </p>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div>
            {!finishUpload ? (
              <>
                {isUploading ? (
                  <ul
                    className="multi-input-message"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={LoadingIcon}
                      style={{
                        width: 40,
                      }}
                    />
                    <span style={{ fontWeight: "bold" }}>
                      Processing upload{" "}
                    </span>
                  </ul>
                ) : (
                  <>
                    <h3>
                      Almost there! Help us understand what to do with your data
                      below. Please note:
                    </h3>
                    <ul
                      className={
                        tickerOrMarketValueError
                          ? "multi-input-message error"
                          : "multi-input-message"
                      }
                    >
                      <li
                        className={
                          tickerOrMarketValueError ? "input-message error" : ""
                        }
                      >
                        Ticker and Market Value are required. A header is
                        required on your spreadsheet.
                      </li>
                    </ul>
                    <Row key={parentKeyId}>
                      {initUpload &&
                        initUpload.columns.map((i, index) => (
                          <Col
                            xs={12}
                            sm={index % 2 === 0 ? 5 : 6}
                            style={{
                              backgroundColor: "#E7ECEE",
                              marginBottom: 15,
                              padding: 0,
                              marginRight: 15,
                            }}
                          >
                            <SelectMenu
                              title="Make A Selection"
                              fullWidth
                              disabled={columnsReadyForUpload}
                              options={
                                columnsReadyForUpload
                                  ? [
                                      {
                                        title: "Reset All Selections",
                                        value: "reset",
                                      },
                                    ]
                                  : [
                                      {
                                        title: "Ticker",
                                        value: "ticker",
                                      },
                                      {
                                        title: "Market Value",
                                        value: "market value",
                                      },
                                    ].filter(
                                      (k) =>
                                        !Object.values(columnMap).includes(
                                          k.value
                                        )
                                    )
                              }
                              onClick={
                                columnsReadyForUpload
                                  ? () => {
                                      setColumnMap({});
                                      setParentKeyId(uuidv4());
                                    }
                                  : (value) => {
                                      setColumnMap({
                                        ...columnMap,
                                        [i]: value,
                                      });
                                    }
                              }
                            />
                            {initUpload.rows.map((k) => (
                              <p
                                style={{
                                  borderTop: "1px solid #c8cdd0",
                                  padding: "10px",
                                  marginBottom: 0,
                                  minHeight: 46,
                                }}
                              >
                                {k[i]}
                              </p>
                            ))}
                          </Col>
                        ))}
                    </Row>
                  </>
                )}
              </>
            ) : (
              <>
                <ul className="multi-input-message">
                  <li>The new portfolio has been created</li>
                  <li>Successfully uploaded: {finishUpload.success} tickers</li>
                  <li>
                    Failed to upload: {finishUpload.errored.length} tickers
                  </li>
                  <li>The following companies errored in upload:</li>
                  {finishUpload.errored.map((i) => (
                    <Row>
                      <Col xs={4}>
                        <strong>{i.ticker}</strong>
                      </Col>
                      <Col xs={8}>{i.reason}</Col>
                    </Row>
                  ))}
                </ul>
              </>
            )}
          </div>
        )}
      </form>
      <Row style={{ paddingTop: 30 }}>
        <Col xs={12} sm={6} lgOffset={!finishUpload ? 2 : 0} lg={4}>
          {!finishUpload && (
            <Button
              fullWidth
              onClick={() => (
                props.onCancel && props.onCancel(), setPortfolioName("")
              )}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Button>
          )}
        </Col>
        <Col xs={12} sm={6} lgOffset={0} lg={4}>
          {!finishUpload && !isUploading ? (
            <Button
              style={{ cursor: "pointer" }}
              disabled={
                Object.keys(columnMap).length === 0 &&
                (Object.keys(columnMap).includes("market value") ||
                  Object.keys(columnMap).includes("percentage"))
              }
              preset="formPrimary"
              fullWidth
              onClick={() => {
                if (!readyToChooseColumns) {
                  uploadToNewPortfolio === null
                    ? setErrorPortfolioNotSelected(true)
                    : selectedFile === null || selectedFile === undefined
                    ? setSelectedFile(undefined)
                    : setReadyToChooseColumns(true);
                } else {
                  if (!columnsReadyForUpload) {
                    setTickerOrMarketValueError(true);
                  } else {
                    handleSubmit();
                    setErrorPortfolioNotSelected(false);
                  }
                }
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              fullWidth
              style={{ cursor: "pointer" }}
              preset="formPrimary"
              onClick={() => {
                reset();
                props.onClose();
              }}
            >
              Done
            </Button>
          )}
        </Col>
      </Row>
    </Modal>
  );
}
