import axios from "axios";
import { sendPasswordResetEmail } from "firebase/auth";
import { Col, Row } from "react-flexbox-grid";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Button from "../components/Button";
import { auth } from "../components/Firebase";
import { themeState } from "../contexts/ThemeContext";
import InsightLogo from "../images/Insight/insight-logo.svg";
import Logo from "../images/scoreboard-logo.svg";
import emails from "../migrations/emails.json";

function ForgotPassword() {
  const navigate = useNavigate();

  const handlePasswordReset = (e: any) => {
    e.preventDefault();
    const username = e.target.elements[0].value;
    if (themeState.theme === "insight" && emails.includes(username)) {
      axios
        .post(
          `https://api.inspireinsight.com/api/account/reset-password/init`,
          username,
          {
            headers: {
              "Content-Type": "text/xml",
            },
          }
        )
        .then(() => {
          toast.success("Please check your email", {autoClose: 1000 });
        });
    } else {
      sendPasswordResetEmail(auth, username).then(() => {
        navigate("/login");
        toast.success("Please check your email", {autoClose: 1000 });
      });
    }
  };

  return (
    <>
      <div className="fullWidthContainer whiteBg">
        <ToastContainer position="bottom-center" hideProgressBar />
        <div className="fullWidthContent">
          <Row>
            <Col>
              <a href="#">
                <img
                  className="logoImg"
                  src={themeState.theme === "resonate" ? Logo : InsightLogo}
                />
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div id="mainContent" className="fullWidthContainer">
        <div className="fullWidthContent">
          {/* <Row>{auth.currentUser?.uid}</Row> */}

          <form onSubmit={handlePasswordReset}>
            <Row>
              <Col mdOffset={3} md={6} xs={12}>
                <h2>Reset Password</h2>
                <div className="inputContainer">
                  <label htmlFor="login-email">Email:</label>
                  <input id="login-email" type="text" name="email" />
                </div>
                <Row>
                  <Col xs={12} sm={12}>
                    <Button type="submit" fullWidth preset="formPrimary">
                      Send Reset Email
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
