import { useState, useEffect, useContext } from "react";
import { IClient, IPlaybook, IUser, Portfolio } from "../../models";
import { Row, Col } from "react-flexbox-grid";
import Button from "../../components/Button";
import { Plus } from "@styled-icons/feather/Plus";
import { FilePlus } from "@styled-icons/feather/FilePlus";
import { ClientView } from "./Home";
import { PlaybookPosition } from "../../models/Resonate/playbook";
import { CreateClientModal } from "../../components/Resonate/Home/CreateClientModal";
import { CreatePortfolioModal } from "../../components/Resonate/Home/CreatePortfolioModal";

import Confirm from "../../components/Confirm";
import { db, doDeleteClient } from "../../components/Firebase";
import Portfolios, { PortfolioTemplate } from "../../components/Portfolios";
import { toast } from "react-toastify";
import { collection, onSnapshot } from "firebase/firestore";
import { v4 as uuid4 } from "uuid";
import SelectMenu from "../../components/SelectMenu";
import PREBUILT_PLAYBOOKS from "../../constants";
import ThemeContext from "../../contexts/ThemeContext";

interface ClientPageProps {
  client: IClient;
  setActiveClient: (client: IClient) => void;
  user: IUser;
  activePlaybook: IPlaybook | null;
  view: ClientView;
  updatePlaybook: (
    category: string,
    value: PlaybookPosition,
    playbookMenuTitle: string,
    clientCreatedProfileId?: string,
    prebuiltProfile?: {}
  ) => void;
  handleViewChange: (view: ClientView) => void;
  resetPlaybook: () => void;
  updateClient: (client: IClient) => void;
  handlePlaybookSave: (
    playbookMenuTitle: string,
    isClientCreatedPlaybook: boolean
  ) => void;
  fetchClients: () => void;
  setEditUserModalVisible: (arg0: boolean) => void;
  setActivePlaybook: (playbook: IPlaybook) => void;
  portfoliosReady: boolean;
  setPortfoliosReady: (arg0: boolean) => void;
  setClientScore: (arg0: number) => void;
  setClientRatio: (arg0: number) => void;
  toastIds: any;
  setToastIds: (arg0: {}) => void;
  displayRatio: (arg0: number) => string;
  setPlaybookVisible: (arg0: boolean) => void;
  playbookVisible: boolean;
  setEditModalVisible: (arg0: boolean) => void;
  editModalVisible: boolean;
  deleteConfirmVisible: boolean;
  setDeleteConfirmVisible: (arg0: boolean) => void;
  handleClientSelection: (arg0: IClient, arg1?: string) => void;
  urlClientId?: string;
  urlPortfolioId?: string;
}

export default function Client(props: ClientPageProps) {
  const [createPortfolioModalVisible, setCreatePortfolioModalVisible] =
    useState<boolean>(false);

  const [uploadPortfolioModalVisible, setUploadPortfolioModalVisible] =
    useState<boolean>(false);

  const [reports, setReports] = useState<Array<any>>([]);
  const [randomPortfolioKey, setRandomPortfolioKey] = useState("");

  const {
    user,
    client,
    handleViewChange,
    handlePlaybookSave,
    updatePlaybook,
    resetPlaybook,
    fetchClients,
    setEditUserModalVisible,
    activePlaybook,
    setActivePlaybook,
    toastIds,
    setToastIds,
    displayRatio,
    setPlaybookVisible,
    playbookVisible,
    setEditModalVisible,
    editModalVisible,
    deleteConfirmVisible,
    setDeleteConfirmVisible,
    urlClientId,
    urlPortfolioId,
  } = props;

  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    onSnapshot(
      collection(db, `users/${props.user.uid}/reports`),
      (snapshot) => {
        setReports(snapshot.docs.map((doc: any) => doc.data()));
      }
    );
  }, []);

  useEffect(() => {
    setRandomPortfolioKey(uuid4());
  }, [props.view]);

  useEffect(() => {
    if (Object.keys(toastIds).length > 0) {
      let sortedReports = reports.sort((a: any, b: any) => {
        if (a.completedDate < b.completedDate) {
          return 1;
        }
        if (a.completedDate > b.completedDate) {
          return -1;
        }

        return 0;
      });

      const link = document.createElement("a");
      link.href = sortedReports[0].downloadUrl;
      link.setAttribute("download", sortedReports[0].fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      let toastToDismiss = sortedReports[0].requestId;
      toast.dismiss(toastIds[toastToDismiss]);

      let copy = { ...toastIds };
      delete copy[toastToDismiss];
      setToastIds(copy);
    }
  }, [reports]);

  const renderTemplateString = () => {
    switch (props.view) {
      case ClientView.CLIENT_PAGE:
        return PortfolioTemplate.PORTFOLIO;
        break;
      case ClientView.PROPOSALS:
        return PortfolioTemplate.PROPOSAL;
        break;
      case ClientView.CLIENT_INSIGHT_REPORT:
        return PortfolioTemplate.PORTFOLIO;
      default:
        return PortfolioTemplate.PORTFOLIO;
    }
  };

  const playbookToast = () => (
    <span
      onClick={() => {
        setPlaybookVisible(true);
      }}
    >
      Please{" "}
      <a style={{ textDecoration: "underline" }} href="#">
        configure a playbook{" "}
      </a>{" "}
      before creating a portfolio.
    </span>
  );

  return (
    <>
      <CreateClientModal
        isActive={editModalVisible}
        onClose={(user: IClient) => {
          setEditModalVisible(false);
          if (user) props.updateClient(user);
        }}
        client={client}
        edit
      />
      <Confirm
        isActive={deleteConfirmVisible}
        onClose={() => setDeleteConfirmVisible(false)}
        onConfirm={() =>
          doDeleteClient(client, fetchClients).then(() => {
            setDeleteConfirmVisible(false);
            handleViewChange(ClientView.CLIENT_MANAGEMENT);
          })
        }
        message="Are you sure you want to delete this client?"
      />
      {props.view !== ClientView.CLIENT_INSIGHT_REPORT && (
        <div className="fullWidthContainer whiteBg clientPagination">
          <div className="fullWidthContent">
            <Row>
              <Col xs={6}>
                <Button
                  onClick={() => setCreatePortfolioModalVisible(true)}
                  style={{ marginRight: 10 }}
                >
                  <Plus
                    width="20"
                    style={{ marginRight: 8, position: "relative", bottom: 2 }}
                  />
                  Add{" "}
                  {props.view === ClientView.CLIENT_PAGE
                    ? "Portfolio"
                    : "Proposal"}
                </Button>
                {props.view === ClientView.CLIENT_PAGE && (
                  <Button onClick={() => setUploadPortfolioModalVisible(true)}>
                    <FilePlus
                      width="20"
                      style={{
                        marginRight: 8,
                        position: "relative",
                        bottom: 2,
                      }}
                    />
                    Upload Portfolio
                  </Button>
                )}
              </Col>
              <Col smOffset={3} sm={3} xs={6} className="hideOnMobile">
                <SelectMenu
                  title="View As"
                  fullWidth
                  activeState={props.client?.id}
                  onClick={(value: string) => {
                    if (
                      ["christian", "environmental", "catholic"].includes(value)
                    ) {
                      const client = props.client;
                      const playbook = PREBUILT_PLAYBOOKS[value];
                      if (client) {
                        props.setActiveClient({
                          ...client,
                          playbooks: [
                            {
                              positions: playbook,
                              dateSaved: new Date(),
                              playbookName: value,
                              clientCreated: false,
                              active: true,
                              id: "prebuilt",
                            },
                          ],
                        });
                        props.setActivePlaybook({
                          positions: playbook,
                          dateSaved: new Date(),
                          playbookName: value,
                          clientCreated: false,
                          active: true,
                          id: "prebuilt",
                        });
                      } else {
                        props.setActivePlaybook({
                          positions: playbook,
                          dateSaved: new Date(),
                          playbookName: value,
                          clientCreated: false,
                          active: true,
                          id: "prebuilt",
                        });
                        props.setActiveClient({
                          id: "test",
                          name: "Hello",
                          email: "hello@gmail.com",
                          playbooks: [
                            {
                              positions: playbook,
                              dateSaved: new Date(),
                              playbookName: value,
                              clientCreated: false,
                              active: true,
                              id: "prebuilt",
                            },
                          ],
                          portfolios: props.client.portfolios,
                          dateCreated: new Date(),
                        });
                      }
                      setRandomPortfolioKey(uuid4());
                    } else {
                      console.log("view as client");
                      let client = user.clients.find((c) => c.id === value);
                      if (client && props.setActiveClient) {
                        props.setActiveClient(client);
                        let ap = client.playbooks.find((p) => p.active);
                        if (ap) {
                          props.setActivePlaybook(ap);
                        }
                        setRandomPortfolioKey(uuid4());
                      }
                    }
                  }}
                  options={[
                    ...[
                      {
                        title: "Christian (Catholic USCCB)",
                        value: "catholic",
                      },
                      {
                        title: "Christian (Protestant)",
                        value: "christian",
                      },
                    ],
                  ]}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}

      <Portfolios
        key={randomPortfolioKey}
        urlClientId={urlClientId}
        urlPortfolioId={urlPortfolioId}
        setActiveClient={props.setActiveClient}
        view={props.view}
        template={renderTemplateString()}
        activePlaybook={activePlaybook}
        setActivePlaybook={setActivePlaybook}
        setPlaybookVisible={setPlaybookVisible}
        handleClientSelection={props.handleClientSelection}
        updatePlaybook={updatePlaybook}
        resetPlaybook={resetPlaybook}
        handlePlaybookSave={handlePlaybookSave}
        playbookVisible={playbookVisible}
        displayRatio={displayRatio}
        onClick={(modalVisible: boolean) =>
          setCreatePortfolioModalVisible(modalVisible)
        }
        client={props.client}
        user={user}
        refreshUser={props.fetchClients}
        createPortfolioModalOpen={createPortfolioModalVisible}
        setCreatePortfolioModalOpen={setCreatePortfolioModalVisible}
        uploadPortfolioModalOpen={uploadPortfolioModalVisible}
        setUploadPortfolioModalOpen={setUploadPortfolioModalVisible}
        portfoliosReady={props.portfoliosReady}
        setPortfoliosReady={props.setPortfoliosReady}
        setClientScore={props.setClientScore}
        setClientRatio={props.setClientRatio}
        setEditUserModalVisible={setEditUserModalVisible}
        toastIds={props.toastIds}
        setToastIds={props.setToastIds}
      />
    </>
  );
}
