import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UniversalSearchResultItem } from "../../models/Resonate/api";
import axios from "axios";
import {
  IEsgCriterionCount,
  IEsgCriterionRollup,
  IInstrumentDetails,
} from "../../models/Insight/api";
import ImpactScore from "../../components/Insight/ImpactScore";
import { Row, Col } from "react-flexbox-grid";
import Header from "../../components/Insight/Header";
import Holdings from "../../components/Insight/Holdings";
import CompositeIssues from "../../components/Insight/CompositeIssues";
import CompanyIssues, {
  checkPolarity,
} from "../../components/Insight/CompanyIssues";
import Footer from "../../components/Insight/Footer";
import Table from "antd/es/table";
import { IFundHoldingApi } from "../../models/Insight/api";
import Button from "../../components/Button";
import Loader from "../../components/Insight/Loader";
import { useNavigate } from "react-router";
import { insightHostUrl } from "../../components/Firebase";

const InstrumentDetails = () => {
  const { ticker, location } = useParams<{
    ticker: string;
    location?: string;
  }>();

  const [id, setId] = useState<number>(0);
  const [details, setDetails] = useState<IInstrumentDetails>();

  const [positiveCount, setPositiveCount] = useState<number>(0);
  const [negativeCount, setNegativeCount] = useState<number>(0);
  const [negativeExposure, setNegativeExposure] = useState<number>(0);
  const [exposureCalculated, setExposureCalculated] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${insightHostUrl}/api/search?query=${ticker}`, {})
      .then((response) => {
        const searchResults = response.data as UniversalSearchResultItem[];

        const id = getInstrumentId(
          searchResults,
          ticker as any,
          location
        ) as number;
        fetchDetailsById(id);
      });
  }, [ticker]);

  const fetchDetailsById = async (id: number) => {
    await axios.get(`${insightHostUrl}/api/tickers/${id}`).then((response) => {
      console.log(response.data);
      if (response.data.esgIssueCriteriaRollup) {
        let data: IEsgCriterionRollup[] = response.data.esgIssueCriteriaRollup;
        setPositiveCount(
          response.data.esgIssueCriteriaRollup
            .map((i: IEsgCriterionRollup) =>
              checkPolarity(i) !== "negative" ? i.numberOfHoldings : 0
            )
            .reduce((a: number, b: number) => a + b, 0)
        );
        setNegativeCount(
          response.data.esgIssueCriteriaRollup
            .map((i: IEsgCriterionRollup) =>
              checkPolarity(i) == "negative" ? i.numberOfHoldings : 0
            )
            .reduce((a: number, b: number) => a + b, 0)
        );
      } else if (response.data.esgIssueCriteriaCount) {
        setPositiveCount(
          response.data.esgIssueCriteriaCount
            .map((i: IEsgCriterionCount) =>
              checkPolarity(i) !== "negative" ? i.count : 0
            )
            .reduce((a: number, b: number) => a + b, 0)
        );

        setNegativeCount(
          response.data.esgIssueCriteriaCount
            .map((i: IEsgCriterionCount) =>
              checkPolarity(i) == "negative" ? i.count : 0
            )
            .reduce((a: number, b: number) => a + b, 0)
        );
      }

      setDetails(response.data);
    });
  };

  const getInstrumentId = (
    searchResults: Array<UniversalSearchResultItem>,
    tickerRaw: string,
    exchange?: string
  ) => {
    let id;
    const ticker = tickerRaw.toUpperCase();

    if (!searchResults.length) {
    }

    if (exchange) {
      //exchange is specified.
      searchResults.forEach((item: UniversalSearchResultItem) => {
        if (
          item.exchangeLocation === `${exchange.toUpperCase()}` &&
          item.ticker === ticker
        ) {
          id = item.id;
        }
      });
    } else {
      //no exchange is specified

      //strip all companies out with a different ticker
      let resultSet = searchResults.filter((item) => item.ticker === ticker);

      //check if there is US company to default to
      resultSet.forEach((result) => {
        if (result.exchangeLocation === "UNITED_STATES_OF_AMERICA") {
          id = result.id; //return the result if so
        }
      });

      //if there are no US Companies to default to, simply return the first result
      if (!id && resultSet.length) {
        id = resultSet[0].id;
      }
    }

    if (id) {
      setId(id);
      return id;
    } else {
      //if no id has been found, redirect to the 404 page
      alert("invalid security");
    }
  };

  return (
    <>
      <Header />
      <div id="mainContent" className="fullWidthContainer">
        <div className="fullWidthContent">
          {details !== undefined ? (
            <>
              <Row>
                <Col xs={12} sm={7} md={7} className="instrumentTicker">
                  <ImpactScore
                    score={details.impactScore}
                    type="large"
                    key={Math.random() * 10000}
                    bgColor={"rgb(251, 239, 229)"}
                    footer
                  />
                  <div className="instrumentTitle">
                    <h1>{details.ticker}</h1>
                    <h3>{details.name}</h3>
                  </div>
                </Col>
                <Col xs={12} sm={5} md={5} className="instrumentMeta">
                  <Row>
                    <Col xs={6} className="detail">
                      <strong>{positiveCount}</strong> Positives
                    </Col>
                    {details.hasConstituents ? (
                      <Col xs={6} className="negative detail">
                        {exposureCalculated ? (
                          <>
                            <strong>{negativeExposure.toFixed(0)}</strong>%
                            Negative Exposure
                          </>
                        ) : (
                          <Loader red={true} inline={true} />
                        )}
                      </Col>
                    ) : (
                      <Col xs={6} className="negative detail">
                        <strong>{negativeCount}</strong> Negatives
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: 30 }}>
                <Col xs={12}>
                  <div className="cardRowContent cardRowContentScreening">
                    {details.hasConstituents ? (
                      <CompositeIssues id={id} details={details} />
                    ) : (
                      <CompanyIssues id={id} details={details} />
                    )}
                  </div>
                </Col>
              </Row>
              {details.hasConstituents && (
                <Holdings
                  id={id}
                  negativeExposureCb={(num: any) => (
                    setExposureCalculated(true), setNegativeExposure(num)
                  )}
                />
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InstrumentDetails;
