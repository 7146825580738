import React, { useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import ImpactScore from "./ImpactScore";
import { UniversalSearchResultItem } from "../../models/Resonate/api";
import { useNavigate } from "react-router";

interface TickerListProps {
  tickers: any;
  listTitle?: any;
}

function TickerList(props: TickerListProps) {
  const navigate = useNavigate();

  const navigateToInstrument = (item: any) => {
    // if (item.isDomestic) {
    navigate(`/${item.ticker}`);
    // } else {
    //   navigate(
    //     `/${item.ticker}/${item.exchangeLocation
    //       .replace("(", "")
    //       .replace(")", "")
    //       .replace(" ", "")}`
    //   );
    // }
  };

  return (
    <>
      {props.tickers && (
        <Row className="homeMiniTickers">
          {props.listTitle && (
            <Col xs={12} style={{ padding: 0 }}>
              <h3>{props.listTitle}</h3>
            </Col>
          )}
          {props.tickers.map((ticker: any, index: number) => {
            return (
              <Row
                className="miniTicker"
                style={{ cursor: "pointer" }}
                onClick={() => navigateToInstrument(ticker)}
                key={index}
              >
                <Col xs={2} style={{ display: "flex", alignItems: "center" }}>
                  <p>
                    <strong>{ticker.ticker}</strong>
                  </p>
                </Col>
                <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
                  <p>
                    {
                      // show ticker name limited to 30 chars and add ... if longer
                      ticker.name.length > 25
                        ? ticker.name.substring(0, 25) + "..."
                        : ticker.name
                    }
                  </p>
                </Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingTop: 5,
                    paddingRight: 8,
                  }}
                >
                  <ImpactScore
                    type="small"
                    key={Math.random() * 10000}
                    bgColor={"rgb(251, 239, 229)"}
                    score={ticker.impactScore}
                  />
                </Col>
              </Row>
            );
          })}
        </Row>
      )}
    </>
  );
}

export default TickerList;
