import React from "react";
import { Row, Col } from "react-flexbox-grid";

function Footer() {
  return (
    <div
      className="fullWidthContainer footerContainer"
      style={{ marginTop: 50 }}
    >
      <Row>
        <Col xs={7} style={{ paddingRight: 0 }}>
          <ul>
            <li>
              <a href="https://legacy.inspireinsight.com/terms-of-service">
                Terms
              </a>
            </li>
            <li>
              <a href="https://legacy.inspireinsight.com/privacy-policy">
                Privacy
              </a>
            </li>
            {/* <li>
              <a href="#">Release Notes</a>
            </li> */}
            <li>
              <a href="https://legacy.inspireinsight.com/help-and-support">
                Help &amp; Support
              </a>
            </li>
            <li>
              <a href="https://legacy.inspireinsight.com/screening-categories">
                Screening Categories
              </a>
            </li>
          </ul>
        </Col>
        <Col
          xs={5}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: 0,
          }}
        >
          <p>&copy; Copyright - Inspire Insight</p>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
