import { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import useOnClickOutside from "use-onclickoutside";
import { isMobile, isTablet } from "react-device-detect";
import LoadingIcon from "../images/dual-ring-loading-grey.svg";
import { INotification, IUser } from "../../models";
import { LoadingModal } from "./Home/LoadingModal";
import Logo from "../../images/scoreboard-logo.svg";
import InsightLogo from "../../images/Insight/insight-logo.svg";
import { themeState } from "../../contexts/ThemeContext";

import { doSignOut, removeNotification } from "../Firebase";
import { Menu } from "@styled-icons/feather/Menu";
import { doLaunchCustomerPortal } from "../Firebase";
import { toast } from "react-toastify";
import { Bell } from "@styled-icons/bootstrap/Bell";
import { SecurityUpdateDimensions } from "@styled-icons/material/SecurityUpdate";
import { NotificationsActiveDimensions } from "@styled-icons/material/NotificationsActive";
import { Block } from "styled-icons/fluentui-system-regular";
import { cursorTo } from "readline";

interface HeaderProps {
  user: IUser;
  notifications: Array<INotification>;
  notificationsOpen: boolean;
  setNotificationsOpen: (arg0: boolean) => void;
  setNotifications: (arg0: Array<INotification>) => void;
  refreshUser: () => void;
  setEditUserModalVisible: (arg0: boolean) => void;
  findAndSetClient: (arg0: string) => void;
}

function Header(props: HeaderProps, close: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoadingAccountDetails, setIsLoadingAccountDetails] = useState(false);
  const [toastsFired, setToastsFired] = useState<number>(0);

  const ref = useRef(null);
  useOnClickOutside(
    ref,
    () => (setOpenMenu(false), props.setNotificationsOpen(false))
  );

  return (
    <>
      {isLoadingAccountDetails && (
        <LoadingModal
          message="Loading Account Details"
          isActive={isLoadingAccountDetails}
        />
      )}

      <div className="fullWidthContainer whiteBg">
        <header className="fullWidthContent header whiteBg" ref={ref}>
          <Row
            style={{
              position: "relative",
              zIndex: 10,
            }}
          >
            <Col xs={10}>
              <a href="/">
                <img
                  src={themeState.theme === "resonate" ? Logo : InsightLogo}
                  className="logoImg"
                />
              </a>
            </Col>

            <Col
              xs={2}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                position: "relative",
                display: "flex",
              }}
            >
              <div
                style={{
                  position: "relative",
                  marginRight: 40,
                  cursor: "pointer",
                }}
                onClick={() => (
                  props.setNotificationsOpen(!props.notificationsOpen),
                  setOpenMenu(false)
                )}
              >
                <>
                  <div
                    style={{
                      display:
                        props.notifications.length > 0 ? "block" : "none",
                      width: 10,
                      position: "absolute",
                      right: -5,
                      backgroundColor: "red",
                      height: 10,
                      borderRadius: 50,
                    }}
                  ></div>
                  <Bell width={25} className="headerIcon" />
                </>
              </div>
              <a
                href="#"
                onClick={() => (
                  setOpenMenu(!openMenu), props.setNotificationsOpen(false)
                )}
                style={{
                  color: "#0b192c",
                  marginRight: 10,
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <Menu
                  className="headerIcon"
                  style={{
                    width: 33,
                  }}
                />
              </a>
            </Col>
          </Row>
          <nav
            style={{
              top: openMenu ? "70px" : "-290px",
              opacity: openMenu ? "1" : "0",
              position: "absolute",
              right: isMobile && !isTablet ? 15 : 40,
              width: 300,
            }}
            className="primaryNavigation accountMenu whiteBg"
          >
            <div
              style={{
                borderBottom: "1px 0solid #b1b1b1",
                padding: "1.25em",
              }}
            >
              <h3>{props.user.firm}</h3>
              <h4>{props.user.email}</h4>
            </div>

            <ul>
              <li>
                <a
                  href="#"
                  onMouseDown={() => (
                    props.setEditUserModalVisible(true), setOpenMenu(false)
                  )}
                >
                  Edit Account
                </a>
              </li>
              <li>
                <a
                  onMouseDown={() => {
                    // doCreateCheckout();
                    setIsLoadingAccountDetails(true);
                    doLaunchCustomerPortal();
                  }}
                >
                  Manage Subscription
                </a>
              </li>
              <li>
                <a
                  onMouseDown={() => {
                    doSignOut();
                    window.location.reload();
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
          <nav
            style={{
              top: props.notificationsOpen ? "70px" : "-800px",
              opacity: props.notificationsOpen ? "1" : "0",
              pointerEvents: props.notificationsOpen ? "auto" : "none",
              position: "absolute",
            }}
            className="primaryNavigation notifications whiteBg"
          >
            {/* <div
            style={{
              borderBottom: "1px solid #b1b1b1",
              padding: "1.25em",
            }}
          >
            <h3>{props.user.firm}</h3>
            <h4>{props.user.email}</h4>
          </div> */}

            <ul>
              {props.notifications.length > 0 ? (
                props.notifications.map((noti: INotification) => (
                  <div>
                    <li
                      onClick={() => {
                        setOpenMenu(false);
                        props.setNotificationsOpen(false);
                        props.findAndSetClient(noti.metadata?.clientId);
                      }}
                      style={{
                        borderBottom: "1px solid #e4e4e4",
                        padding: "1.25em 1.8em",
                        cursor: "pointer",
                      }}
                    >
                      <Row>
                        <Col
                          xs={11}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p style={{ margin: 0 }}>{noti.body}</p>
                        </Col>
                        <Col
                          xs={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeNotification(noti);
                            }}
                          >
                            &#10005;
                          </span>
                        </Col>
                      </Row>
                    </li>
                  </div>
                ))
              ) : (
                <li
                  style={{
                    borderBottom: "1px solid #e4e4e4",
                    padding: "1.25em 1.8em",
                  }}
                >
                  <Row>
                    <Col
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p style={{ margin: 0 }}>No notifications.</p>
                    </Col>
                  </Row>
                </li>
              )}
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
}

export default Header;
