import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useNavigate } from "react-router-dom";
import { validatePhone } from "../constants";
import ThemeContext from "../contexts/ThemeContext";
import InsightLogo from "../images/Insight/insight-logo.svg";
import Logo from "../images/scoreboard-logo.svg";

import { ToastContainer, toast } from "react-toastify";
import Button from "../components/Button";
import {
  doCreateUser
} from "../components/Firebase";

function Signup() {
  const [signupPlan, setSignupPlan] = useState<string>("");
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<number>();

  const { theme, setTheme } = useContext(ThemeContext);

  const navigate = useNavigate();

  useEffect(() => {
    theme === "resonate"
      ? (document.title = "Resonate - Create Account")
      : (document.title = "InspireInsight.com - Create Account");
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get("plan");
    if (plan != null) {
      localStorage.setItem("planOfIntent", plan);
      setSignupPlan(plan);
    }
  }, []);

  const handlePhoneChange = (e: any) => {
    const validatedPhoneNumber = validatePhone(
      e.target.value,
      true,
      () => {
        setPhoneError(false);
      },
      () => {
        setPhoneError(true);
      }
    );

    if (validatedPhoneNumber != null) {
      setPhoneNumber(validatedPhoneNumber);
    }
  };

  const handleSignup = (e: any) => {
    if (isTermsChecked) {
      e.preventDefault();
      let firstName = "";
      let lastName = "";
      let firm = "";
      let phone = phoneNumber;
      let email = "";
      let password = "";

      if (theme === "insight") {
        firstName = e.target[0].value;
        lastName = e.target[1].value;
        firm = "";
        phone = phoneNumber;
        email = e.target[3].value;
        password = e.target[4].value;
      } else {
        firstName = e.target[0].value;
        lastName = e.target[1].value;
        firm = e.target[2].value;
        phone = phoneNumber;
        email = e.target[4].value;
        password = e.target[5].value;
      }

      if (!phoneError && phoneNumber != null) {
        doCreateUser(
          email,
          password,
          firm,
          firstName,
          lastName,
          phone as number,
          theme,
          () => {
            navigate("/");
          }
        );
      } else {
        e.preventDefault();
        setPhoneError(true);
      }
    } else {
      e.preventDefault();
      toast.error("Please agree to the Terms of Service", { autoClose: 1000 });
    }
  };

  return (
    <>
      <div className="fullWidthContainer whiteBg">
        <ToastContainer position="bottom-center" hideProgressBar />
        <div className="fullWidthContent">
          <Row>
            <Col>
              <a href="#">
                <img
                  className="logoImg"
                  src={theme === "resonate" ? Logo : InsightLogo}
                />
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div id="mainContent" className="fullWidthContainer">
        <div className="fullWidthContent">
          {/* <Row>{auth.currentUser?.uid}</Row> */}

          <form onSubmit={handleSignup}>
            <Row>
              <Col mdOffset={3} md={6} xs={12}>
                <h2>Register</h2>
                <div className="inputContainer">
                  <label htmlFor="signup-firstName">First Name:</label>
                  <input id="signup-firstName" type="text" name="firstName" />
                </div>
                <div className="inputContainer">
                  <label htmlFor="signup-lastName">Last Name:</label>
                  <input id="signup-lastName" type="text" name="lastName" />
                </div>
                {theme === "resonate" && (
                  <div className="inputContainer">
                    <label htmlFor="signup-firm">Firm Name:</label>
                    <input id="signup-name" type="text" name="firm-name" />
                  </div>
                )}
                <div className="inputContainer">
                  <label htmlFor="signup-phone">Phone Number:</label>
                  <input
                    id="signup-phone"
                    type="tel"
                    className={phoneError ? "error" : ""}
                    placeholder="(123) 456-7890"
                    name="phone"
                    onChange={(e) => {
                      handlePhoneChange(e);
                    }}
                  />
                  {phoneError ? (
                    <p className="input-message error">
                      Mobile Number must be 10 digits and correctly formatted.
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="inputContainer">
                  <label htmlFor="signup-email">Email:</label>
                  <input id="signup-email" type="text" name="email" />
                </div>
                <div className="inputContainer">
                  <label htmlFor="signup-password">Password:</label>
                  <input id="signup-password" type="password" name="password" />
                </div>
                {theme === "resonate" && (
                  <div className="inputContainer radioContainer">
                    <h3>Select Your Plan:</h3>
                    <Row>
                      <Col
                        xs={12}
                        className={
                          signupPlan === "starter" || signupPlan === ""
                            ? "selected"
                            : ""
                        }
                        onClick={() => {
                          setSignupPlan("starter");
                          localStorage.setItem("planOfIntent", "starter");
                        }}
                      >
                        <div className="radioInputContainer">
                          <input
                            id="signup-starter"
                            type="radio"
                            value="starter"
                            name="accountType"
                            checked={
                              signupPlan === "starter" || signupPlan === ""
                            }
                          />
                        </div>
                        <label className="radioLabel" htmlFor="signup-starter">
                          Starter: $50/month, up to 5 clients
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        className={signupPlan === "essential" ? "selected" : ""}
                        onClick={() => {
                          setSignupPlan("essential");
                          localStorage.setItem("planOfIntent", "essential");
                        }}
                      >
                        <div className="radioInputContainer">
                          <input
                            id="signup-essential"
                            type="radio"
                            value="essential"
                            name="accountType"
                            checked={signupPlan === "essential"}
                          />
                        </div>
                        <label
                          className="radioLabel"
                          htmlFor="signup-essential"
                        >
                          Essential: $150/month, unlimited clients
                        </label>
                      </Col>
                    </Row>
                  </div>
                )}
                <div className="inputContainer radioContainer">
                  <h3>Terms of Service:</h3>
                  <Row>
                    <Col
                      xs={12}
                      className={isTermsChecked ? "selected" : ""}
                      onClick={() => {
                        setIsTermsChecked(!isTermsChecked);
                      }}
                    >
                      <div className="radioInputContainer">
                        <input
                          id="signup-starter"
                          type="checkbox"
                          value="starter"
                          name="accountType"
                          checked={isTermsChecked}
                        />
                      </div>
                      <label className="radioLabel" htmlFor="signup-starter">
                        {theme === "resonate" ? (
                          <>
                            I agree to Resonate's{" "}
                            <a
                              href="https://www.startresonating.com/terms"
                              target="new"
                            >
                              Terms of Service
                            </a>
                          </>
                        ) : (
                          <>
                            I agree to Insight's{" "}
                            <a
                              href="https://www.inspireinsight.com/terms-of-service"
                              target="new"
                            >
                              Terms of Service
                            </a>
                          </>
                        )}
                      </label>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col xs={12}>
                    <Button type="submit" fullWidth preset="formPrimary">
                      {theme === "resonate" ? "Subscribe" : "Register"}
                    </Button>
                  </Col>
                </Row>
                <Row style={{ textAlign: "center", paddingTop: 20 }}>
                  <Col xs={12} style={{ textAlign: "center" }}>
                    <span>
                      Already have an account? <a href="/login">Sign in</a>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}

export default Signup;
