import { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import Logo from "../images/scoreboard-logo.svg";
import InsightLogo from "../images/Insight/insight-logo.svg";
import LoadingIcon from "../images/dual-ring-loading-grey.svg";
import Button from "../components/Button";
import { ToastContainer } from "react-toastify";
import { themeState } from "../contexts/ThemeContext";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

function Migrate() {
  const [loading, setLoading] = useState(false);
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [passwordValid, setPasswordValid] = useState<boolean | null>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    process.env.REACT_APP_THEME === "resonate"
      ? (document.title = "Resonate - Login")
      : (document.title = "InspireInsight.com - Login");
  }, []);

  useEffect(() => {
    if (passwordOne === passwordTwo) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [passwordOne, passwordTwo]);

  const handleLogin = (e: any) => {
    if (passwordValid && passwordOne !== "") {
      setLoading(true);
      const newPassword = passwordTwo;
      // const oldEmail = Buffer.from(localStorage.get("email")).toString(
      //   "base64"
      // );
      // const authToken = Buffer.from(localStorage.get("legacyToken")).toString();

      // console.log(newPassword, oldEmail, authToken);
      e.preventDefault();
    } else {
      setPasswordValid(false);
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="fullWidthContainer whiteBg">
        <ToastContainer position="bottom-center" hideProgressBar />
        <div className="fullWidthContent">
          <Row>
            <Col>
              <a href="#">
                <img
                  src={themeState.theme === "resonate" ? Logo : InsightLogo}
                  className="logoImg"
                />
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div id="mainContent" className="fullWidthContainer">
        <div className="fullWidthContent">
          {/* <Row>{auth.currentUser?.uid}</Row> */}

          <form onSubmit={handleLogin}>
            <Row>
              <Col mdOffset={3} md={6} xs={12}>
                <h2>Update Your Insight Password</h2>
                <p style={{ fontSize: 20 }}>
                  Thank you for being a valued subscriber of Inspire Insight. As
                  part of our recent upgrades, we are asking all legacy users to
                  update their password. Please enter your new password below.
                  Your email address will stay the same.
                </p>

                {loading ? (
                  <ul
                    className="multi-input-message"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={LoadingIcon}
                      style={{
                        width: 40,
                      }}
                    />
                    <span style={{ fontWeight: "bold" }}>
                      Updating Account Details
                    </span>
                  </ul>
                ) : (
                  <>
                    <div className="inputContainer">
                      <label htmlFor="login-password">Password:</label>
                      <input
                        id="login-password"
                        type="password"
                        name="password"
                        onChange={(e) => {
                          setPasswordOne(e.target.value);
                        }}
                      />
                    </div>
                    <div className="inputContainer">
                      <label htmlFor="login-password-confirm">
                        Confirm Password:
                      </label>
                      <input
                        id="login-password-confirm"
                        type="password"
                        name="password"
                        className={!passwordValid ? "error" : ""}
                        onChange={(e) => {
                          setPasswordTwo(e.target.value);
                        }}
                      />
                      {!passwordValid ? (
                        <p className="input-message error">
                          Passwords must match.
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Row>
                      <Col xs={12} sm={6} smOffset={3}>
                        <Button type="submit" fullWidth preset="formPrimary">
                          Continue
                        </Button>
                      </Col>
                      {/* <Col xs={12} sm={6}>
                    <Button
                      onClick={() => {
                        navigate("/register");
                      }}
                      fullWidth
                    >
                      Register
                    </Button>
                  </Col> */}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}

export default Migrate;
