import React from "react";
import LoadingIcon from "../../images/dual-ring-loading-grey.svg";
import LoadingIconRed from "../../images/dual-ring-loading-red.svg";
import { Row, Col } from "react-flexbox-grid";

interface LoaderProps {
  mini?: boolean;
  inline?: boolean;
  red?: boolean;
}

function Loader(props: LoaderProps) {
  return props.inline ? (
    <div style={{ display: "flex", justifyContent: "center", marginLeft: -5 }}>
      <img
        style={{
          width: props.mini ? 40 : 70,
        }}
        src={props.red ? LoadingIconRed : LoadingIcon}
      />
    </div>
  ) : (
    <Row>
      <Col
        xs={12}
        style={{ display: "flex", justifyContent: "center", margin: "50px 0" }}
      >
        <img
          style={{ width: props.mini ? 40 : 70 }}
          src={props.red ? LoadingIconRed : LoadingIcon}
        />
      </Col>
    </Row>
  );
}

export default Loader;
