import { Row, Col } from "react-flexbox-grid";
import { useEffect, useLayoutEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Close } from "@styled-icons/material/Close";
import { isMobile } from "react-device-detect";

export interface ModalProps {
  isActive: boolean;
  removeClose?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  modalTitle?: string;
  fullWidth?: boolean;
}

export default function Modal(props: ModalProps) {
  useEffect(() => {
    if (props.isActive) {
      document.body.classList.add("modalActive");
    }
    isMobile && window.scrollTo(0, 0);
  });

  useLayoutEffect(() => {
    document.body.classList.remove("modalActive");
  });

  return (
    <>
      <AnimatePresence>
        {props.isActive && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
            style={{
              width: "100%",
              zIndex: 900,
              top: 0,
              left: 0,
            }}
            className={
              props.fullWidth
                ? "fullWidth modalOuterContainer"
                : "modalOuterContainer"
            }
            onClick={() => {
              !props.removeClose && props.onClose && props.onClose();
            }}
          >
            <div
              style={{
                opacity: 1,
                zIndex: 901,
                borderRadius: 2,
              }}
              className="modalContainer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!props.removeClose && (
                <Close
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                  }}
                  className="modalCloseButton"
                  onClick={() => {
                    props.onClose && props.onClose();
                  }}
                  size="30"
                />
              )}
              {props.modalTitle && (
                <>
                  <Row>
                    <Col xs={12}>
                      <h2>{props.modalTitle}</h2>
                    </Col>
                  </Row>
                </>
              )}
              <div className="modalContentContainer">{props.children}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
