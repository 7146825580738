import { useState } from "react";
import {
  IEsgCriterionCount,
  IEsgCriterionRollup,
  IInstrumentDetails,
} from "../../models/Insight/api";
import { IDetailsAPI } from "../Portfolios";
import { CHRISTIAN } from "../../constants";
import { Row, Col } from "react-flexbox-grid";
import { Plus as PlusSmall } from "@styled-icons/fa-solid/Plus";
import { Minus } from "@styled-icons/fa-solid/Minus";
import axios from "axios";
import ViewIssues from "../Resonate/Client/ViewIssues";
import { insightHostUrl } from "../Firebase";

interface CompanyIssuesProps {
  details: IInstrumentDetails;
  id: number;
}

export const checkPolarity = (
  issue: IEsgCriterionCount | IEsgCriterionRollup
): "positive" | "negative" | "neutral" => {
  const score = (CHRISTIAN as any)[issue.criterion.name];
  if (score > 0) {
    return "positive";
  } else if (score < 0) {
    return "negative";
  } else {
    return "neutral";
  }
};

export default function CompanyIssues({ details, id }: CompanyIssuesProps) {
  const [esgIssues, setEsgIssues] = useState<any[]>([]);
  const [activeEsgIssue, setActiveEsgIssue] = useState<string>();

  const handleIssues = (financialId: number, category: string) => {
    let formatCategory = category.toUpperCase();

    axios
      .get(
        `${insightHostUrl}/api/tickers/${financialId}/esg-issues/scoreboard/${formatCategory}?page=0&&size=50`
      )
      .then((response) => {
        console.log(response.data);
        setActiveEsgIssue(category);
        setEsgIssues(response.data);
      });
  };

  return (
    <>
      {details.esgIssueCriteriaCount &&
        details.esgIssueCriteriaCount
          .sort((a: IEsgCriterionCount, b: IEsgCriterionCount) => {
            const scoreA = (CHRISTIAN as any)[a.criterion.name];
            const scoreB = (CHRISTIAN as any)[b.criterion.name];
            return scoreA - scoreB;
          })
          .map((issue: IEsgCriterionCount, index) => (
            <div className="issueRow">
              <Row
                onClick={() => {
                  if (activeEsgIssue === issue.criterion.name) {
                    setActiveEsgIssue("");
                    setEsgIssues([]);
                  } else {
                    handleIssues(id, issue.criterion.name);
                  }
                }}
              >
                <Col>
                  <div className="pointScore">
                    <i
                      className={
                        checkPolarity(issue) === "negative"
                          ? "pointNegative"
                          : "pointPositive"
                      }
                    >
                      {checkPolarity(issue) === "negative" ? (
                        <Minus size="10" />
                      ) : (
                        <PlusSmall size="10" />
                      )}
                    </i>
                    <span className="issueCategoryClick">
                      {issue.criterion.title}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {activeEsgIssue === issue.criterion.name && (
                    <ViewIssues issues={esgIssues} ticker={details.ticker} />
                  )}
                </Col>
              </Row>
            </div>
          ))}
    </>
  );
}
