import React from "react";

export const themeState = {
  theme: process.env.REACT_APP_THEME ?? "resonate",
  setTheme: (theme: string) => {},
};

const ThemeContext = React.createContext(themeState);

export default ThemeContext;
