import { initializeApp } from "@firebase/app";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import axios from "axios";
import { getAnalytics } from "firebase/analytics";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { objToDate } from "../../constants";
import { themeState } from "../../contexts/ThemeContext";
import {
  FirestoreUser,
  IClient,
  INotification,
  IPlaybook,
  IReport,
  IUser,
  Model,
  Portfolio,
} from "../../models";
import { PortfolioTemplate } from "../Portfolios";

const firebaseConfig = {
  apiKey: "AIzaSyCarbSqQeueFh5lcAXMF3T1mDllexpVgu8",
  authDomain: "scoreboard-87a49.firebaseapp.com",
  projectId: "scoreboard-87a49",
  storageBucket: "scoreboard-87a49.appspot.com",
  messagingSenderId: "330427602401",
  appId: "1:330427602401:web:d981aae3ba47fcf54e6ea0",
  measurementId: "G-38ZHKXSNLR",
};

let hostUrl = "https://resonate-production-api.herokuapp.com";
let insightHostUrl = "https://api.inspireinsight.com";

switch (process.env.REACT_APP_HOST_ENV) {
  case "local":
    insightHostUrl = "https://api.inspireinsight.com";
    hostUrl = "http://localhost:8080/resonate";
    break;
  case "qa":
    insightHostUrl = "https://qaapi.inspireinsight.com";
    hostUrl = "https://api.inspireinsight.patmos.dev/resonate";
    break;
  case "institutional":
    insightHostUrl = "https://institutionalapi.inspireinsight.com";
    hostUrl = "https://institutional-scorebuilder-api.herokuapp.com";
    break;
  default:
    insightHostUrl = "https://api.inspireinsight.com";
    hostUrl = "https://api.inspireinsightprod.patmos.dev/resonate";
}

export { hostUrl, insightHostUrl };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

/* Authentication API */
export const doCreateUserWithEmailAndPassword = (
  email: string,
  password: string
) => createUserWithEmailAndPassword(auth, email, password);

export const doSignInWithEmailAndPassword = (
  email: string,
  password: string,
  navigate?: (path: string) => void
) => {
  // if (themeState.theme === "insight") {
  //   if (emails.includes(email)) {
  //     return axios
  //       .post("https://api.inspireinsight.com/api/authenticate", {
  //         username: email,
  //         password: password,
  //       })
  //       .then((response) => {
  //         navigate && navigate("/migrate");
  //       })
  //       .catch((e) => console.log(e));
  //   }
  // }
  return signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      if (themeState.theme === "insight") {
        navigate && navigate("/dashboard");
      } else {
        navigate && navigate("/");
      }
    })
    .catch((e) => {
      console.log(e.code);
      switch (e.code) {
        case "auth/wrong-password":
          toast.error("Wrong password", { autoClose: 1000 });
          break;
        case "auth/invalid-email":
          toast.error("Invalid email" , { autoClose: 1000 });
          break;
        case "auth/user-not-found":
          toast.error("User with provided address not found", { autoClose: 1000 });
          break;
      }
    });
};

export const doSignOut = () => {
  console.log("signout");
  auth.signOut();
};

export const doPasswordReset = (email: string) =>
  sendPasswordResetEmail(auth, email);

export const doCreateUser = async (
  email: string,
  password: string,
  firm: string,
  firstName: string,
  lastName: string,
  phone: number,
  platform: string,
  successCb: () => void
) => {
  await createUserWithEmailAndPassword(auth, email, password)
    .then((user) => {
      setDoc(doc(db, "users", user.user.uid), {
        clients: [],
        notifications: [],
        endPageDisclaimer: "",
        footerDisclaimer: "",
        email: email,
        firm: firm,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        platform: [platform],
      });
      successCb();
    })
    .catch((e) => {
      console.log(e);
      switch (e.code) {
        case "auth/invalid-email":
          toast.error("Error, invalid email address", { autoClose: 1000 });
          break;
        case "auth/weak-password":
          toast.error("Error, password too weak", { autoClose: 1000 });
          break;
        case "auth/email-already-in-use":
          toast.error("Error, email is already in use", { autoClose: 1000 });
          break;
        default:
          toast.error("An error has occurred", { autoClose: 1000 });
      }
    });
};

/* Firestore API */

export async function getCustomClaimRole() {
  if (auth.currentUser) {
    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    const role = decodedToken.claims.stripeRole;
    if (role) {
      return role;
    } else {
      let planOfIntent = localStorage.getItem("planOfIntent");
      if (planOfIntent === "essential" || planOfIntent == "starter") {
        doCreateCheckout(planOfIntent);
      } else {
        doCreateCheckout("starter");
      }
    }
  } else {
    return null;
  }
}

const addHours = (date: Date, hours: number) => {
  date.setHours(date.getHours() + hours);
  return date;
};

export const doFetchUser = async (
  firebaseRet?: boolean
): Promise<IUser | null> => {
  const user = auth.currentUser;
  if (user) {
    const userReference = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userReference);
    let userData: any = userSnapshot.data();
    const role = await getCustomClaimRole();
    return firebaseRet ? userData : { ...user, ...userData, role: role };
  } else {
    return null;
  }
};

export const doFetchReports = async (uid: string) => {
  const snapshot = await getDocs(collection(db, `users/${uid}/reports`));
  return snapshot.docs.map(
    (doc) =>
      ({
        ...(doc.data() as IReport),
        completedDate: objToDate(doc.data().completedDate),
      } as IReport)
  ) as Array<IReport>;
};

export const doUpdateUser = async (newUser: IUser, theme?: string) => {
  let userData: FirestoreUser = {
    clients: newUser.clients,
    firm: newUser.firm,
    email: newUser.email,
    firstName: newUser.firstName,
    lastName: newUser.lastName,
    endPageDisclaimer: newUser.endPageDisclaimer,
    footerDisclaimer: newUser.footerDisclaimer,
    notifications: newUser.notifications,
    models: newUser.models,
    phone: newUser.phone === undefined ? 0 : newUser.phone,
    platform: newUser.platform ? newUser.platform : [],
  };
  // userData["clients"] = newUser.clients;
  // userData["firm"] = newUser.firm;
  // userData["email"] = newUser.email;
  // userData["firstName"] = newUser.firstName;
  // userData["lastName"] = newUser.lastName;
  // userData["endPageDisclaimer"] = newUser.endPageDisclaimer;
  // userData["footerDisclaimer"] = newUser.footerDisclaimer;
  // userData["notifications"] = newUser.notifications;
  // userData["models"] = newUser.models;
  // userData["phone"] = newUser.phone;

  userData["models"] !== undefined && userData["models"]?.length > 0
    ? (userData["models"] = newUser.models)
    : (userData["models"] = []);

  if (theme) {
    let updatedPlatform = userData.platform;
    if (!updatedPlatform?.includes(theme)) updatedPlatform?.push(theme);
    userData.platform = updatedPlatform;
  }

  const user = auth.currentUser;
  if (user) {
    const userReference = doc(db, "users", user.uid);
    await setDoc(userReference, userData);
    auth.currentUser && (await updateProfile(auth.currentUser, newUser));
  }
};

export const doCreateClient = async (client: IClient, theme: string) => {
  await doFetchUser(true)
    .then((user: IUser | null) => {
      if (user) {
        let updatedPlatform = client.platform ? client.platform : [];
        if (!updatedPlatform.includes(theme)) updatedPlatform.push(theme);
        client.platform = updatedPlatform;

        user["clients"] = user.clients.concat(client);
        doUpdateUser(user, theme);
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const doDeleteClient = async (client: IClient, cb?: () => void) => {
  await doFetchUser(true)
    .then((user: IUser | null) => {
      if (user) {
        user["clients"] = user.clients.filter((c) => c.id != client.id);
        doUpdateUser(user).then(() => {
          toast.success("Client deleted", {autoClose: 1000 });
          cb && cb();
        });
      }
    })
    .catch((err) => {
      toast.warning("Client could not be deleted");
      throw err;
    });
};

export const doUpdateClient = async (
  client: IClient,
  template?: PortfolioTemplate,
  callback?: () => void
) => {
  await doFetchUser(true)
    .then((user: IUser | null) => {
      if (user) {
        let userData = user;

        let currentClient = user.clients.find((c) => c.id === client.id);
        if (currentClient) {
          userData["clients"] = user.clients.filter((c) => c.id !== client.id);
          userData["clients"].push(client);

          doUpdateUser(userData).then(() => {
            callback && callback();
          });
        }
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const removeNotification = async (
  notification: INotification,
  callback?: () => void
) => {
  await doFetchUser(true).then((user: IUser | null) => {
    if (user) {
      let userData = user;
      userData["notifications"] = userData["notifications"].filter(
        (noti: INotification) => noti.body != notification.body
      );
      doUpdateUser(userData).then(() => {
        callback && callback();
      });
    }
  });
};

export const doCreateOrUpdateModel = async (model: Model) => {
  await doFetchUser(true)
    .then((user: IUser | null) => {
      if (user) {
        let userData = user;
        if (!userData.models) {
          userData.models = [];
        }
        let currentModel = userData.models.find((m) => m.id === model.id);
        if (currentModel) {
          userData["models"] = userData.models.filter((m) => m.id !== model.id);
          userData["models"].push(model);
        } else {
          userData["models"] = userData.models.concat(model);
        }
        doUpdateUser(userData);
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const doCreatePortfolio = async (
  client: IClient,
  portfolio: Portfolio,
  template: string
) => {
  let currentClient = client;
  if (template === "portfolio") {
    currentClient["portfolios"].push(portfolio);
  } else {
    if (!Object.keys(currentClient).includes("proposals")) {
      currentClient["proposals"] = [portfolio];
    } else {
      currentClient["proposals"] && currentClient["proposals"].push(portfolio);
    }
  }

  await doUpdateClient(currentClient, template as PortfolioTemplate);
};

export const doDeletePortfolio = async (
  client: IClient,
  portfolio: Portfolio,
  template: string,
  user: IUser
) => {
  if (template === "model" && user.models) {
    let updatedUser = user;
    updatedUser["models"] = updatedUser["models"]?.filter(
      (p) => p.id !== portfolio.id
    );
    doUpdateUser(updatedUser);
  } else {
    let currentClient = client;
    if (template === "portfolio") {
      currentClient["portfolios"] = currentClient["portfolios"].filter(
        (p) => p.id !== portfolio.id
      );
    } else if (currentClient["proposals"]) {
      currentClient["proposals"] = currentClient["proposals"].filter(
        (p) => p.id !== portfolio.id
      );
    }
    await doUpdateClient(currentClient, template as PortfolioTemplate);
  }
};

export const doUpdatePortfolio = async (
  client: IClient,
  portfolio: Portfolio,
  template: string
) => {
  let currentClient = client;

  if (template === "portfolio") {
    currentClient["portfolios"] = currentClient["portfolios"].filter(
      (p) => p.id !== portfolio.id
    );
    currentClient["portfolios"].push(portfolio);
  } else if (currentClient["proposals"]) {
    currentClient["proposals"] = currentClient["proposals"].filter(
      (p) => p.id !== portfolio.id
    );
    currentClient["proposals"].push(portfolio);
  }

  await doUpdateClient(currentClient, template as PortfolioTemplate);
};

export const doFetchHoldings = async (
  client: IClient,
  portfolio: Portfolio,
  playbook: IPlaybook | null,
  cb?: (data: any) => void
) => {
  let requestData: any = {
    holdings: portfolio.holdings,
    cash: portfolio.cash,
  };

  if (playbook) {
    requestData["issues"] = Object.keys(playbook.positions)
      .filter((key) => playbook.positions[key] !== 0)
      .reduce((newObj: { [key: string]: number }, key) => {
        newObj[key] = playbook.positions[key];
        return newObj;
      }, {});
  } else {
    requestData["issues"] = {};
  }

  await axios
    .post(`${hostUrl}/portfolios`, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      cb && cb(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const doGenerateReport = async (
  client: IClient,
  portfolio: Portfolio,
  oldPlaybook: IPlaybook | null,
  disclaimerToast: any,
  requestId: string,
  template: PortfolioTemplate,
  cb?: (data: any) => void
) => {
  doFetchUser().then((user: IUser | null) => {
    // fetches fresh playbook from server to override local copy
    let newPlaybook = user?.clients
      .find((c) => c.id === client.id)
      ?.playbooks.find((p) => p.id === oldPlaybook?.id);

    if (user && newPlaybook) {
      let requestData = {
        issues: Object.keys(newPlaybook.positions)
          .filter((key) => newPlaybook?.positions[key] !== 0)
          .reduce((newObj: { [key: string]: number }, key) => {
            newObj[key] = newPlaybook?.positions[key] as number;
            return newObj;
          }, {}),
        holdings: portfolio.holdings,
        cash: portfolio.cash,
        household: `${template.charAt(0).toUpperCase() + template.slice(1)}: ${
          portfolio.name
        }`,
        endPageDisclaimer: user.endPageDisclaimer,
        footerDisclaimer: user.footerDisclaimer,
        clientName: client.name,
        requestId: requestId,
        userId: user.uid,
      };

      if (!requestData["endPageDisclaimer"]) {
        toast.warning(disclaimerToast);
      }
      axios.post(`${hostUrl}/report`, requestData, {
        // responseType: "blob", // important
      });

      //   // toast.promise(
      //   //       .then((response) => {
      //   //         const url = window.URL.createObjectURL(new Blob([response.data]));
      //   //         const link = document.createElement("a");
      //   //         link.href = url;
      //   //         link.setAttribute(
      //   //           "download",
      //   //           `ResonateReport-${client.name}-${new Date()
      //   //             .toLocaleDateString()
      //   //             .replace("_", "-")}.pdf`
      //   //         ); //or any other extension
      //   //         document.body.appendChild(link);
      //   //         link.click();
      //   //       }),
      //   //     {
      //   //       pending: "Generating report",
      //   //       success: "Report generated",
      //   //       error: "Failed to generate report",
      //   //     }
      //   //   );
    }
  });
};

export const doGenerateClientReport = async (
  client: IClient,
  oldPlaybook: IPlaybook | null,
  requestId: string,
  cb?: (data: any) => void
) => {
  doFetchUser().then((user: IUser | null) => {
    // fetches fresh playbook from server to override local copy
    let newPlaybook = user?.clients
      .find((c) => c.id === client.id)
      ?.playbooks.find((p) => p.id === oldPlaybook?.id);

    if (user && newPlaybook) {
      let requestData = {
        portfolios: client.portfolios,
        endPageDisclaimer: user.endPageDisclaimer,
        household: "Household",
        footerDisclaimer: user.footerDisclaimer,
        clientName: client.name,
        issues: Object.keys(newPlaybook.positions)
          .filter((key) => newPlaybook?.positions[key] !== 0)
          .reduce((newObj: { [key: string]: number }, key) => {
            newObj[key] = newPlaybook?.positions[key] as number;
            return newObj;
          }, {}),
        requestId: requestId,
        userId: user.uid,
      };

      axios.post(`${hostUrl}/client-report`, requestData, {
        // responseType: "blob", // important
      });
    }
  });
};

export const doDeleteHolding = async (
  client: IClient,
  portfolio: Portfolio | Model,
  holdingId: number,
  template: string,
  cb?: (portfolio: Portfolio) => void
) => {
  let currentClient = client;
  let currentPortfolio = portfolio;

  let updatedHoldings = currentPortfolio.holdings.filter(
    (h) => h.instrumentId !== holdingId
  );
  currentPortfolio.holdings = updatedHoldings;

  if (template === PortfolioTemplate.MODEL) {
    doCreateOrUpdateModel(currentPortfolio as Model);
  } else {
    currentClient["portfolios"] = currentClient["portfolios"].filter(
      (p) => p.id !== currentPortfolio.id
    );
    currentClient["portfolios"].push(currentPortfolio);
  }

  await doUpdateClient(currentClient, template as PortfolioTemplate).then(
    () => {
      cb && cb(portfolio);
    }
  );
};

/* STRIPE API */

const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

export const doCreateCheckout = async (plan: "essential" | "starter") => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    let price;
    if (plan == "essential") {
      price = "price_1Lwp0wKLlbHe0ZIcqRBkQql6";
    } else {
      price = "price_1LuesXKLlbHe0ZIc1Uxz9gbJ";
    }

    const docRef = await addDoc(
      collection(db, `customers/${currentUser.uid}/checkout_sessions`),
      {
        price: price,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
        payment_method_collection: "if_required",
      }
    );

    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }
};

export const doLaunchCustomerPortal = async () => {
  const createCustomerPortalUrl = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );
  const { data } = await createCustomerPortalUrl({
    returnUrl: window.location.origin,
    locale: "auto", // Optional, defaults to "auto"
  });
  window.location.assign((data as any).url);
};

export const doFetchClientPlaybook = async (liveLinkId: string) => {
  const docRef = doc(db, "livelinks", liveLinkId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};

// export const doSubmitFileUploadInit = async (file) => {

// }
