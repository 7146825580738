import Button from "./Button";
import Modal from "./Modal";
import { Row, Col } from "react-flexbox-grid";

interface ConfirmProps {
  isActive: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onCancel?: () => void;
  cancelText?: string;
  confirmText?: string;
  message?: string;
}

export default function Confirm(props: ConfirmProps) {
  const {
    isActive,
    onClose,
    onConfirm,
    onCancel,
    message,
    cancelText,
    confirmText,
  } = props;
  return (
    <Modal isActive={isActive} onClose={onClose}>
      <Row style={{ padding: 20, paddingTop: 30 }}>
        <h2>{message ?? "Are you sure you want to perform this action?"}</h2>
      </Row>
      <Row>
        <Col xs={6}>
          <Button onClick={onCancel ? onCancel : onClose} fullWidth>
            {cancelText ?? "Cancel"}
          </Button>
        </Col>
        <Col xs={6}>
          <Button onClick={onConfirm} fullWidth preset="formPrimary">
            {confirmText ?? "Confirm"}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
