import { useEffect } from "react";

interface OnLoadRedirectProps {
  url: string;
}

export default function OnLoadRedirect({ url }: OnLoadRedirectProps) {
  useEffect(() => {
    window.location.href = url;
  }, []);

  return <></>;
}
