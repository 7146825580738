import { AlertCircleOutline } from "@styled-icons/evaicons-outline/AlertCircleOutline";
import { DocumentOnePage } from "@styled-icons/fluentui-system-regular/DocumentOnePage";
import Axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { css, hover } from "glamor";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useReactToPrint } from "react-to-print";
import { PlaybookPosition } from "../../models/Resonate/playbook";
import { ClientView } from "../../pages/Resonate/Home";
import { doCreatePortfolio, hostUrl } from "../Firebase";
import ImpactScore from "../Insight/ImpactScore";
import { Playbook } from "./Playbook";

import { DownArrow } from "@styled-icons/boxicons-solid/DownArrow";
import { UpArrow } from "@styled-icons/boxicons-solid/UpArrow";
import { Close } from "@styled-icons/material/Close";
import { useNavigate } from "react-router";
import LoadingIconGrey from "../../images/dual-ring-loading-grey.svg";
import LoadingIcon from "../../images/dual-ring-loading.svg";
import { IPortfolioAPIResponse } from "../../models/Resonate/api";
import DollarInput from "../DollarInput";

import { themeState } from "../../contexts/ThemeContext";

import { Minus } from "@styled-icons/fa-solid/Minus";
import { Plus as PlusSmall } from "@styled-icons/fa-solid/Plus";
import { toast } from "react-toastify";
import Elipsis from "../../images/ellipsis-stroke-light.svg";
import {
  IClient,
  IHolding,
  IPlaybook,
  IUser,
  Model,
  Portfolio,
} from "../../models";
import Confirm from "../Confirm";
import ContextMenu from "../ContextMenu";
import {
  doCreateOrUpdateModel,
  doDeleteHolding,
  doDeletePortfolio,
  doFetchHoldings,
  doGenerateReport,
  doUpdatePortfolio,
} from "../Firebase";
import { CreatePortfolioModal } from "../Resonate/Home/CreatePortfolioModal";
import { UploadPortfolioModal } from "../Resonate/Home/UploadPortfolioModal";

import { capitalizeFirstLetter } from "../../constants";
import Button from "../Button";
import CreateModal from "../Resonate/Models/CreateModal";
import Cash from "./Cash";
import Issue from "./Issue";
import ReportGive50 from "./ReportGive50";
import ReportHeader from "./ReportHeader";
import TickerSearch from "./TickerSearch";

export enum PortfolioTemplate {
  PORTFOLIO = "portfolio",
  MODEL = "model",
  FUND = "fund",
  PROPOSAL = "proposal",
}

interface PortfoliosProps {
  template: PortfolioTemplate;
  view: ClientView;
  client: IClient;
  setActiveClient: (client: IClient) => void;
  user: IUser;
  refreshUser: () => void;
  onClick: (modalVisible: boolean) => void;
  createPortfolioModalOpen: boolean;
  setCreatePortfolioModalOpen: (state: boolean) => void;
  uploadPortfolioModalOpen?: boolean;
  setUploadPortfolioModalOpen?: (state: boolean) => void;
  setClientScore: (score: number) => void;
  setClientRatio: (ratio: number) => void;
  setPlaybookVisible: (arg0: boolean) => void;
  handlePlaybookSave: (
    playbookMenuTitle: string,
    isClientCreatedPlaybook: boolean
  ) => void;
  setPortfoliosReady: (arg0: boolean) => void;
  portfoliosReady: boolean;
  resetPlaybook: () => void;
  playbookVisible: any;
  updatePlaybook: (
    category: string,
    value: PlaybookPosition,
    playbookMenuTitle: string,
    clientCreatedProfileId?: string,
    prebuiltProfile?: {}
  ) => void;
  setEditUserModalVisible: (arg0: boolean) => void;
  displayRatio?: (ratio: number) => string;
  activePlaybook: IPlaybook | null;
  setActivePlaybook: (playbook: IPlaybook) => void;

  toastIds: Array<any>;
  setToastIds: (toastIds: Array<any>) => void;
  sortMethod?: string;
  handleClientSelection: (arg0: IClient, arg1?: string) => void;
  models?: Array<Model>;
  query?: string;
  urlClientId?: string;
  urlPortfolioId?: string;
}

export const roundAlignment = (alignment?: number) => {
  if (alignment) {
    alignment = alignment * 100;
    if (alignment > 99 && alignment < 100) {
      return "99%";
    } else {
      return alignment.toFixed(0).toString() + "%";
    }
  } else {
    return "Not Aligned";
  }
};

export interface IDetailsAPI {
  date: string;
  source: string;
  detail: string;
  title: string;
  url: string;
}

export default function Portfolios(props: PortfoliosProps) {
  const [tickerQuery, setTickerQuery] = useState("");
  const [portfolioIsSearching, setPortfolioIsSearching] = useState("");
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [activePortfolio, setActivePortfolio] = useState<Portfolio | null>(
    null
  );

  const [uploadPortfolioOptionsModalOpen, setUploadPortfolioOptionsModalOpen] =
    useState<boolean>(false);

  const [inputLoadingId, setInputLoadingId] = useState<number | null>();

  const [portfoliosOpen, setPortfoliosOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const [esgIssues, setEsgIssues] = useState<Array<IDetailsAPI> | []>([]);
  const [query, setQuery] = useState<boolean>(false);

  const [activeIssueTicker, setActiveIssueTicker] = useState<
    | {
        id: string;
        companyName: string;
        portfolioId: string;
        category: string;
      }
    | any
  >({});

  const navigate = useNavigate();
  const portfolioRef = useRef<any>(null);

  //create portfolio/proposal
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalTemplate, setModalTemplate] = useState<PortfolioTemplate>(
    PortfolioTemplate.PORTFOLIO
  );
  const [activeModel, setActiveModel] = useState<Portfolio>();

  const [holdings, setHoldings] = useState<{
    [key: string]: IPortfolioAPIResponse;
  }>({});

  // this is for sorting holdings at the portfolio holding level
  const [sortKey, setSortKey] = useState<any>(
    props.template === PortfolioTemplate.FUND ? "name" : "positionSize"
  );
  const [sortDirection, setSortDirection] = useState<string>("desc");

  // this is for sorting portfolios themselves
  const [portfoliosSortKey, setPortfoliosSortKey] = useState<any>("name");
  const [portfoliosSortDirection, setPortfoliosSortDirection] =
    useState<string>("asc");

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (
      props.template == PortfolioTemplate.PROPOSAL ||
      props.template === "model"
    ) {
      const proposalIds = props.client.portfolios.map((p) => p.id);
      if (
        !proposalIds.every((id) => Object.keys(holdings).includes(id)) &&
        !query
      ) {
        setQuery(true);
        refreshAllHoldings();
      }
    }
  });

  useEffect(() => {
    handlePortfoliosOpen();
    if (!Object.keys(holdings).length) {
      refreshAllHoldings();
    }

    props.template === PortfolioTemplate.FUND &&
      setPortfoliosOpen({
        ...portfoliosOpen,
        [props.client.portfolios[0].id]: true,
      });

    props.setActiveClient(props.client);

    if (props.urlPortfolioId) {
      handlePortfoliosOpen(props.urlPortfolioId, false);
      setTimeout(
        () => portfolioRef.current.scrollIntoView({ behavior: "smooth" }),
        1000
      );
    }

    if (props.view === ClientView.CLIENT_INSIGHT_REPORT) {
      let thePortfolio = props.client.portfolios.find(
        (p) => p.id === props.urlPortfolioId
      );

      setActivePortfolio(thePortfolio as Portfolio);
    }
  }, []);

  useEffect(() => {
    if (props.view === ClientView.CLIENT_MODELS) createPortfolioFromModel();
  }, [activeModel]);

  const handlePortfoliosOpen = (
    portfolioId?: string,
    portfolioIsOpen?: boolean
  ) => {
    if (portfolioId) {
      setPortfoliosOpen({ ...portfoliosOpen, [portfolioId]: !portfolioIsOpen });
    } else {
      let newSetPortfoliosOpen = portfoliosOpen;
      props.client.portfolios.forEach((portfolio) => {
        let p = { [portfolio.id]: false };
        newSetPortfoliosOpen = Object.assign(newSetPortfoliosOpen, p);
      });

      setPortfoliosOpen(newSetPortfoliosOpen);
    }
  };

  const handleSort = (key: string, env?: string) => {
    /**
     * This function is used for sorting portfolios and holdings. When env is passed in, it is used for sorting portfolios. Otherwise, it is used for sorting holdings.
     */
    let activeSortKey;
    let activeSortDirection;

    if (!env) {
      activeSortKey = sortKey;
      activeSortDirection = sortDirection;
    } else {
      activeSortKey = portfoliosSortKey;
      activeSortDirection = portfoliosSortDirection;
    }

    if (key === activeSortKey) {
      if (env) {
        setPortfoliosSortDirection(
          activeSortDirection === "asc" ? "dsc" : "asc"
        );
      } else {
        setSortDirection(activeSortDirection === "asc" ? "dsc" : "asc");
      }
    } else {
      if (env) {
        setPortfoliosSortKey("asc");
      } else {
        setSortDirection("asc");
      }
    }
    if (env) {
      setPortfoliosSortKey(key);
    } else {
      setSortKey(key);
    }
  };

  const DynamicArrow = (key: string, env?: string) => {
    /*
    This function is used for rendering an arrow that corresponds with the sorting direction. When env is passed in, it is used for sorting portfolios. Otherwise, it is used for sorting holdings.
    */
    let activeSortKey;
    let activeSortDirection;

    if (!env) {
      activeSortKey = sortKey;
      activeSortDirection = sortDirection;
    } else {
      activeSortKey = portfoliosSortKey;
      activeSortDirection = portfoliosSortDirection;
    }

    if (key === activeSortKey) {
      if (activeSortDirection === "asc") {
        return (
          <UpArrow
            style={{
              height: 11,
              position: "relative",
              bottom: 1,
              left: 2,
              color: themeState.theme === "resonate" ? "teal" : "#666",
            }}
          />
        );
      } else {
        return (
          <DownArrow
            style={{
              height: 11,
              position: "relative",
              bottom: 2,
              left: 2,
              color: themeState.theme === "resonate" ? "teal" : "#666",
            }}
          />
        );
      }
    } else {
      return <></>;
    }
  };

  const handleIssues = (
    financialId: number,
    category: string,
    companyName: string,
    portfolioId: string
  ) => {
    let formatCategory = category.toUpperCase();

    const updateTickerandIssues = () => {
      setEsgIssues([]);
      setActiveIssueTicker({
        id: financialId,
        companyName: companyName,
        portfolioId: portfolioId,
        category: formatCategory,
      });
      Axios.get(
        `${hostUrl}/issues/${financialId}/${formatCategory}`
      ).then((response) => {
        setEsgIssues(response.data);
      });
    };
    if (
      activeIssueTicker.id === financialId &&
      activeIssueTicker.category === category &&
      activeIssueTicker.portfolioId === portfolioId
    ) {
      setActiveIssueTicker({});
    } else if (
      activeIssueTicker.portfolioId === portfolioId &&
      activeIssueTicker.category !== category
    ) {
      setEsgIssues([]);
      updateTickerandIssues();
    } else {
      updateTickerandIssues();
    }
  };

  const refreshScoresAndRatios = () => {
    if (themeState.theme === "resonate") {
      let scores = Object.values(holdings).map(
        (holding: IPortfolioAPIResponse) => holding.alignment
      );
      let ratios = Object.values(holdings).map(
        (holding: IPortfolioAPIResponse) => holding.resonateScore
      );

      props.setClientScore(scores.reduce((a, b) => a + b, 0) / scores.length); // portfolio alignment
      props.setClientRatio(ratios.reduce((a, b) => a + b, 0) / ratios.length); // portfolio totalPositive / totalNegative multiply by -1)
    } else {
      // add up all ImpactScores and divide by number of portfolios
      let scores = Object.values(holdings).map(
        (holding: IPortfolioAPIResponse) => holding.impactScore
      );

      props.setClientScore(scores.reduce((a, b) => a + b, 0) / scores.length);
    }
  };

  const refreshHoldings = (portfolio: Portfolio) => {
    props.setPortfoliosReady(false);

    if (portfolio.holdings.length > 0) {
      doFetchHoldings(
        props.client,
        portfolio,
        props.activePlaybook,
        (data: IPortfolioAPIResponse) => {
          data.holdings.forEach((holding: any) => {
            holding.open = false;
          });
          let obj = holdings;
          obj[portfolio.id] = data;
          setHoldings({ ...obj });
        }
      ).then(() => {
        refreshScoresAndRatios();
        props.setPortfoliosReady(true);
        setInputLoadingId(null);
      });
    } else {
      let obj = holdings;
      delete obj[portfolio.id];
      setHoldings({ ...obj });
      refreshScoresAndRatios();
      props.setPortfoliosReady(true);
    }
  };

  const refreshAllHoldings = async () => {
    props.setPortfoliosReady(false);

    await props.refreshUser();
    let client: IClient | undefined = props.client;

    if (
      !(
        props.template === PortfolioTemplate.FUND ||
        props.template === PortfolioTemplate.MODEL
      )
    ) {
      client = props.user.clients.find((c) => c.id === props.client.id);
    }

    if (client) {
      if (client.proposals && props.template === PortfolioTemplate.PROPOSAL) {
        for (const portfolio of client.proposals) {
          if (portfolio.holdings.length > 0) {
            await doFetchHoldings(
              client,
              portfolio,
              props.activePlaybook,
              (data: IPortfolioAPIResponse) => {
                data.holdings.forEach((holding: any) => {
                  holding.open = false;
                });

                let obj = holdings;
                obj[portfolio.id] = data;
                setHoldings({ ...obj });
              }
            ).then(() => {
              refreshScoresAndRatios();
            });
          } else {
            refreshScoresAndRatios();
          }
        }
      } else {
        for (const portfolio of client.portfolios) {
          if (portfolio.holdings.length > 0) {
            await doFetchHoldings(
              client,
              portfolio,
              props.activePlaybook,
              (data: IPortfolioAPIResponse) => {
                data.holdings.forEach((holding: any) => {
                  holding.open = false;
                });

                let obj = holdings;
                obj[portfolio.id] = data;
                setHoldings({ ...obj });
              }
            ).then(() => {
              refreshScoresAndRatios();
            });
          } else {
            refreshScoresAndRatios();
          }
        }
        props.setPortfoliosReady(true);
      }
    }

    props.setPortfoliosReady(true);
  };

  const readonly = (model?: any): boolean => {
    if (
      props.template === PortfolioTemplate.PROPOSAL ||
      props.template === PortfolioTemplate.PORTFOLIO
    ) {
      return false;
    } else {
      if (model) {
        return !model.tags.includes("my-models");
      }
    }

    return false;
  };

  const onCreatePortfolioFromModelComplete = () => {
    toast.success(
      `${
        modalTemplate === PortfolioTemplate.PROPOSAL ? "Proposal" : "Portfolio"
      } created` , {autoClose: 1000 });
    refreshAllHoldings();
  };

  const createPortfolioFromModel = () => {
    if (props.client && activeModel) {
      let updatedClient = props.client;

      if (
        props.client.temporaryPortfolios !== undefined &&
        props.client.temporaryPortfolios.length > 0
      ) {
        updatedClient.portfolios = props.client.temporaryPortfolios;
      } else {
        updatedClient.portfolios = [];
      }

      doCreatePortfolio(updatedClient, activeModel, modalTemplate).then(
        onCreatePortfolioFromModelComplete
      );
      modalTemplate === PortfolioTemplate.PROPOSAL
        ? props.handleClientSelection(props.client, "proposals")
        : props.handleClientSelection(props.client);
    }
  };

  useEffect(() => {
    if (tickerQuery === "") {
      setPortfolioIsSearching("");
    }
  }, [portfolioIsSearching]);

  let isSmallScreen: boolean;
  window.innerWidth < 767 && (isSmallScreen = true);

  const handleShowIssues = (p: Portfolio, holding: IHolding) => {
    let newHoldings = holdings;
    newHoldings[p.id].holdings = newHoldings[p.id].holdings.filter(
      (h: any) => h.id != holding.id
    );

    let newHolding = holding;
    newHolding.open = !holding.open;

    newHoldings[p.id].holdings = newHoldings[p.id].holdings.concat(newHolding);

    setHoldings({ ...holdings });
  };

  const handleDollarInputSave = (
    value: number,
    portfolio: Portfolio | Model,
    holdingId: number
  ) => {
    let updatedHolding = portfolio.holdings.find(
      (h) => h.instrumentId === holdingId
    );

    setInputLoadingId(holdingId);

    updatedHolding = {
      ...updatedHolding,
      instrumentId: updatedHolding!.instrumentId,
      positionSize: value,
    };

    let allHoldings = portfolio.holdings.filter(
      (h) => h.instrumentId !== holdingId
    );

    allHoldings.push(updatedHolding);

    let updatedPortfolio = {
      ...portfolio,
      holdings: allHoldings,
    };
    if (
      props.template === PortfolioTemplate.MODEL ||
      props.template === PortfolioTemplate.FUND
    ) {
      doCreateOrUpdateModel(updatedPortfolio as Model);
    } else {
      doUpdatePortfolio(
        props.client,
        updatedPortfolio,
        props.template === PortfolioTemplate.PORTFOLIO
          ? "portfolio"
          : "proposal"
      );
    }

    toast.success("Holding updated successfully", {autoClose: 1000 });
    refreshHoldings(updatedPortfolio);
  };

  const holdingRow = css({
    ":hover div.closeHover": { display: "block !important" },
  });
  const closeHover = hover({ opacity: "1 !important" });

  const disclaimerToast = () => (
    <span
      onClick={() => {
        props.setEditUserModalVisible(true);
      }}
    >
      <a style={{ textDecoration: "underline" }} href="#">
        Add a disclaimer
      </a>{" "}
      to your report.
    </span>
  );

  const DropDownOptions = (p: any) => {
    switch (props.template) {
      case PortfolioTemplate.PORTFOLIO:
      case PortfolioTemplate.PROPOSAL:
        return [
          { title: "Edit", value: "edit" },
          { title: "Delete", value: "delete" },
        ];
      case PortfolioTemplate.MODEL:
      case PortfolioTemplate.FUND:
        if (
          Object.keys(p).includes("tags") &&
          (p as Model).tags.includes("my-models")
        ) {
          return [
            { title: "Edit", value: "edit" },
            { title: "Delete", value: "delete" },
            { title: "New Portfolio", value: "portfolio" },
            { title: "New Proposal", value: "proposal" },
          ];
        } else {
          return [
            { title: "New Portfolio", value: "portfolio" },
            { title: "New Proposal", value: "proposal" },
          ];
        }
    }
  };

  const renderPortfolios = () => {
    switch (props.template) {
      case PortfolioTemplate.PORTFOLIO:
        return props.client.portfolios;
      case PortfolioTemplate.PROPOSAL:
        return props.client.proposals;
      case PortfolioTemplate.MODEL:
      case PortfolioTemplate.FUND:
        return props.models;
    }
  };

  return (
    <div ref={printRef}>
      <div id={props.view === ClientView.CLIENT_INSIGHT_REPORT ? "root" : ""}>
        <div
          className={
            props.view === ClientView.CLIENT_INSIGHT_REPORT
              ? "inspire-body"
              : ""
          }
        >
          <Confirm
            isActive={deleteConfirmVisible}
            onClose={() => setDeleteConfirmVisible(false)}
            confirmText="Delete"
            onConfirm={() => (
              doDeletePortfolio(
                props.client,
                activePortfolio as Portfolio,
                props.template,
                props.user
              ),
              setDeleteConfirmVisible(false),
              // this refreshes local state when deleting a model only, if enabled for portfolios/proposals, local state won't refresh
              props.template === "model" && refreshAllHoldings(),
              toast.success(
                `${
                  capitalizeFirstLetter(props.template) as string
                } deleted successfully`, {autoClose: 1000 }
              )
            )}
            message={`Are you sure you want to delete this ${props.template}?`}
          />
          <CreatePortfolioModal
            isActive={props.createPortfolioModalOpen}
            onClose={() => {
              props.setCreatePortfolioModalOpen(false);
              refreshAllHoldings();
            }}
            client={props.client}
            template={props.template}
          />
          {props.uploadPortfolioModalOpen && (
            <UploadPortfolioModal
              isActive={props.uploadPortfolioModalOpen}
              setActivePortfolio={setActivePortfolio}
              onCancel={() => {
                props.setUploadPortfolioModalOpen &&
                  props.setUploadPortfolioModalOpen(false);
              }}
              onClose={() => {
                props.setUploadPortfolioModalOpen &&
                  props.setUploadPortfolioModalOpen(false);

                if (props.template === PortfolioTemplate.MODEL) {
                  if (props.client.id && props.client.id !== 'test') {
                    navigate(`/client/${props.client.id}/models`);
                  } else {
                    navigate('/models');
                  }
                } else {
                  navigate(`/client/${props.client.id}/${activePortfolio?.id}`);
                }

                // the second navigate is to force a refresh of the page
                navigate(0);
              }}
              client={props.client}
              template={props.template}
            />
          )}

          <Playbook
            visible={props.playbookVisible}
            activePlaybook={props.activePlaybook}
            setActivePlaybook={props.setActivePlaybook}
            setPlaybookVisible={props.setPlaybookVisible}
            onClose={(
              playbookIsZero: boolean,
              playbookMenuTitle: string,
              isClientCreatedPlaybook: boolean
            ) => {
              if (!playbookIsZero) {
                props.handlePlaybookSave(
                  playbookMenuTitle,
                  isClientCreatedPlaybook
                );
                props.setPlaybookVisible(false);
                refreshAllHoldings();
              }
            }}
            onPlaybookChange={props.updatePlaybook}
            resetPlaybook={props.resetPlaybook}
            client={props.client}
            user={props.user}
          />
          {activePortfolio && (
            <CreatePortfolioModal
              edit
              isActive={editModalVisible}
              portfolio={activePortfolio}
              onClose={() => {
                setEditModalVisible(false);
                props.refreshUser();
                refreshScoresAndRatios();
              }}
              client={props.client}
              template={props.template as PortfolioTemplate}
            />
          )}
          <CreateModal
            isActive={modalVisible}
            createPortfolio={createPortfolioFromModel}
            setActiveClient={props.setActiveClient}
            onClose={() => setModalVisible(false)}
            template={modalTemplate}
            clients={props.user.clients}
            activeModel={activeModel}
          />
          {props.view === ClientView.CLIENT_INSIGHT_REPORT && (
            <ReportHeader
              client={props.client}
              portfoliosReady={props.portfoliosReady}
              activePortfolio={activePortfolio}
              holdings={holdings}
              urlPortfolioId={props.urlPortfolioId}
              handlePrint={handlePrint}
            />
          )}
          <div
            id="mainContent"
            className="fullWidthContainer portfolioContainer"
            style={{
              pageBreakBefore: "always",
              pageBreakInside: "avoid",
            }}
          >
            <div
              className={
                props.template == PortfolioTemplate.MODEL
                  ? "fullWidthContent modelContent"
                  : "fullWidthContent"
              }
            >
              {props.view === ClientView.CLIENT_INSIGHT_REPORT && (
                <h2>Top 10 Holdings</h2>
              )}
              <>
                {![
                  PortfolioTemplate.FUND,
                  PortfolioTemplate.PORTFOLIO,
                  PortfolioTemplate.PROPOSAL,
                ].includes(props.template) && (
                  <Row className="cardRowContent holdingHeader holdingHeaderLarge">
                    <Col xs={8} sm={8} md={8} lg={4}>
                      <h4
                        style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => {
                          handleSort("name", "portfolios");
                        }}
                      >
                        Name{DynamicArrow("name", "portfolios")}
                      </h4>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={2}>
                      <h4
                        style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => {
                          handleSort("totalMonetaryValue", "portfolios");
                        }}
                      >
                        Value{DynamicArrow("totalMonetaryValue", "portfolios")}
                      </h4>
                    </Col>
                    <Col xs={8} sm={3} md={3} lg={1}>
                      <h4
                        style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => {
                          handleSort("alignment", "portfolios");
                        }}
                      >
                        Aligned{DynamicArrow("alignment", "portfolios")}
                      </h4>
                    </Col>
                    <Col xs={4} sm={3} md={3} lg={2}>
                      <h4
                        style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => {
                          handleSort("resonateScore", "portfolios");
                        }}
                      >
                        Resonate Score
                        {DynamicArrow("resonateScore", "portfolios")}
                      </h4>
                    </Col>

                    <Col xs={6} sm={3} md={3} lg={2}></Col>
                    <Col
                      xs={4}
                      sm={3}
                      md={3}
                      lg={1}
                      className="alignRight"
                      xsOffset={1}
                      smOffset={0}
                    ></Col>
                  </Row>
                )}
                {(renderPortfolios() || [])
                  .sort((first: any, next: any): number => {
                    first = { ...first, ...holdings[first.id] };
                    next = { ...next, ...holdings[next.id] };

                    if (first[portfoliosSortKey] < next[portfoliosSortKey]) {
                      return portfoliosSortDirection === "asc" ? -1 : 1;
                    } else if (
                      first[portfoliosSortKey] > next[portfoliosSortKey]
                    ) {
                      return portfoliosSortDirection === "asc" ? 1 : -1;
                    } else {
                      return 0;
                    }

                    // if (sortMethod === "resonate") {
                    //   if (
                    //     holdings[a.id].resonateScore > holdings[b.id].resonateScore
                    //   )
                    //     return -1;
                    //   else if (
                    //     holdings[a.id].resonateScore == holdings[b.id].resonateScore
                    //   )
                    //     return 0;
                    //   else return 1;
                    // } else if (sortMethod === "alignment") {
                    //   if (holdings[a.id].alignment > holdings[b.id].alignment)
                    //     return -1;
                    //   else if (holdings[a.id].alignment == holdings[b.id].alignment)
                    //     return 0;
                    //   else return 1;
                    // } else {
                    //   if (a.name < b.name) return -1;
                    //   else if (a.name == b.name) return 0;
                    //   else return 1;
                    // }
                  })
                  // if view is client insight report, only show portfolio with id that matches urlPortfolioId
                  .filter((p) => {
                    if (
                      props.view === ClientView.CLIENT_INSIGHT_REPORT &&
                      props.urlPortfolioId !== p.id
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map((p) => (
                    <>
                      <div className="cardRow" key={p.id}>
                        {props.template !== PortfolioTemplate.FUND &&
                          props.view !== ClientView.CLIENT_INSIGHT_REPORT && (
                            <div
                              className="cardRowHeader portfolioHeader"
                              onClick={() =>
                                handlePortfoliosOpen(p.id, portfoliosOpen[p.id])
                              }
                              ref={
                                props.urlPortfolioId === p.id
                                  ? portfolioRef
                                  : null
                              }
                            >
                              <Row>
                                <Col
                                  xs={themeState.theme === "resonate" ? 8 : 6}
                                  sm={8}
                                  md={8}
                                  lg={4}
                                >
                                  <h3>{p.name}</h3>
                                </Col>
                                {themeState.theme !== "resonate" ? (
                                  <>
                                    <Col xs={3} sm={2} lg={2}>
                                      {p.holdings.length > 0 &&
                                      !props.portfoliosReady ? (
                                        <h4>
                                          <a href="#">
                                            <img
                                              width={
                                                props.template !==
                                                PortfolioTemplate.MODEL
                                                  ? "50"
                                                  : "35"
                                              }
                                              style={{ padding: "4px 0" }}
                                              src={
                                                themeState.theme === "resonate"
                                                  ? LoadingIcon
                                                  : LoadingIconGrey
                                              }
                                            />
                                          </a>
                                        </h4>
                                      ) : Object.keys(holdings).includes(
                                          p.id
                                        ) ? (
                                        <h4>
                                          $
                                          {holdings[
                                            p.id
                                          ].totalMonetaryValue.toLocaleString(
                                            "en-US"
                                          )}
                                        </h4>
                                      ) : (
                                        <h4>$0</h4>
                                      )}
                                    </Col>
                                    <Col xs={3} sm={2}>
                                      {Object.keys(holdings).includes(p.id) &&
                                      props.portfoliosReady ? (
                                        <ImpactScore
                                          type="small"
                                          score={holdings[p.id].impactScore}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                  </>
                                ) : (
                                  <Col xs={2}>
                                    {p.holdings.length > 0 &&
                                    !props.portfoliosReady ? (
                                      <h4>
                                        <a href="#">
                                          <img
                                            width={
                                              props.template !==
                                              PortfolioTemplate.MODEL
                                                ? "50"
                                                : "35"
                                            }
                                            style={{ padding: "4px 0" }}
                                            src={
                                              themeState.theme === "resonate"
                                                ? LoadingIcon
                                                : LoadingIconGrey
                                            }
                                          />
                                        </a>
                                      </h4>
                                    ) : Object.keys(holdings).includes(p.id) ? (
                                      <h4>
                                        $
                                        {holdings[
                                          p.id
                                        ].totalMonetaryValue.toLocaleString(
                                          "en-US"
                                        )}
                                      </h4>
                                    ) : (
                                      <h4>$0</h4>
                                    )}
                                  </Col>
                                )}
                                {themeState.theme === "resonate" && (
                                  <>
                                    <Col xs={8} sm={3} md={3} lg={1}>
                                      {Object.keys(holdings).includes(p.id) &&
                                      props.portfoliosReady ? (
                                        <h4
                                          style={{
                                            textDecoration: "none",
                                            textTransform: "none",
                                          }}
                                        >
                                          <strong>
                                            {holdings[p.id].alignment}%
                                          </strong>
                                          <span>Aligned</span>
                                        </h4>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                    <Col xs={4} sm={3} md={3} lg={2}>
                                      {Object.keys(holdings).includes(p.id) &&
                                      props.portfoliosReady ? (
                                        <h4
                                          style={{
                                            textDecoration: "none",
                                            textTransform: "none",
                                          }}
                                        >
                                          <strong>
                                            {props.displayRatio &&
                                              props.displayRatio(
                                                holdings[p.id].resonateScore
                                              )}
                                          </strong>
                                          <span>Resonate Score</span>
                                        </h4>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                  </>
                                )}

                                <>
                                  <Col
                                    xs={6}
                                    sm={themeState.theme === "resonate" ? 3 : 9}
                                    lg={themeState.theme === "resonate" ? 2 : 3}
                                  >
                                    <Button
                                      preset="formPrimary"
                                      stopPropagation={true}
                                      fullWidth
                                      onClick={() => {
                                        if (themeState.theme === "resonate") {
                                          let requestId = Math.random()
                                            .toString(16)
                                            .slice(-4);
                                          props.setToastIds({
                                            ...props.toastIds,
                                            [requestId]: toast.loading(
                                              `Report for ${p.name} is generating`,
                                              { closeOnClick: true }
                                            ) as any,
                                          });
                                          doGenerateReport(
                                            props.client,
                                            p,
                                            props.activePlaybook,
                                            disclaimerToast,
                                            requestId,
                                            props.template
                                          );
                                        } else {
                                          window.open(
                                            "../insight-report/" +
                                              props.client.id +
                                              "/" +
                                              p.id +
                                              "",
                                            "_blank"
                                          );
                                          navigate(0);
                                        }
                                      }}
                                    >
                                      <DocumentOnePage
                                        width="20"
                                        style={{
                                          marginRight: 8,
                                          position: "relative",
                                          bottom: 2,
                                        }}
                                      />
                                      Create Report
                                    </Button>
                                  </Col>
                                  <Col
                                    xs={4}
                                    sm={3}
                                    md={3}
                                    lg={1}
                                    className="alignRight"
                                    xsOffset={1}
                                    smOffset={0}
                                  >
                                    <ContextMenu
                                      icon={Elipsis}
                                      iconWidth="20"
                                      onClick={(value: string) => {
                                        if (value === "edit") {
                                          setActivePortfolio(p);
                                          setEditModalVisible(true);
                                        } else if (value === "delete") {
                                          setActivePortfolio(p);
                                          setDeleteConfirmVisible(true);
                                        } else if (value === "portfolio") {
                                          setActiveModel(p);
                                          setModalTemplate(
                                            PortfolioTemplate.PORTFOLIO
                                          );
                                          if (
                                            props.view ===
                                            ClientView.CLIENT_MODELS
                                          ) {
                                            setActiveModel(p);
                                          } else {
                                            setModalVisible(true);
                                          }
                                        } else if (value === "proposal") {
                                          setActiveModel(p);
                                          setModalTemplate(
                                            PortfolioTemplate.PROPOSAL
                                          );
                                          if (
                                            props.view ===
                                            ClientView.CLIENT_MODELS
                                          ) {
                                            setActiveModel(p);
                                          } else {
                                            setModalVisible(true);
                                          }
                                        }
                                      }}
                                      options={DropDownOptions(p)}
                                    />
                                  </Col>
                                </>
                              </Row>
                            </div>
                          )}
                        {Object.keys(holdings).includes(p.id) &&
                          portfoliosOpen[p.id] === true && (
                            <motion.div
                              initial={{ y: -5, opacity: 0 }}
                              animate={{
                                y: 0,
                                opacity: 1,
                                transition: {
                                  delay:
                                    props.template !== PortfolioTemplate.FUND
                                      ? 0.015
                                      : 0,
                                  duration:
                                    props.template !== PortfolioTemplate.FUND
                                      ? 0.2
                                      : 0,
                                },
                              }}
                              exit={{}}
                            >
                              <div className="cardRowContent holdingHeader">
                                <Row>
                                  <Col
                                    sm={2}
                                    xs={2}
                                    md={1}
                                    className="holdingTitle"
                                  >
                                    <h4
                                      style={{
                                        cursor: "pointer",
                                        userSelect: "none",
                                      }}
                                      onClick={() => {
                                        handleSort("ticker");
                                      }}
                                    >
                                      Ticker{DynamicArrow("ticker")}
                                    </h4>
                                  </Col>
                                  <Col
                                    xs={4}
                                    sm={
                                      props.template ===
                                        PortfolioTemplate.FUND ||
                                      themeState.theme !== "resonate"
                                        ? 5
                                        : 3
                                    }
                                    className="holdingTitle hideOnMobile hideOnTablet showOnPrint"
                                  >
                                    <h4
                                      style={{
                                        cursor: "pointer",
                                        userSelect: "none",
                                      }}
                                      onClick={() => {
                                        handleSort("name");
                                      }}
                                    >
                                      Name{DynamicArrow("name")}
                                    </h4>
                                  </Col>
                                  {props.template != PortfolioTemplate.FUND && (
                                    <>
                                      <Col
                                        xs={
                                          themeState.theme === "resonate"
                                            ? 4
                                            : 5
                                        }
                                        xl={2}
                                        md={2}
                                        className="hideOnMobile hideOnTablet"
                                      >
                                        <h4
                                          style={{
                                            paddingLeft: 12,

                                            cursor: "pointer",
                                            userSelect: "none",
                                          }}
                                          onClick={() => {
                                            handleSort("positionSize");
                                          }}
                                        >
                                          Value{DynamicArrow("positionSize")}
                                        </h4>
                                      </Col>
                                      <Col xs={3} sm={2} md={1} lg={1}>
                                        <h4
                                          style={{
                                            cursor: "pointer",
                                            userSelect: "none",
                                          }}
                                          onClick={() => {
                                            handleSort("positionSizePercent");
                                          }}
                                        >
                                          Asset %
                                          {DynamicArrow("positionSizePercent")}
                                        </h4>
                                      </Col>
                                    </>
                                  )}
                                  {themeState.theme === "resonate" ? (
                                    <>
                                      <Col
                                        xs={4}
                                        sm={2}
                                        md={2}
                                        className="hideOnMobile hideOnTablet"
                                      >
                                        <h4>
                                          Screens
                                          {/* {DynamicArrow("name")} */}
                                        </h4>
                                      </Col>
                                      <Col xs={3} sm={3} md={1}>
                                        <h4
                                          style={{
                                            cursor: "pointer",
                                            userSelect: "none",
                                          }}
                                          onClick={() => {
                                            handleSort("alignmentPercent");
                                          }}
                                        >
                                          Alignment
                                          {DynamicArrow("alignmentPercent")}
                                        </h4>
                                      </Col>
                                      <Col xs={3} sm={3} md={2}>
                                        <h4
                                          style={{
                                            cursor: "pointer",
                                            userSelect: "none",
                                          }}
                                          onClick={() => {
                                            handleSort("resonateScore");
                                          }}
                                        >
                                          Resonate Score
                                          {DynamicArrow("resonateScore")}
                                        </h4>
                                      </Col>
                                    </>
                                  ) : (
                                    <Col xs={3} sm={3} md={2}>
                                      <h4
                                        style={{
                                          cursor: "pointer",
                                          userSelect: "none",
                                        }}
                                        onClick={() => {
                                          handleSort("impactScore");
                                        }}
                                      >
                                        Impact Score
                                        {DynamicArrow("impactScore")}
                                      </h4>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                              {holdings[p.id].holdings
                                .sort((first: any, next: any) => {
                                  if (first[sortKey] < next[sortKey]) {
                                    return sortDirection === "asc" ? -1 : 1;
                                  } else if (first[sortKey] > next[sortKey]) {
                                    return sortDirection === "asc" ? 1 : -1;
                                  } else {
                                    if (first.name < next.name) {
                                      return sortDirection === "asc" ? -1 : 1;
                                    } else if (first.name > next.name) {
                                      return sortDirection === "asc" ? 1 : -1;
                                    } else {
                                      return 0;
                                    }
                                  }
                                })
                                .filter((holding) => {
                                  if (props.query) {
                                    return (
                                      holding.name
                                        .toLowerCase()
                                        .includes(props.query.toLowerCase()) ||
                                      holding.ticker
                                        .toLowerCase()
                                        .includes(props.query.toLowerCase())
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                // limit to 10 holdings if view is insight client report
                                .slice(
                                  0,
                                  props.view ===
                                    ClientView.CLIENT_INSIGHT_REPORT
                                    ? 10
                                    : holdings[p.id].holdings.length
                                )
                                // .reverse()
                                .map((holding: IHolding, index: any) => (
                                  <div>
                                    <div
                                      onClick={() => {
                                        !isSmallScreen &&
                                          props.view !==
                                            ClientView.CLIENT_INSIGHT_REPORT &&
                                          handleShowIssues(p, holding);
                                      }}
                                      className={
                                        holding.open
                                          ? "cardRowContent openHolding"
                                          : "cardRowContent"
                                      }
                                    >
                                      <Row {...holdingRow}>
                                        <Col
                                          xs={2}
                                          sm={2}
                                          md={1}
                                          className="holdingTitle"
                                        >
                                          <h3
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            <strong style={{ marginRight: 5 }}>
                                              {holding.ticker}
                                            </strong>
                                          </h3>
                                        </Col>
                                        <Col
                                          xs={4}
                                          sm={
                                            props.template ===
                                              PortfolioTemplate.FUND ||
                                            themeState.theme !== "resonate"
                                              ? 5
                                              : 3
                                          }
                                          className="holdingTitle hideOnMobile hideOnTablet showOnPrint"
                                        >
                                          <h3
                                            style={{
                                              display: "inline-block",
                                            }}
                                            className="abbreviateOnPrint"
                                          >
                                            {holding.name}
                                          </h3>
                                        </Col>
                                        {props.template !=
                                          PortfolioTemplate.FUND && (
                                          <>
                                            <Col
                                              xs={
                                                themeState.theme === "resonate"
                                                  ? 4
                                                  : 5
                                              }
                                              md={2}
                                              xl={2}
                                              className="hideOnMobile hideOnTablet"
                                            >
                                              <div
                                                className="inputContainer dollarContainer"
                                                style={{ marginBottom: 0 }}
                                              >
                                                <DollarInput
                                                  holdingTicker={holding.ticker}
                                                  onLoadValue={
                                                    holding.positionSize
                                                  }
                                                  disabled={
                                                    isSmallScreen ||
                                                    readonly(p) ||
                                                    props.view ===
                                                      ClientView.CLIENT_INSIGHT_REPORT
                                                  }
                                                  isLoading={
                                                    inputLoadingId ===
                                                    holding.id
                                                  }
                                                  portfolio={p}
                                                  holdingId={holding.id}
                                                  onSave={handleDollarInputSave}
                                                />
                                              </div>
                                            </Col>

                                            <Col xs={3} sm={2} md={1} xl={1}>
                                              <h5>
                                                {(
                                                  (holding.positionSize /
                                                    holdings[p.id]
                                                      .totalMonetaryValue) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </h5>
                                            </Col>
                                          </>
                                        )}
                                        {themeState.theme === "resonate" ? (
                                          <>
                                            <Col
                                              xs={3}
                                              sm={2}
                                              md={2}
                                              className="hideOnMobile hideOnTablet"
                                            >
                                              <ul className="pointScore">
                                                <li className="pointPositive">
                                                  <i>
                                                    <PlusSmall size="10" />
                                                  </i>
                                                  <span>
                                                    {holding.totalPositive}
                                                  </span>
                                                </li>
                                                <li className="pointNegative">
                                                  <i>
                                                    <Minus size="10" />
                                                  </i>
                                                  <span>
                                                    {holding.totalNegative}
                                                  </span>
                                                </li>
                                              </ul>
                                            </Col>
                                            <Col xs={3} md={1}>
                                              <h5>
                                                {roundAlignment(
                                                  holding.alignmentPercent
                                                )}
                                              </h5>
                                            </Col>
                                            <Col xs={2} sm={2} md={1}>
                                              <h5>
                                                {holding.resonateScore.toFixed(
                                                  2
                                                )}
                                              </h5>
                                            </Col>
                                          </>
                                        ) : (
                                          <Col xs={3} sm={2} md={1}>
                                            <ImpactScore
                                              type="small"
                                              score={holding.impactScore}
                                            />
                                          </Col>
                                        )}
                                        {props.view !==
                                          ClientView.CLIENT_INSIGHT_REPORT && (
                                          <Col
                                            xs={1}
                                            sm={1}
                                            style={{
                                              textAlign: "right",
                                              display: "none",
                                              opacity: 0.4,
                                            }}
                                            {...closeHover}
                                            className="closeHover"
                                          >
                                            {!isSmallScreen && !readonly(p) && (
                                              <Close
                                                onClick={(e) => (
                                                  e.stopPropagation(),
                                                  doDeleteHolding(
                                                    props.client,
                                                    p,
                                                    holding.id,
                                                    props.template,
                                                    () => refreshHoldings(p)
                                                  ),
                                                  toast.success("Ticker removed successfully", {autoClose: 1000 })
                                                )}
                                                style={{
                                                  width: 20,
                                                  cursor: "pointer",
                                                }}
                                              />
                                            )}
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                    <AnimatePresence>
                                      {holding.open && (
                                        <>
                                          <motion.div
                                            initial={{ y: -5, opacity: 0 }}
                                            animate={{
                                              y: 0,
                                              opacity: 1,
                                              transition: {
                                                delay: 0.015,
                                                duration: 0.2,
                                              },
                                            }}
                                            exit={{}}
                                          >
                                            <div className="cardRowContent cardRowContentScreening">
                                              {Object.entries(holding.issues)
                                                .filter(
                                                  ([key, value]) =>
                                                    !value.positive
                                                )
                                                .map(([key, value]) => (
                                                  <Issue
                                                    p={p}
                                                    title={key}
                                                    holding={holding}
                                                    value={value}
                                                    handleIssues={handleIssues}
                                                    activeIssueTicker={
                                                      activeIssueTicker
                                                    }
                                                    esgIssues={esgIssues}
                                                  />
                                                ))}
                                              {Object.entries(holding.issues)
                                                .filter(
                                                  ([key, value]) =>
                                                    value.positive
                                                )
                                                .map(([key, value]) => (
                                                  <Issue
                                                    p={p}
                                                    // ckey={ckey}
                                                    title={key}
                                                    value={value}
                                                    holding={holding}
                                                    handleIssues={handleIssues}
                                                    activeIssueTicker={
                                                      activeIssueTicker
                                                    }
                                                    esgIssues={esgIssues}
                                                  />
                                                ))}

                                              {Object.keys(holding.issues)
                                                .length === 0 && (
                                                <ul className="screeningTickers noRelevantData">
                                                  <li className="label">
                                                    <AlertCircleOutline
                                                      width={20}
                                                      style={{
                                                        marginRight: 8,
                                                        marginTop: -2,
                                                      }}
                                                    />
                                                    No relevant data available.
                                                  </li>
                                                </ul>
                                              )}
                                            </div>
                                          </motion.div>
                                        </>
                                      )}
                                    </AnimatePresence>
                                  </div>
                                ))}
                              {props.template !== PortfolioTemplate.FUND &&
                                props.view !==
                                  ClientView.CLIENT_INSIGHT_REPORT && (
                                  <Cash
                                    portfolio={p}
                                    disabled={
                                      isSmallScreen ||
                                      props.template ===
                                        (PortfolioTemplate.MODEL ||
                                          PortfolioTemplate.FUND)
                                    }
                                    holdings={holdings}
                                    client={props.client}
                                    onSaveCallback={() => {
                                      refreshHoldings(p);
                                      toast.success("Cash value updated", {autoClose: 1000 });
                                    }}
                                    template={props.template}
                                  />
                                )}
                            </motion.div>
                          )}
                        {!readonly(p) &&
                          props.view !== ClientView.CLIENT_INSIGHT_REPORT && (
                            <TickerSearch
                              portfolio={p}
                              template={props.template}
                              activePlaybook={props.activePlaybook}
                              client={props.client}
                              setActivePortfolio={setActivePortfolio}
                              addCallback={() => {
                                handlePortfoliosOpen(p.id, false);
                                refreshHoldings(p);
                              }}
                            />
                          )}
                      </div>
                    </>
                  ))}
              </>
            </div>
          </div>
          {props.view === ClientView.CLIENT_INSIGHT_REPORT && (
            <div
              style={{
                pageBreakBefore: "always",
                pageBreakInside: "avoid",
              }}
            >
              <ReportGive50
                portfoliosReady={props.portfoliosReady}
                activePortfolio={activePortfolio}
                holdings={holdings}
                urlPortfolioId={props.urlPortfolioId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
