import { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col } from "react-flexbox-grid";
import Modal from "../Modal";
import Button from "../Button";
import checkIcon from "../../images/select-check-white.svg";
import { hostUrl } from "../Firebase";
import { validatePhone } from "../../constants";
import { doCreatePortfolio, doUpdatePortfolio } from "../Firebase";
import { toast } from "react-toastify";
import { IClient, Portfolio } from "../../models";
import { v4 as uuidv4 } from "uuid";
import { E } from "styled-icons/simple-icons";

interface ShareProfileModalProps {
  isActive: boolean;
  clientId: string;
  clientName: string;
  advisorUserId: string;
  onClose: (callbackData?: any) => void;
  setShareProfileModalVisible: (arg0: boolean) => void;
}

export function ShareProfileModal(props: ShareProfileModalProps) {
  const [formError, setFormError] = useState<boolean>(false);
  const [isUrlCopied, setIsUrlCopied] = useState<boolean>(false);
  const profileURL = `https://app.startresonating.com/screening/${props.clientId}`;

  useEffect(() => {
    setIsUrlCopied(false);
    setFormError(false);
  }, [props.isActive]);

  const createLivelink = () => {
    const screeningRequest = {
      clientId: props.clientId,
      advisorUserId: props.advisorUserId,
      clientName: props.clientName,
    };

    axios.post(`${hostUrl}/playbook-init`, screeningRequest).then((res) => {
      // need success toast and error catching
      console.log(res);
      console.log(res.data);
    });
  };

  const copyProfileToClipboard = async () => {
    createLivelink();

    try {
      await navigator.clipboard.writeText(profileURL);
      setIsUrlCopied(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      modalTitle={"Send Profile to Client"}
      isActive={props.isActive}
      onClose={() => props.setShareProfileModalVisible(false)}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="copy-link">Click to copy:</label>
              <input
                type="text"
                id="copy-link"
                value={profileURL}
                onClick={(e) => {
                  (e.target as HTMLInputElement).select();
                  (e.target as HTMLInputElement).focus();
                  copyProfileToClipboard();
                }}
                placeholder=""
              />
              {isUrlCopied ? (
                <p className="input-message success">
                  <img
                    src={checkIcon}
                    style={{
                      width: 19,
                      backgroundColor: "#008080",
                      padding: 4,
                      borderRadius: "50%",
                      marginRight: 5,
                    }}
                  />
                  URL copied to clipboard.
                </p>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </form>
      <Row>
        <Col xs={12}>
          <Button
            fullWidth
            onClick={() => props.setShareProfileModalVisible(false)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
