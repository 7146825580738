import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./styles/App.scss";
import "react-toastify/dist/ReactToastify.css";

import themeContext from "./contexts/ThemeContext";

import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/Routes/PrivateRoute";

/* Pages */
import Home from "./pages/Resonate/Home";
import Footer from "./components/Resonate/Home/Footer";
import Login from "./pages/Login";
import Migrate from "./pages/Migrate";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import StandalonePlaybookContainer from "./components/Portfolios/StandalonePlaybookContainer";
import InsightHome from "./pages/Insight/InsightHome";
import InstrumentDetails from "./pages/Insight/InstrumentDetails";
import { useContext } from "react";
import OnLoadRedirect from "./components/OnLoadRedirect";

function App() {
  const { theme, setTheme } = useContext(themeContext);

  let routes = <></>;
  switch (theme as string) {
    case "resonate":
      routes = (
        <>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/:urlClientId/:urlPortfolioId"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/:urlClientId"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/:urlClientId/models"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/models"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          ,
          <Route path="/login" element={<Login />} />,
          <Route path="/register" element={<Signup />} />
        </>
      );
      break;
    default:
      routes = (
        <>
          <Route path="/" element={<InsightHome />}></Route>
          <Route
            path="/client/:urlClientId/:urlPortfolioId"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/:urlClientId"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insight-report/:urlClientId/:urlPortfolioId"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Home />
                <Footer />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />,
          <Route path="/migrate" element={<Migrate />} />,
          <Route path="/register" element={<Signup />} />
          <Route
            path="/screening-categories"
            element={
              <OnLoadRedirect url="https://legacy.inspireinsight.com/screening-categories" />
            }
          />
          <Route path="/:ticker" element={<InstrumentDetails />} />
          <Route path="/:ticker/:location" element={<InstrumentDetails />} />
        </>
      );
  }

  return (
    <>
      {/* <div>
        Current Context: {theme}.{" "}
        <a
          onClick={() => {
            setTheme(
              theme === "resonate" ? "insight" : "resonate"
            );
          }}
        >
          Switch
        </a>
      </div> */}
      <Routes>
        <Route
          path="/screening/:liveLinkId"
          element={<StandalonePlaybookContainer />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {routes}
        {/* <Route
        // exact
        path="/:ticker/:location"
        element={<InstrumentDetails />}
      /> */}
      </Routes>
    </>
  );
}

export default App;
