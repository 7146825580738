import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import models, { PLAYBOOK_DEFINITIONS } from "../../constants";
import circleInfo from "../../images/circle-info-light.svg";
import Logo from "../../images/scoreboard-logo.svg";
import PlaybookPositions, {
  PlaybookPosition,
  categories,
} from "../../models/Resonate/playbook";
import Button from "../Button";
import { hostUrl } from "../Firebase";
import SelectMenu from "../SelectMenu";
import { PlaybookButton } from "./PlaybookButton";
interface PlaybookProps {
  clientData: {
    clientId: number;
    advisorUserId: string;
    clientName: string;
    playbookName: string;
  };
}

export function StandalonePlaybook(props: PlaybookProps) {
  const { clientData } = props;
  const [playbookIsZero, setPlaybookIsZero] = useState<boolean>(false);
  const [playbookMenuTitle, setPlaybookMenuTitle] = useState<string>("");
  const [clientPlaybook, setClientPlaybook] = useState<PlaybookPositions>({});
  const [profileCompleted, setProfileCompleted] = useState<boolean>(false);
  const [screeningDefinition, setScreeningDefinition] = useState("");
  const [explainPlaybook, setExplainPlaybook] = useState<boolean>(false);

  useEffect(() => {
    // Object.keys(client.playbook).length === 0 && setPlaybookIsZero(true);
  }, []);

  const updatePlaybook = (category: string, value: PlaybookPosition) => {
    let updatedPlaybook = clientPlaybook;
    updatedPlaybook[category] = value;
    setClientPlaybook({ ...updatedPlaybook });
  };

  const handlePlaybookSave = (playbookMenuTitle: string) => {
    let result = new Date();
    let timestamp = result.toLocaleDateString();

    let updatedPlaybookName =
      "Client - " + timestamp + " - " + playbookMenuTitle;
    const completedData = {
      clientId: clientData.clientId,
      advisorUserId: clientData.advisorUserId,
      clientName: clientData.clientName,
      playbook: clientPlaybook,
      playbookName: updatedPlaybookName,
    };
    axios.post(`${hostUrl}/playbook-finish`, completedData).then((res) => {
      setProfileCompleted(true);
    });
    // client.playbookName = playbookMenuTitle;
    // doUpdateUser(newUser as IUser)
    //   .then(() => {
    //     toast.success("Profile saved");
    //   })
    //   .catch((e) => {
    //     toast.warning("Profile failed to save");
    //   });
  };

  let playbookClientName = `What resonates with you, ${clientData.clientName}?`;

  const checkSelected = (
    category: string,
    value: PlaybookPosition
  ): boolean => {
    if (Object.keys(clientPlaybook).includes(category)) {
      return clientPlaybook[category] === value;
    } else {
      return value === 0;
    }
  };

  const setProfile = (profile: { [key: string]: PlaybookPosition }) => {
    Object.entries(profile).forEach(([key, value]) => {
      updatePlaybook(key, value);
    });
  };

  const retrieveDefinition = (key: any) => {
    setScreeningDefinition((PLAYBOOK_DEFINITIONS as any)[key]);
  };

  return (
    <>
      {!profileCompleted ? (
        <div>
          <div style={{ position: "fixed", top: 0, width: "100%" }}>
            <Row style={{ backgroundColor: "#ffffff", padding: "20px 30px" }}>
              <Col xs={12}>
                <h2 style={{ textAlign: "center", marginBottom: 0 }}>
                  {playbookClientName}
                </h2>
              </Col>
            </Row>

            <Row style={{ backgroundColor: "#ffffff", padding: "20px" }}>
              <Col xs={6}>
                <SelectMenu
                  title="Preset Profiles"
                  persisentTitle={playbookMenuTitle}
                  activeState={playbookMenuTitle}
                  fullWidth
                  onClick={(value: string, title?: string) => {
                    setProfile(models[value]);
                    setPlaybookIsZero(false);
                    setPlaybookMenuTitle(title as string);
                  }}
                  options={[
                    {
                      title: "Christian (Catholic USCCB)",
                      value: "catholic",
                    },
                    {
                      title: "Christian (Protestant)",
                      value: "christian",
                    },
                    {
                      title: "Environmental",
                      value: "environmental",
                    },
                    // { title: "ESG", value: "esg" },
                    // {
                    //   title: "Libertarian",
                    //   value: "libertarian",
                    // },
                    // {
                    //   title: "Progressive",
                    //   value: "progressive",
                    // },
                  ]}
                />
              </Col>
              {/* <Col xs={4}>
          <Button
            onClick={() => {
              resetPlaybook();
              setPlaybookIsZero(true);
              setPlaybookMenuTitle("Custom Profile");
            }}
          >
            Reset Profile
          </Button>
        </Col> */}
              <Col
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  preset="formPrimary"
                  mini
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  onClick={() => {
                    handlePlaybookSave(playbookMenuTitle);
                  }}
                >
                  Save Profile
                </Button>
              </Col>
            </Row>
          </div>
          <Row style={{ padding: "20px 30px", marginTop: 190 }}>
            <Col>
              {/* <h1
              style={{
                fontWeight: 700,
                fontSize: "2.5em",
                marginBottom: 10,
                color: "#062a49",
              }}
            >
              Let's get personal.
            </h1> */}
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  color: "#062a49",
                  marginBottom: 0,
                }}
              >
                Your choices below will help your financial advisor align your
                portfolio to fit your personal values.
              </p>

              <Button
                mini
                style={{ display: "inline-block", position: "static" }}
                onClick={() => setExplainPlaybook(!explainPlaybook)}
              >
                How do I use this?
              </Button>

              {explainPlaybook && (
                <>
                  <Row
                    style={{
                      marginTop: 20,
                      paddingTop: 20,
                      borderTop: "1px solid rgb(224, 231, 231)",
                    }}
                  >
                    <Col xs={12} sm={6}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 5,
                        }}
                      >
                        Your financial advisor would like to know what values
                        you want to support or oppose with your investment
                        portfolio. Please select the issues you want to
                        “Support” or “Oppose”, or those which are simply “Not
                        Important” to you in your investment portfolio. For
                        example, selecting <strong>-3</strong> for
                        “Abortifacients” means that you “Strongly Oppose”
                        abortifacient manufacturing and distribution companies
                        and want them minimized or excluded from your portfolio.
                        Selecting <strong>2</strong> for “Business Ethics” means
                        you want to “Support” companies which demonstrate above
                        average business ethics and elevate their position in
                        your portfolio.
                      </p>
                    </Col>
                    <Col xs={12} sm={6}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 15,
                        }}
                      >
                        The selections you make below will generate your
                        client's Resonate Score algorithm that can be used to
                        quickly and easily identify which stocks or funds are
                        most aligned and least aligned with your client's
                        personal values and empower them to make more informed
                        investment decisions to support what they care about
                        most.
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 5,
                        }}
                      >
                        Fill out the profile and then click “Save Profile”. Your
                        responses will be automatically attached to your profile
                        and your advisor will be notified.
                      </p>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "rgb(232 235 235)",
                      padding: 0,
                      marginTop: 10,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "#062a49",
                        marginBottom: 0,
                        textAlign: "center",
                        padding: "5px 0",
                        display: "block",
                        width: "100%",
                        borderBottom: "2px solid rgb(215, 222, 222)",
                      }}
                    >
                      Screening Profile Legend:
                    </p>
                    <Col xs={12}>
                      <Row>
                        {[
                          { label: "Strongly Oppose", value: -3 },
                          { label: "Oppose", value: -2 },
                          { label: "Somewhat Oppose", value: -1 },
                          { label: "Not Important", value: 0 },
                          { label: "Somewhat Support", value: 1 },
                          { label: "Support", value: 2 },
                          { label: "Strongly Support", value: 3 },
                        ].map((button) => (
                          <Col
                            xs={12}
                            sm={1.5}
                            style={{
                              borderRight:
                                button.value === 3
                                  ? "0"
                                  : "2px solid rgb(215, 222, 222)",
                              padding: 10,
                              borderBottom: "2px solid rgb(215, 222, 222)",
                              textAlign: "center",
                            }}
                          >
                            <Row>
                              <Col
                                xs={4}
                                sm={12}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                  paddingTop: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PlaybookButton
                                    title={button ? button?.value : 0}
                                    style={{ marginRight: 5 }}
                                    selected={checkSelected(
                                      "yo",
                                      button?.value as PlaybookPosition
                                    )}
                                    onClick={() => {}}
                                  />
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                sm={12}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "0.9em",
                                    fontWeight: 600,
                                    height: 30,
                                    lineHeight: "1.1em",
                                    margin: "5px 0",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {button?.label}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {/* <Row>
          <form>
            <div className="inputContainer">
              <label htmlFor="client-name">Filter Categories:</label>
              <input
                type="text"
                id="client-name"
                onChange={(e) => {}}
                placeholder="–"
              />
            </div>
          </form>
        </Row> */}
          {Object.entries(categories).map(([key, value]) => (
            <Row
              style={{
                padding: "10px 20px",
                borderTop: "1px solid #e0e7e7",
                marginTop: 0,
              }}
              className="playbookCriteriaRow"
            >
              {[-3, -2, -1, 0, 1, 2, 3].map((number) => (
                <Col>
                  <PlaybookButton
                    title={number}
                    selected={checkSelected(key, number as PlaybookPosition)}
                    onClick={() => {
                      updatePlaybook(key, number as PlaybookPosition);
                      setPlaybookIsZero(false);
                      setPlaybookMenuTitle("Custom Profile");
                    }}
                  />
                </Col>
              ))}
              <Col
                xs={12}
                sm={4}
                md={3}
                style={{ paddingTop: 15, paddingBottom: 8 }}
              >
                <p
                  style={{ fontWeight: "bold", fontSize: 16, marginBottom: 0 }}
                >
                  {screeningDefinition ===
                  (PLAYBOOK_DEFINITIONS as any)[key] ? (
                    <a
                      style={{
                        color: "rgb(6, 42, 73)",
                        textDecoration: "none",
                      }}
                      onClick={() => setScreeningDefinition("")}
                    >
                      {value}{" "}
                      <img
                        src={circleInfo}
                        width="16"
                        style={{ marginLeft: 2, marginTop: -4 }}
                      />
                    </a>
                  ) : (
                    <a
                      style={{
                        color: "rgb(6, 42, 73)",
                        textDecoration: "none",
                      }}
                      onClick={() => retrieveDefinition(key)}
                    >
                      {value}{" "}
                      <img
                        src={circleInfo}
                        width="16"
                        style={{ marginLeft: 2, marginTop: -4 }}
                      />
                    </a>
                  )}
                </p>
              </Col>
              {screeningDefinition === (PLAYBOOK_DEFINITIONS as any)[key] && (
                <Col xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <p style={{ fontSize: "1em", marginBottom: 0 }}>
                    {screeningDefinition}
                  </p>
                </Col>
              )}
            </Row>
          ))}
        </div>
      ) : (
        <>
          <div style={{}}>
            <Row style={{ backgroundColor: "#ffffff", padding: "20px 30px" }}>
              <Col xs={12}>
                <h2 style={{ textAlign: "center", marginBottom: 0 }}>
                  Thank you for completing your Resonate profile.
                </h2>
              </Col>
            </Row>
            <Row
              style={{
                padding: "20px 30px",
                display: "flex",
                alignContent: "center",
              }}
            >
              <Col xs={12}>
                {/* <h1
              style={{
                fontWeight: 700,
                fontSize: "2.5em",
                marginBottom: 10,
                color: "#062a49",
              }}
            >
              Let's get personal.
            </h1> */}
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    color: "#062a49",
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  Your financial advisor has been notified and will be in touch
                  with you soon.
                </p>
              </Col>
            </Row>
            <Row style={{ marginTop: 100 }}>
              <Col xs={12}>
                <a href="#">
                  <img
                    src={Logo}
                    style={{
                      width: 150,
                      margin: "0 auto",
                      display: "block",
                    }}
                  />
                </a>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}
