import { Copy } from "@styled-icons/feather/Copy";
import { WindowNew } from "@styled-icons/fluentui-system-regular/WindowNew";
import { Statistic, Table } from "antd";
import axios from "axios";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { auth, db, hostUrl } from "../../components/Firebase";
import { FirestoreUser, IClient } from "../../models";

const columns = [
  {
    title: "UID",
    dataIndex: "id",
    key: "uid",
    render: (id: string) => (
      <>
        <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(
              `https://console.firebase.google.com/u/0/project/scoreboard-87a49/firestore/data/~2Fusers~2F${id}`,
              "_newtab"
            );
          }}
        >
          <WindowNew style={{ width: 20 }} />
        </a>
        <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(id);
            toast.success("User ID copied to clipboard!", {autoClose: 1000 });
          }}
        >
          <Copy style={{ width: 20, marginLeft: 10 }} />
        </a>
      </>
    ),
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    key: "lastName",
    dataIndex: "firstName",
  },
  {
    title: "Firm",
    key: "firm",
    dataIndex: "firm",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Clients",
    key: "clients",
    dataIndex: "clients",
    render: (clients: IClient[]) => clients.length,
  },
  {
    title: "Portfolios",
    key: "portfolios",
    dataIndex: "clients",
    render: (clients: IClient[]) =>
      clients.reduce((acc, curr) => acc + curr.portfolios.length, 0),
  },
];

type FirebaseUserWithId = FirestoreUser & { id: string };

export const Admin = () => {
  const [views, setViews] = useState<"users" | "clients" | "portfolios">(
    "users"
  );

  // user view
  const [users, setUsers] = useState<FirebaseUserWithId[]>([]);

  // client view
  const [activeUser, setActiveUser] = useState<FirebaseUserWithId>();

  // portfolio view

  const getAllUsers = async () => {
    // fetch all users and place them into the firebase collection
    const querySnapshot = await getDocs(collection(db, "users"));
    setUsers(
      querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as FirebaseUserWithId)
      )
    );
  };

  const copyClient = (user: string, client: string) => {
    auth.currentUser &&
      auth.currentUser.getIdToken().then((token: string) => {
        console.log(token);
        axios
          .post(
            `${hostUrl}/copy-client`,
            {
              from_user: user,
              client_id: client,
            },
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          )
          .then(() => {
            toast.success("Client copied successfully (refresh your client list)", {autoClose: 1000 });
          })
          .catch(() => {
            toast.error("Error copying client!", { autoClose: 1000 });
          });
      });
    console.log(user, client);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const renderView = () => {
    switch (views) {
      case "users":
        return (
          <>
            <Row>
              <Col style={{ padding: 20 }}>
                <Statistic value={users.length} title="Accounts" />
              </Col>
              <Col style={{ padding: 20 }}>
                <Statistic
                  value={
                    users.filter((u) => u.email.includes("inspire")).length
                  }
                  title="Adjusted Accounts"
                />
              </Col>
              <Col style={{ padding: 20 }}>
                <Statistic
                  value={users.reduce(
                    (acc, curr) => acc + curr.clients.length,
                    0
                  )}
                  title="Clients"
                />
              </Col>
              <Col style={{ padding: 20 }}>
                <Statistic
                  value={users.reduce(
                    (acc, curr) =>
                      acc +
                      curr.clients.reduce(
                        (acc, curr) => acc + curr.portfolios.length,
                        0
                      ),
                    0
                  )}
                  title="Portfolios"
                />
              </Col>
            </Row>
            <Row>
              <Table
                columns={columns}
                dataSource={users}
                style={{ width: "100%" }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      setActiveUser(record as FirebaseUserWithId);
                      setViews("clients");
                    }, // click row
                  };
                }}
              />
            </Row>
          </>
        );
      case "clients":
        return (
          <>
            <Button
              onClick={() => {
                setActiveUser(undefined);
                setViews("users");
              }}
              style={{ cursor: "pointer" }}
            >
              Back
            </Button>
            {activeUser && (
              <>
                <h1>
                  {activeUser.firstName} {activeUser.lastName}
                </h1>
                <h3>Clients</h3>
                <Table
                  dataSource={activeUser.clients}
                  columns={[
                    {
                      title: "First Name",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Last Name",
                      dataIndex: "lastName",
                      key: "name",
                    },
                    {
                      title: "Email",
                      dataIndex: "email",
                      key: "email",
                    },
                    {
                      title: "# Portfolios",
                      dataIndex: "portfolios",
                      key: "portfolios",
                      render: (portfolios: any[]) => portfolios.length,
                    },
                    {
                      title: "Actions",
                      dataIndex: "id",
                      key: "id",
                      render: (id) => (
                        <>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              copyClient(activeUser.id, id);
                            }}
                          >
                            <Copy style={{ width: 20 }} />
                          </a>
                        </>
                      ),
                    },
                  ]}
                />
              </>
            )}
          </>
        );
      case "portfolios":
        return <></>;
    }
  };

  return <div style={{ padding: 30 }}>{renderView()}</div>;
};
