import { Row, Col } from "react-flexbox-grid";
import Modal from "../../Modal";
import LoadingIcon from "../../../images/dual-ring-loading.svg";

interface LoadingModalProps {
  message?: any;
  isActive: boolean;
}

export function LoadingModal(props: LoadingModalProps) {
  return (
    <Modal isActive={props.isActive} removeClose={true}>
      <Row>
        <Col xs={12} style={{ textAlign: "center" }}>
          <img width="80" style={{ marginBottom: 10 }} src={LoadingIcon} />
          {typeof props.message === "string" ? (
            <h2 style={{}}>{props.message ?? "Loading"}</h2>
          ) : (
            <>{props.message}</>
          )}
        </Col>
      </Row>
    </Modal>
  );
}
