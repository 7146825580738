import { Col, Row } from "react-flexbox-grid";
import { CHRISTIAN } from "../../constants";
import {
  IEsgCriterionRollup,
  IInstrumentDetails,
} from "../../models/Insight/api";
import { checkPolarity } from "./CompanyIssues";
import { Plus as PlusSmall } from "@styled-icons/fa-solid/Plus";
import { Minus } from "@styled-icons/fa-solid/Minus";
import { useState } from "react";
import axios from "axios";
import ViewIssues from "../Resonate/Client/ViewIssues";
import { insightHostUrl } from "../Firebase";

interface CompositeIssuesProps {
  details: IInstrumentDetails;
  id: number;
}

export default function CompositeIssues({ details, id }: CompositeIssuesProps) {
  const [contributors, setContributors] = useState<any[]>([]);
  const [activeEsgIssue, setActiveEsgIssue] = useState<string>();
  const [esgIssues, setEsgIssues] = useState<any[]>([]);
  const [viewCount, setViewCount] = useState<number>(10);
  const [activeTicker, setActiveTicker] = useState<any>();

  const fetchConributors = (issue: IEsgCriterionRollup) => {
    axios
      .get(
        `${insightHostUrl}/api/tickers/${id}/esg-issues/scoreboard/${issue.criterion.name}/contributors`
      )
      .then((response) => {
        setEsgIssues([]);
        setActiveTicker("");
        setContributors(response.data);
        setActiveEsgIssue(issue.criterion.name);
      });
  };

  const fetchTickerDetails = (ticker: any) => {
    axios
      .get(
        `${insightHostUrl}/api/tickers/${ticker.financialInstrumentId}/esg-issues/scoreboard/${activeEsgIssue}?page=0&&size=50`
      )
      .then((response) => {
        setActiveTicker(ticker.ticker);
        setEsgIssues(response.data);
      });
  };

  return (
    <>
      {details.esgIssueCriteriaRollup &&
        details.esgIssueCriteriaRollup
          .sort((a: IEsgCriterionRollup, b: IEsgCriterionRollup) => {
            const scoreA = (CHRISTIAN as any)[a.criterion.name];
            const scoreB = (CHRISTIAN as any)[b.criterion.name];
            return scoreA - scoreB;
          })
          .map((issue: IEsgCriterionRollup, index) => (
            <div className="issueRow">
              <Row
                onClick={() => {
                  if (activeEsgIssue === issue.criterion.name) {
                    setActiveEsgIssue("");
                    setContributors([]);
                    setActiveTicker("");
                  } else {
                    fetchConributors(issue);
                  }
                }}
              >
                <Col>
                  <div className="pointScore">
                    <i
                      className={
                        checkPolarity(issue) === "negative"
                          ? "pointNegative"
                          : "pointPositive"
                      }
                    >
                      {checkPolarity(issue) === "negative" ? (
                        <Minus size="10" />
                      ) : (
                        <PlusSmall size="10" />
                      )}
                    </i>
                    <span className="issueCategoryClick">
                      {issue.criterion.title}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                {activeEsgIssue === issue.criterion.name && (
                  <ul className="screeningTickers">
                    {contributors.slice(0, viewCount).map((item) => (
                      <li
                        className="ticker"
                        key={item.id}
                        style={{ marginTop: 15, marginBottom: 5 }}
                      >
                        <a
                          className={
                            activeTicker === item.ticker
                              ? "activeIssueTicker"
                              : ""
                          }
                          onClick={() => fetchTickerDetails(item)}
                        >
                          {item.ticker}
                        </a>
                      </li>
                    ))}
                    {contributors.length > 10 && (
                      <li
                        className="ticker"
                        style={{ marginTop: 15, marginBottom: 5 }}
                      >
                        <a
                          onClick={() => {
                            setViewCount(
                              viewCount === 10 ? contributors.length : 10
                            );
                          }}
                        >
                          View {viewCount === 10 ? "More" : "Less"}
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </Row>
              <Row>
                <Col xs={12}>
                  {activeEsgIssue === issue.criterion.name &&
                    esgIssues.length > 0 && (
                      <ViewIssues issues={esgIssues} ticker={activeTicker} />
                    )}
                </Col>
              </Row>
            </div>
          ))}
    </>
  );
}
