import { useEffect, useState } from "react";
import { IFundHoldingApi } from "../../models/Insight/api";
import axios from "axios";
import { Table } from "antd";
import ImpactScore from "./ImpactScore";
import { LoadingOutlined } from "@ant-design/icons";
import Loader from "../../components/Insight/Loader";
import { useNavigate } from "react-router";
import { insightHostUrl } from "../Firebase";

let columns = [
  {
    title: "Ticker",
    dataIndex: "ticker",
    key: "ticker",
    sorter: (a: IFundHoldingApi, b: IFundHoldingApi) =>
      a.ticker.localeCompare(b.ticker),
    hideOnMobile: false,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: IFundHoldingApi, b: IFundHoldingApi) =>
      a.name.localeCompare(b.name),
    hideOnMobile: false,
  },
  {
    title: "Impact Score",
    dataIndex: "score",
    key: "score",
    sorter: (a: IFundHoldingApi, b: IFundHoldingApi) => a.score - b.score,
    render: (score: number, _: any, index: number) => (
      <ImpactScore key={Math.random() * 10000} score={score} type="small" />
    ),
    hideOnMobile: false,
  },
  {
    title: "Asset %",
    dataIndex: "holdingPercentage",
    key: "holdingPercentage",
    render: (percentage: number) =>
      percentage ? percentage.toFixed(2) + "%" : "N/A",
    sorter: (a: IFundHoldingApi, b: IFundHoldingApi) =>
      a.holdingPercentage - b.holdingPercentage,
    hideOnMobile: true,
  },
];

window.innerWidth < 768 && columns.splice(3, 1);

interface HoldingsProps {
  id: number;
  negativeExposureCb?: (num: number) => void;
}

export default function Holdings({ id, negativeExposureCb }: HoldingsProps) {
  const [holdings, setHoldings] = useState<IFundHoldingApi[]>([]);
  const [reqSent, setReqSent] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${insightHostUrl}/api/tickers/${id}/constituents?size=10000`)
      .then((response: any) => {
        let responseData: IFundHoldingApi[] = response.data.holdings;
        if (negativeExposureCb) {
          negativeExposureCb(
            responseData
              .filter((holding: IFundHoldingApi) => holding.score < 0)
              .map((holding: IFundHoldingApi) => holding.holdingPercentage)
              .reduce((a: number, b: number) => a + b, 0)
          );
        }
        setHoldings(responseData);
        console.log(responseData);
        setReqSent(true);
      });
  }, []);

  return (
    <>
      {reqSent ? (
        <>
          <div
            style={{
              width: "100%",
              height: 54,
              backgroundColor: "#2C8AAF",
              padding: 12,
            }}
          >
            <h3
              style={{
                fontWeight: 700,
                color: "white",
              }}
            >
              Holdings
            </h3>
          </div>
          <Table
            columns={columns}
            dataSource={holdings}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  navigate("/" + record.ticker);
                  window.location.reload();
                },
              };
            }}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
