import React, { useState, useEffect } from "react";
import ThemeContext, { themeState } from "./ThemeContext";

const ThemeProvider = ({ children }: { children: any }) => {
  const [theme, setTheme] = useState(themeState.theme);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme--${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
