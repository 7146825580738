import { useState } from "react";
import ChevronDown from "../images/chevron-down.svg";
import Search from "../images/search-icon-black.svg";
import Button from "../components/Button";
import { isMobile, isTablet } from "react-device-detect";
interface SelectMenuProps {
  title: string;
  persisentTitle?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
  className?: string;
  activeState?: string | any;

  // multiple select needs an array of string values
  multiTitles?: string[];
  setMultiTitles?: (arg0: string[]) => void;

  options: Array<{
    title: string;
    value: string;
    name?: string;
    positions?: any;
    clientCreated?: boolean;
    id?: string;
  }>;
  onClick?: (
    value: string,
    title?: string,
    name?: string,
    positions?: any,
    clientCreated?: boolean,
    id?: string
  ) => void;

  onSearch?: (value: string) => void;
}

export default function SelectMenu(props: SelectMenuProps) {
  const [viewMenu, setViewMenu] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(props.title);
  const [searchActive, setSearchActive] = useState(false);

  const showFullWidth = () => {
    if (props.fullWidth) {
      return { fullWidth: true };
    }
  };

  const renderActiveTitle = (value: string, title?: string) => {
    switch (true) {
      case props.multiTitles && props.multiTitles?.includes(value):
        return "active selected";
        break;
      case (!props.multiTitles &&
        props.activeState?.toString().toLowerCase() === value) ||
        props.activeState?.toString() === value ||
        props.activeState?.toString() === title:
        return "active";
        break;
      default:
        return "";
    }
  };

  const handleSelection = (option: any) => {
    if (option.value === "--") return false;
    if (!props.persisentTitle)
      if (props.multiTitles && props.setMultiTitles) {
        let updatedTitles = props.multiTitles;

        if (updatedTitles.includes(option.value)) {
          updatedTitles = updatedTitles.filter(
            (title) => title !== option.value
          );

          props.setMultiTitles([...(updatedTitles as any)]);
        } else {
          updatedTitles.push(option.value);

          props.setMultiTitles([...(updatedTitles as any)]);
        }
      } else {
        setSelectedTitle(option.title);
      }
    props.onClick &&
      props.onClick(
        option.value,
        option.title,
        option.name,
        option.positions,
        option.clientCreated,
        option.id
      );
    props.onSearch && props.onSearch("");
    if (!props.multiTitles) {
      setViewMenu(false);
      setSearchActive(false);
    }
  };

  return (
    <div
      className={
        props.multiTitles
          ? "selectMenuContainer multiTitles"
          : "selectMenuContainer" +
            (props.className ? " " + props.className : "")
      }
      style={{
        width: props.fullWidth ? "100%" : "auto",
      }}
      onMouseDown={() => {
        if (!isMobile || !isTablet)
          !viewMenu
            ? setViewMenu(true)
            : props.onSearch && setSearchActive(true);
      }}
      onMouseLeave={() => {
        setViewMenu(false);
        setSearchActive(false);
      }}
    >
      <div
        onTouchStart={() => {
          if (viewMenu) {
            setViewMenu(false);
          } else {
            props.onSearch && setSearchActive(false);
            setViewMenu(true);
          }
        }}
      >
        {searchActive && !isMobile && !isTablet ? (
          <input
            type="text"
            onChange={(e) => props.onSearch && props.onSearch(e.target.value)}
          ></input>
        ) : props.onSearch && !isMobile && !isTablet && viewMenu ? (
          <Button
            disabled={props.disabled}
            preset="selectMenuButton"
            stickyHover={viewMenu}
            leftAlign
            {...showFullWidth()}
          >
            Click to search
            <img
              src={Search}
              width="12"
              style={{
                position: "absolute",
                right: 20,
                top: 14,
              }}
            />
          </Button>
        ) : (
          <Button
            disabled={props.disabled}
            preset="selectMenuButton"
            stickyHover={viewMenu}
            leftAlign
            {...showFullWidth()}
            onClick={() => props.onSearch && setSearchActive(true)}
          >
            {props.multiTitles && props.multiTitles.length > 0
              ? `${selectedTitle + " (" + props.multiTitles.length + ")"}`
              : !props.persisentTitle
              ? selectedTitle
              : props.persisentTitle}
            <img
              src={ChevronDown}
              width="12"
              style={{
                position: "absolute",
                right: 20,
                top: 14,
              }}
            />
          </Button>
        )}
      </div>
      {!viewMenu ? (
        <></>
      ) : (
        <ul>
          {props.options.map((option) => (
            <li
              key={option.value}
              className={renderActiveTitle(option.value, option.title)}
            >
              <a onClick={() => handleSelection(option)}>{option.title}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
