import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../../images/Insight/insight-logo.svg";
import Button from "../../components/Button";

import { Row, Col } from "react-flexbox-grid";
import { auth, insightHostUrl } from "../../components/Firebase";
import { User } from "firebase/auth";

import TickerSearch from "../../components/Insight/TickerSearch";
import Header from "../../components/Insight/Header";
import TickerList from "../../components/Insight/TickerList";
import Footer from "../../components/Insight/Footer";
import Loader from "../../components/Insight/Loader";

function InsightHome() {
  const [allRankedStocks, setAllRankedStocks] = useState<any>([]);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    axios.get(`${insightHostUrl}/api/ranked`, {}).then((response) => {
      setAllRankedStocks(response.data);
      setReady(true);
    });
  }, []);

  return (
    <>
      <Header isHome={true} />
      <div
        id="mainContent"
        className="fullWidthContainer homeContainer"
        style={{ marginTop: 50 }}
      >
        <div className="fullWidthContent">
          <Row>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: 18,
              }}
            >
              <img src={Logo} alt="Insight Logo" width="300" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6} lgOffset={3} md={8} mdOffset={2}>
              <TickerSearch />
              <p style={{ textAlign: "center", marginTop: 18 }}>
                Biblical values data on <strong>40,858</strong> tickers
              </p>
            </Col>
          </Row>
          {ready ? (
            <>
              <Row style={{ marginTop: 50 }}>
                <Col lg={3} md={6}>
                  <TickerList
                    listTitle="Inspire Funds"
                    tickers={allRankedStocks.inspireFunds}
                  />
                </Col>
                <Col lg={3} md={6}>
                  <TickerList
                    listTitle="Trending Issue: Abortion Travel"
                    tickers={allRankedStocks.trendingIssue}
                  />
                </Col>
                <Col lg={3} md={6}>
                  <TickerList
                    listTitle="Top Scoring Stocks"
                    tickers={allRankedStocks.topStocks}
                  />
                </Col>
                <Col lg={3} md={6}>
                  <TickerList
                    listTitle="Worst Scoring Stocks"
                    tickers={allRankedStocks.worstStocks}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Loader mini={true} />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InsightHome;
