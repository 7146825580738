import { isMobile, isTablet } from "react-device-detect";

interface PlaybookButtonProps {
  onClick: () => void;
  title: number;
  wordTitle?: string;
  selected: boolean;
  style?: React.CSSProperties;
}
export function PlaybookButton(props: PlaybookButtonProps) {
  const { onClick, title, selected, wordTitle } = props;

  const renderClassName = () => {
    let className = "playbookButton";
    if (wordTitle === "Include" || title > 0) {
      className += " positivePlaybookButton";
    }
    if (selected) {
      className += " selected";
    }

    return className;
  };

  const universalStyles = {
    borderRadius: wordTitle ? 12 : "50%",
    width: wordTitle ? 75 : 33,
    height: wordTitle ? 30 : 33,
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    marginRight: isMobile && !isTablet ? 10 : 15,
    display: "flex",
    fontWeight: 700,
    paddingTop: 1,
    cursor: "pointer",
    border: selected ? "2px solid #f5f5f5" : "none",
  };

  const negativeStyles = {
    backgroundColor: selected ? "#bd425a" : "#f2d9de",
    boxShadow: "#bd425a 0px 0px 0px 3px",
    color: selected ? "white" : "#bd425a",
  };

  const neutralStyles = {
    backgroundColor: selected ? "#b1b1b1" : "white",
    boxShadow: "#b1b1b1 0px 0px 0px 3px",
    color: selected ? "white" : "#b1b1b1",
  };

  return (
    <div
      onClick={onClick}
      style={{
        userSelect: "none",
        ...universalStyles,
        ...{ ...(title < 0 && negativeStyles) },
        ...{ ...(title === 0 && neutralStyles) },
        ...{ ...(wordTitle === "Exclude" && negativeStyles) },
        ...{ ...(wordTitle === "Off" && neutralStyles) },
        ...props.style,
      }}
      // add a string of " selected" to this className object if selected is true
      className={renderClassName()}
    >
      {wordTitle ? wordTitle : title}
    </div>
  );
}
