import { IUser } from '../../models';
import { SET } from './user.types';

const INITIAL_STATE: {} | IUser = {} 

const reducer = (state = INITIAL_STATE, action: any) => {
  switch(action.type) {
    case SET: 
      break;
    default:
      return INITIAL_STATE
  }
}

export default reducer;