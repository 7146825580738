import { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import { IClient, Portfolio } from "../../../models";
import Button from "../../Button";
import Modal from "../../Modal";
import { PortfolioTemplate } from "../../Portfolios";
import SelectMenu from "../../SelectMenu";

interface CreateModal {
  template: PortfolioTemplate;
  isActive: boolean;
  onClose: () => void;
  clients: IClient[];
  activeModel?: Portfolio;
  createPortfolio: () => void;
  setActiveClient: (client: IClient) => void;
}
export default function CreateModal(props: CreateModal) {
  const { template, isActive, onClose, clients, activeModel } = props;
  const [client, setClient] = useState<IClient>();

  return (
    <Modal isActive={isActive} onClose={onClose}>
      <h2>New {template} from model</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12}>
            <div className="inputContainer">
              <SelectMenu
                title="Select Client"
                fullWidth
                onClick={(clientId) => {
                  let client = clients.find((c) => c.id === clientId);
                  if (client) {
                    props.setActiveClient(client);
                  }
                }}
                options={clients.map((client) => ({
                  title: client.name,
                  value: client.id,
                }))}
              />
            </div>
          </Col>
        </Row>
      </form>
      <Row>
        <Col xs={12} sm={6}>
          <Button fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            preset="formPrimary"
            fullWidth
            onClick={props.createPortfolio}
          >
            Create
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
