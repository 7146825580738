import { useState, useEffect } from "react";
import { IClient, IPlaybook, IUser, Model, Portfolio } from "../../models";
import { Row, Col } from "react-flexbox-grid";
import { ClientView } from "./Home";
import { PlaybookPosition } from "../../models/Resonate/playbook";

import { db } from "../../components/Firebase";
import Portfolios, { PortfolioTemplate } from "../../components/Portfolios";
import { toast } from "react-toastify";
import { collection, onSnapshot } from "firebase/firestore";
import SelectMenu from "../../components/SelectMenu";
import { forEachChild } from "typescript";
import PREBUILT_PLAYBOOKS from "../../constants";
import LoadingIcon from "../../images/dual-ring-loading.svg";
import Button from "../../components/Button";
import { Plus } from "styled-icons/feather";
import { CreatePortfolioModal } from "../../components/Resonate/Home/CreatePortfolioModal";

interface FundPageProps {
  user: IUser;
  view: ClientView;
  refreshUser: () => void;
  activePlaybook: IPlaybook | null;
  activeClient?: IClient | null;
  setActiveClient: (client: IClient) => void;
  updatePlaybook: (
    category: string,
    value: PlaybookPosition,
    playbookMenuTitle: string,
    clientCreatedProfileId?: string,
    prebuiltProfile?: {}
  ) => void;
  handleViewChange: (view: ClientView) => void;
  resetPlaybook: () => void;
  updateClient: (client: IClient) => void;
  handlePlaybookSave: (
    playbookMenuTitle: string,
    isClientCreatedPlaybook: boolean
  ) => void;
  fetchClients: () => void;
  setEditUserModalVisible: (arg0: boolean) => void;
  setActivePlaybook: (playbook: IPlaybook) => void;
  portfoliosReady: boolean;
  setPortfoliosReady: (arg0: boolean) => void;
  setClientScore: (arg0: number) => void;
  setClientRatio: (arg0: number) => void;
  toastIds: any;
  setToastIds: (arg0: {}) => void;
  displayRatio: (arg0: number) => string;
  setPlaybookVisible: (arg0: boolean) => void;
  playbookVisible: boolean;
  setEditModalVisible: (arg0: boolean) => void;
  editModalVisible: boolean;
  deleteConfirmVisible: boolean;
  setDeleteConfirmVisible: (arg0: boolean) => void;
  query: string;
  handleClientSelection: (arg0: IClient, arg1?: string) => void;
}

export default function Funds(props: FundPageProps) {
  const [createPortfolioModalVisible, setCreatePortfolioModalVisible] =
    useState<boolean>(false);

  const [reports, setReports] = useState<Array<any>>([]);
  const [models, setModels] = useState<Array<Model>>([]);
  const [filteredModelTags, setFilteredModelTags] = useState<string[]>([]);
  const [filteredModels, setFilteredModels] = useState<Array<Model>>([]);
  const [stateRunner, setStateRunner] = useState<number>(0);
  const [clientFilter, setClientFilter] = useState("");
  const [sortByAlignmentOrScore, setSortByAlignmentOrScore] =
    useState<boolean>(false);

  const [isReady, setIsReady] = useState<boolean>(false);

  //filters
  const [sortMethod, setSortMethod] = useState<string>("name");

  const {
    user,
    handleViewChange,
    handlePlaybookSave,
    updatePlaybook,
    resetPlaybook,
    fetchClients,
    setEditUserModalVisible,
    activePlaybook,
    setActivePlaybook,
    toastIds,
    setToastIds,
    displayRatio,
    setPlaybookVisible,
    playbookVisible,
    // setEditModalVisible,
    // editModalVisible,
    // deleteConfirmVisible,
    // setDeleteConfirmVisible,
  } = props;

  useEffect(() => {
    refreshModels();
  }, []);

  useEffect(() => {
    refreshModels();
  }, [props.user]);

  useEffect(() => {
    if (Object.keys(toastIds).length > 0) {
      let sortedReports = reports.sort((a: any, b: any) => {
        if (a.completedDate < b.completedDate) {
          return 1;
        }
        if (a.completedDate > b.completedDate) {
          return -1;
        }

        return 0;
      });

      const link = document.createElement("a");
      link.href = sortedReports[0].downloadUrl;
      link.setAttribute("download", sortedReports[0].fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      let toastToDismiss = sortedReports[0].requestId;
      toast.dismiss(toastIds[toastToDismiss]);

      let copy = { ...toastIds };
      delete copy[toastToDismiss];
      setToastIds(copy);
    }
  }, [reports]);

  // useEffect(() => {
  //   if (props.query !== "") {
  //     let updatedModels = models.filter((model: any) => {
  //       return model.name.toLowerCase().includes(props.query.toLowerCase());
  //     });
  //     setFilteredModels(updatedModels);
  //   } else {
  //     setFilteredModels(models);
  //   }
  // }, [props.query]);

  useEffect(() => {
    let updatedModels: any = [];

    if (filteredModelTags.length > 0) {
      models.map((model: any) => {
        filteredModelTags.forEach((tag: string) => {
          if (
            model.tags.includes(tag) &&
            updatedModels.find((m: any) => m.id === model.id) === undefined
          ) {
            updatedModels.push(model);
          }
        });
      });
      setFilteredModels([...(updatedModels as Array<Model>)]);
    } else {
      setFilteredModels([...(models as Array<Model>)]);
    }
  }, [filteredModelTags]);

  const refreshModels = () => {
    onSnapshot(collection(db, `funds`), (snapshot) => {
      setModels([...snapshot.docs.map((doc) => doc.data() as Model)]);
      const models: Model[] = snapshot.docs.map((doc) => doc.data() as Model);
      setFilteredModels([...models]);
    });
  };

  return (
    <>
      {filteredModels.length > 0 ? (
        <>
          <div className="fullWidthContainer clientPagination whiteBg">
            <CreatePortfolioModal
              isActive={createPortfolioModalVisible}
              onClose={() => {
                props.refreshUser();
                setCreatePortfolioModalVisible(false);
              }}
              client={props.activeClient}
              template={PortfolioTemplate.MODEL}
            />
            {/*
      <CreateClientModal
        isActive={editModalVisible}
        onClose={(user: IClient) => {
          setEditModalVisible(false);
          if (user) props.updateClient(user);
        }}
        client={client}
        edit
      />
      <Confirm
        isActive={deleteConfirmVisible}
        onClose={() => setDeleteConfirmVisible(false)}
        onConfirm={() =>
          doDeleteClient(client, fetchClients).then(() => {
            handleViewChange(ClientView.CLIENT_MANAGEMENT);
          })
        }
        message="Are you sure you want to delete this client?"
      />*/}
            <div className="fullWidthContent">
              <Row style={{ marginLeft: 0 }}>
                {/* <Col xs={6} md={3}>
              &nbsp;
            </Col>
            <Col
              xs={sortByAlignmentOrScore ? 3 : 4}
              md={sortByAlignmentOrScore ? 2 : 3}
              className="hideOnMobile"
            >
              &nbsp;
            </Col>

            {props.view == ClientView.CLIENT_MODELS && (
              <Col xs={6} md={2} lg={2} className="hideOnMobile">
                &nbsp;
              </Col>
            )}

            <Col xs={6} md={2} lg={2} className="hideOnMobile">
              {/* {props.query === "" && (
                <SelectMenu
                  multiTitles={filteredModelTags}
                  setMultiTitles={setFilteredModelTags}
                  title="Filter By Tag"
                  fullWidth
                  options={[
                    { title: "Aggressive", value: "aggressive" },
                    { title: "Conservative", value: "conservative" },
                    { title: "Moderate", value: "moderate" },
                    { title: "My Models", value: "my-models" },
                  ]}
                />
              )} 
            </Col> */}
                {props.view !== ClientView.CLIENT_MODELS &&
                  props.view !== ClientView.CLIENT_FUNDS && (
                    <Col
                      xsOffset={6}
                      xs={6}
                      mdOffset={10}
                      md={2}
                      className="hideOnMobile"
                    >
                      <SelectMenu
                        title="View As"
                        onSearch={setClientFilter}
                        fullWidth
                        activeState={props.activeClient?.id}
                        onClick={(value: string) => {
                          if (
                            ["christian", "environmental", "catholic"].includes(
                              value
                            )
                          ) {
                            const client = props.activeClient;
                            const playbook = PREBUILT_PLAYBOOKS[value];
                            if (client) {
                              props.setActiveClient({
                                ...client,
                                playbooks: [
                                  {
                                    positions: playbook,
                                    dateSaved: new Date(),
                                    playbookName: value,
                                    clientCreated: false,
                                    active: true,
                                    id: "prebuilt",
                                  },
                                ],
                              });
                              props.setActivePlaybook({
                                positions: playbook,
                                dateSaved: new Date(),
                                playbookName: value,
                                clientCreated: false,
                                active: true,
                                id: "prebuilt",
                              });
                            } else {
                              props.setActivePlaybook({
                                positions: playbook,
                                dateSaved: new Date(),
                                playbookName: value,
                                clientCreated: false,
                                active: true,
                                id: "prebuilt",
                              });
                              props.setActiveClient({
                                id: "test",
                                name: "Hello",
                                email: "hello@gmail.com",
                                playbooks: [
                                  {
                                    positions: playbook,
                                    dateSaved: new Date(),
                                    playbookName: value,
                                    clientCreated: false,
                                    active: true,
                                    id: "prebuilt",
                                  },
                                ],
                                portfolios: filteredModels,
                                dateCreated: new Date(),
                              });
                            }
                            setStateRunner(Math.random() * 1000);
                          } else {
                            console.log("view as client");
                            let client = user.clients.find(
                              (c) => c.id === value
                            );
                            if (client && props.setActiveClient) {
                              props.setActiveClient(client);
                              let ap = client.playbooks.find((p) => p.active);
                              if (ap) {
                                props.setActivePlaybook(ap);
                              }
                              setStateRunner(Math.random() * 1000);
                            }
                          }
                        }}
                        options={[
                          ...[
                            {
                              title: "Christian (Catholic USCCB)",
                              value: "catholic",
                            },
                            {
                              title: "Christian (Protestant)",
                              value: "christian",
                            },
                            {
                              title: "Environmental",
                              value: "environmental",
                            },
                            {
                              title: "--",
                              value: "--",
                            },
                          ],
                          ...user.clients
                            .filter((client) =>
                              client.name
                                .toLowerCase()
                                .includes(clientFilter.toLowerCase())
                            )
                            .map((client) => ({
                              title: client.name,
                              value: client.id,
                            })),
                        ]}
                      />
                    </Col>
                  )}
                {sortByAlignmentOrScore && (
                  <Col xs={6} md={1} lg={1} className="hideOnMobile">
                    <SelectMenu
                      title="%"
                      fullWidth
                      onClick={setSortMethod}
                      options={[
                        { title: "10%+", value: "10%" },
                        { title: "30%+", value: "30%" },
                        { title: "70%+", value: "70%" },
                      ]}
                    />
                  </Col>
                )}
              </Row>
              {/* <ContextMenu
          icon={Elipsis}
          iconWidth="20"
          onClick={(value: string) => {}}
          options={user.clients.map((client) => ({
            title: client.name,
            value: client.id,
          }))}
        /> */}
            </div>
          </div>

          <div className="fullWidthContainer whiteBg">
            <Portfolios
              models={filteredModels}
              key={stateRunner}
              refreshUser={props.refreshUser}
              setActiveClient={props.setActiveClient}
              handleClientSelection={props.handleClientSelection}
              template={PortfolioTemplate.FUND}
              //models specific props
              sortMethod={sortMethod}
              //back to normal props
              activePlaybook={activePlaybook}
              setActivePlaybook={setActivePlaybook}
              setPlaybookVisible={setPlaybookVisible}
              updatePlaybook={updatePlaybook}
              resetPlaybook={resetPlaybook}
              handlePlaybookSave={handlePlaybookSave}
              playbookVisible={playbookVisible}
              displayRatio={displayRatio}
              onClick={(modalVisible: boolean) =>
                setCreatePortfolioModalVisible(modalVisible)
              }
              client={
                props.activeClient
                  ? {
                      ...props.activeClient,
                      temporaryPortfolios: props.activeClient.portfolios,
                      portfolios: filteredModels,
                    }
                  : {
                      id: "test",
                      name: "Hello",
                      email: "hello@gmail.com",
                      playbooks: [],
                      portfolios: filteredModels,
                      dateCreated: new Date(),
                    }
              }
              user={user}
              view={props.view}
              createPortfolioModalOpen={createPortfolioModalVisible}
              setCreatePortfolioModalOpen={(state) =>
                setCreatePortfolioModalVisible(state)
              }
              portfoliosReady={props.portfoliosReady}
              setPortfoliosReady={props.setPortfoliosReady}
              setClientScore={props.setClientScore}
              setClientRatio={props.setClientRatio}
              setEditUserModalVisible={setEditUserModalVisible}
              toastIds={props.toastIds}
              setToastIds={props.setToastIds}
              query={props.query}
            />
          </div>
        </>
      ) : (
        <div className="fullWidthContainer" id="mainContent">
          <div className="fullWidthContent">
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  width="50"
                  style={{ padding: "4px 0" }}
                  src={LoadingIcon}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
