import { Edit } from "@styled-icons/feather/Edit";
import { Trash } from "@styled-icons/feather/Trash";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../components/Button";
import { auth, db, doFetchUser, doUpdateUser } from "../../components/Firebase";
import Hero from "../../components/Hero";
import Header from "../../components/Resonate/Header";
import { EditUserModal } from "../../components/Resonate/Home/EditUserModal";
import { LoadingModal } from "../../components/Resonate/Home/LoadingModal";
import { themeState } from "../../contexts/ThemeContext";
import { IClient, INotification, IPlaybook, IUser } from "../../models";
import { PlaybookPosition } from "../../models/Resonate/playbook";
import ExportData from "../Insight/ExportData";
import { Admin } from "./Admin";
import Client from "./Client";
import ClientManagement from "./ClientManagement";
import Funds from "./Funds";
import Models from "./Models";
import Reports from "./Reports";

export enum ClientView {
  CLIENT_PAGE = 0,
  CLIENT_MANAGEMENT = 1,
  REPORTS = 2,
  MODELS = 3,
  CLIENT_MODELS = 4,
  PROPOSALS = 5,
  FUNDS = 6,
  CLIENT_FUNDS = 7,
  ADMIN = 8,
  CLIENT_INSIGHT_REPORT = 9,
  EXPORT_DATA = 10,
}

export default function Home() {
  const [user, setUser] = useState<IUser | null>(null);
  const [view, setView] = useState<ClientView>(ClientView.CLIENT_MANAGEMENT);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [activeClient, setActiveClient] = useState<IClient | null>(null);
  const [activePlaybook, setActivePlaybook] = useState<IPlaybook>({
    positions: {},
    dateSaved: new Date(),
    playbookName: "",
    clientCreated: false,
    active: false,
  } as IPlaybook);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Array<INotification>>([]);
  const [updated, setUpdate] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [clientScore, setClientScore] = useState(0);
  const [clientRatio, setClientRatio] = useState(0);
  const [portfoliosReady, setPortfoliosReady] = useState(false);
  const [toastIds, setToastIds] = useState<any>({});
  const [playbookVisible, setPlaybookVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);

  const { urlClientId, urlPortfolioId } = useParams<{
    urlClientId: string;
    urlPortfolioId: string;
  }>();

  useEffect(() => {
    themeState.theme === "resonate"
      ? (document.title = "Resonate")
      : (document.title = "Inspire Insight");

    const url = new URL(window.location.href);

    // if url has clientId and portfolioId, fetch user and set activeClient to automatically show the client page
    if (urlClientId !== undefined) {
      doFetchUser().then((user: IUser | null) => {
        user && setUser({ ...user });
        const client = user?.clients.find((c) => c.id === urlClientId);
        setActiveClient(client as IClient);
        if (url.pathname.includes("/insight-report")) {
          setView(ClientView.CLIENT_INSIGHT_REPORT);
        } else if (url.pathname.includes("/models")) {
          setView(ClientView.CLIENT_MODELS);
        } else {
          setView(ClientView.CLIENT_PAGE);
        }
      });
    } else if (url.pathname.includes("/models")) {
      setView(ClientView.MODELS);
    }
  }, []);

  const handleFilter = (filterQuery: string) => {
    setQuery(filterQuery.replace(/[^\w\s]/gi, ""));
  };

  const fetchClients = () => {
    doFetchUser()
      .then((user: IUser | null) => {
        user && setUser({ ...user });
      })
      .catch(() => {
        toast.warning("Clients could not be fetched");
      });
  };

  useEffect(() => {
    notifications.length === 0 && setNotificationsOpen(false);
  }, [notifications]);

  if (auth.currentUser) {
    onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
      let userData: IUser = doc.data() as IUser;
      if (userData.notifications.length != notifications.length) {
        if (
          userData.notifications.length > notifications.length &&
          updated < 0
        ) {
          toast.success(userData.notifications[userData.notifications.length - 1].body,
            {
              toastId:userData.notifications[userData.notifications.length - 1].body,
              autoClose: 1000 
            }
          );
        }
        setNotifications(userData.notifications);
        setUpdate(updated + 1);
      }
    });
  }

  const addClient = (client: IClient) => {
    user && setUser({ ...user, clients: user.clients.concat(client) });
    setActiveClient(client);
    setView(ClientView.CLIENT_PAGE);
  };

  const findAndSetActiveClient = (clientId: string) => {
    // find client in user.clients by clientId and set as activeClient
    let client = user?.clients.find((c) => c.id === clientId);
    setActiveClient(client as IClient);
    setView(ClientView.CLIENT_PAGE);
  };

  const handleClientSelection = (client: IClient, view?: string) => {
    setActiveClient(client);
    let thePlaybook = client.playbooks.find(
      (playbook) => playbook.active === true
    );
    setActivePlaybook(thePlaybook as IPlaybook);
    view && view === "proposals"
      ? setView(ClientView.PROPOSALS)
      : setView(ClientView.CLIENT_PAGE);
  };

  const displayRatio = (ratio: number): string => {
    ratio = Math.abs(ratio);

    if (ratio === -0 || ratio === 0) {
      return "0";
    } else if (isNaN(ratio)) {
      return "N/A";
    } else {
      return ratio.toFixed(2).toString();
    }
  };

  useEffect(() => {
    if (!user) {
      fetchClients();
    }
  });

  useEffect(() => {
    let updatedClient =
      user && user.clients.find((c) => c.id === activeClient?.id);
    setActiveClient(updatedClient as IClient);
  }, [user]);

  useEffect(() => {
    let thePlaybook = activeClient?.playbooks.find(
      (playbook) => playbook.active === true
    );
    setActivePlaybook(thePlaybook as IPlaybook);
  }, [activeClient]);

  useEffect(() => {
    setQuery("");
  }, [view]);

  const updatePlaybook = (
    category: string,
    value: PlaybookPosition,
    playbookMenuTitle: string,
    clientCreatedProfileId?: string,
    prebuiltProfile?: {}
  ) => {
    if (activeClient && activePlaybook) {
      let currentClient = activeClient;
      let currentPlaybook = activePlaybook;

      if (clientCreatedProfileId) {
        // find client playbook
        let clientPlaybook = currentClient.playbooks.find(
          (p) => p.id === clientCreatedProfileId
        );

        // update playbook positions
        if (clientPlaybook) {
          currentPlaybook = {
            ...(clientPlaybook as IPlaybook),
            positions: clientPlaybook.positions,
            active: true,
          };
        }
      } else {
        // find default playbook
        let defaultPlaybook = currentClient.playbooks.find(
          (p) => p.clientCreated === false
        );

        if (defaultPlaybook) {
          currentPlaybook = {
            clientCreated: false,
            active: true,
            dateSaved: new Date(),
            playbookName: playbookMenuTitle as string,
            positions: prebuiltProfile
              ? prebuiltProfile
              : {
                  ...currentPlaybook.positions,
                  [category]: value,
                },
            id: defaultPlaybook?.id as string,
          };
        }
      }

      savePlaybookToClient(currentPlaybook);
    }
  };

  const savePlaybookToClient = (updatedPlaybook: IPlaybook) => {
    let currentClient = activeClient;
    if (currentClient) {
      // update all playbooks to be active: false
      currentClient.playbooks = currentClient.playbooks.map((playbook) => ({
        ...playbook,
        active: false,
      }));

      // filter out all playbooks that don't match that ID
      currentClient.playbooks = currentClient.playbooks.filter(
        (p) => p.id !== updatedPlaybook?.id
      );

      currentClient.playbooks.push(updatedPlaybook);

      // update state
      setActivePlaybook({ ...(updatedPlaybook as IPlaybook) });
      setActiveClient({ ...currentClient });
    }
  };

  const resetPlaybook = () => {
    if (activeClient && activePlaybook) {
      let currentClient = activeClient;
      let currentPlaybook = activePlaybook;

      Object.keys(currentPlaybook.positions).forEach(
        (category) => (currentPlaybook.positions[category] = 0)
      );

      currentClient.playbooks = currentClient.playbooks.filter(
        (playbook) => playbook.active !== true
      );
      currentClient.playbooks.push(currentPlaybook);

      setActiveClient({ ...currentClient });
    }
  };

  const updateClient = async (client: IClient) => {
    // setTimeout(fetchClients, 1000);
    fetchClients();
    setActiveClient({ ...client });
  };

  const handlePlaybookSave = (
    playbookMenuTitle: string,
    isClientCreatedPlaybook: boolean
  ) => {
    if (user && activeClient && activePlaybook) {
      // save a copy of active states
      let updatedPlaybook = activePlaybook;
      let updatedClient = activeClient;

      // refresh user to reset all the playbook activity in the state
      fetchClients();

      // set all playbooks to active: false
      updatedClient.playbooks = updatedClient.playbooks.map((playbook) => ({
        ...playbook,
        active: false,
      }));

      //  filter out ID of activePlaybook from, add it back to user.
      updatedClient.playbooks = updatedClient.playbooks.filter(
        (playbook) => playbook.id !== updatedPlaybook.id
      );

      updatedPlaybook.positions = Object.fromEntries(
        Object.entries(updatedPlaybook.positions).filter(
          ([k, _]) => k != null && k != ""
        )
      );

      updatedClient.playbooks.push(updatedPlaybook);

      let newUser = user;
      let oldClient = newUser.clients.find(
        (client) => client.id === activeClient.id
      );

      if (oldClient != updatedClient) {
        newUser.clients = user.clients.filter(
          (client) => client.id != activeClient.id
        );
        newUser.clients.push(updatedClient);
        doUpdateUser(newUser)
          .then(() => {
            toast.success("Profile saved", {autoClose: 1000 });
          })
          .catch((e) => {
            console.log(e);
            toast.warning("Profile failed to save");
          });
      }
    }
  };

  const renderView = (user: IUser) => {
    switch (view) {
      case ClientView.CLIENT_PAGE:
        return activeClient ? (
          <Client
            user={user}
            urlClientId={urlClientId}
            urlPortfolioId={urlPortfolioId}
            view={view}
            setActiveClient={setActiveClient}
            setEditUserModalVisible={setEditUserModalVisible}
            handleClientSelection={handleClientSelection}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            client={activeClient}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
          />
        ) : (
          <></>
        );
      case ClientView.CLIENT_INSIGHT_REPORT:
        return activeClient ? (
          <Client
            user={user}
            urlClientId={urlClientId}
            urlPortfolioId={urlPortfolioId}
            view={view}
            setActiveClient={setActiveClient}
            setEditUserModalVisible={setEditUserModalVisible}
            handleClientSelection={handleClientSelection}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            client={activeClient}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
          />
        ) : (
          <></>
        );
      case ClientView.PROPOSALS:
        return activeClient ? (
          <Client
            view={view}
            setActiveClient={setActiveClient}
            user={user}
            setEditUserModalVisible={setEditUserModalVisible}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            client={activeClient}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            handleClientSelection={handleClientSelection}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
          />
        ) : (
          <></>
        );
      case ClientView.MODELS:
        return (
          <Models
            user={user}
            refreshUser={fetchClients}
            view={view}
            handleClientSelection={handleClientSelection}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            setEditUserModalVisible={setEditUserModalVisible}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
            query={query}
          />
        );
      case ClientView.CLIENT_MODELS:
        return (
          <Models
            user={user}
            refreshUser={fetchClients}
            view={view}
            handleClientSelection={handleClientSelection}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            setEditUserModalVisible={setEditUserModalVisible}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
            query={query}
          />
        );
      case ClientView.FUNDS:
        return (
          <Funds
            user={user}
            refreshUser={fetchClients}
            view={view}
            handleClientSelection={handleClientSelection}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            setEditUserModalVisible={setEditUserModalVisible}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
            query={query}
          />
        );
      case ClientView.CLIENT_FUNDS:
        return (
          <Funds
            user={user}
            refreshUser={fetchClients}
            view={view}
            handleClientSelection={handleClientSelection}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            setEditUserModalVisible={setEditUserModalVisible}
            handleViewChange={setView}
            activePlaybook={activePlaybook}
            setActivePlaybook={setActivePlaybook}
            updatePlaybook={updatePlaybook}
            resetPlaybook={resetPlaybook}
            setPortfoliosReady={setPortfoliosReady}
            portfoliosReady={portfoliosReady}
            displayRatio={displayRatio}
            setClientScore={setClientScore}
            setClientRatio={setClientRatio}
            updateClient={updateClient}
            handlePlaybookSave={handlePlaybookSave}
            fetchClients={fetchClients}
            toastIds={toastIds}
            setToastIds={setToastIds}
            setPlaybookVisible={setPlaybookVisible}
            playbookVisible={playbookVisible}
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteConfirmVisible={deleteConfirmVisible}
            setDeleteConfirmVisible={setDeleteConfirmVisible}
            query={query}
          />
        );
      case ClientView.CLIENT_MANAGEMENT:
        return (
          <ClientManagement
            handleViewChange={setView}
            handleFilter={handleFilter}
            query={query}
            setQuery={setQuery}
            activePlaybook={activePlaybook}
            user={user}
            addClient={addClient}
            handleClientSelection={handleClientSelection}
            fetchClients={fetchClients}
          />
        );
      case ClientView.REPORTS:
        return (
          <Reports
            user={user}
            query={query}
            activePlaybook={activePlaybook}
            addClient={addClient}
            handleClientSelection={handleClientSelection}
            fetchClients={fetchClients}
            handleViewChange={setView}
          />
        );
      case ClientView.ADMIN:
        return <Admin />;
      case ClientView.EXPORT_DATA:
        return (
          <ExportData
            playbook={activePlaybook}
            resetPlaybook={resetPlaybook}
            setPlaybook={setActivePlaybook}
          />
        );
    }
  };

  return user ? (
    <div id="appContainer">
      {user.role ? (
        <>
          <EditUserModal
            user={user}
            isActive={editUserModalVisible}
            onClose={() => (setEditUserModalVisible(false), fetchClients())}
          />
          <ToastContainer
            closeOnClick
            position="bottom-center"
            hideProgressBar
          />
          {view !== ClientView.CLIENT_INSIGHT_REPORT && (
            <>
              <Header
                setEditUserModalVisible={setEditUserModalVisible}
                findAndSetClient={findAndSetActiveClient}
                user={user}
                refreshUser={fetchClients}
                notifications={notifications}
                setNotifications={setNotifications}
                setNotificationsOpen={setNotificationsOpen}
                notificationsOpen={notificationsOpen}
              />
              <Hero
                user={user}
                activePlaybook={activePlaybook}
                view={view}
                filterItems={handleFilter}
                query={query}
                handleViewChange={setView}
                onEnterDuringSearch={() => {
                  const filteredClients = user.clients.filter(
                    (client: IClient) =>
                      client.name.toLowerCase().includes(query.toLowerCase())
                  );
                  if (filteredClients.length) {
                    handleClientSelection(filteredClients[0]);
                  }
                }}
                // client specific tingz
                clientScore={clientScore}
                displayRatio={displayRatio}
                activeClient={activeClient}
                portfoliosReady={portfoliosReady}
                clientRatio={clientRatio}
                toastIds={toastIds}
                setToastIds={setToastIds}
                onOpenPlaybook={() => {
                  fetchClients();
                  setPlaybookVisible(true);
                }}
                onBack={() => (
                  fetchClients(), setView(ClientView.CLIENT_MANAGEMENT)
                )}
                header={
                  view === ClientView.CLIENT_PAGE ||
                  view === ClientView.CLIENT_MODELS ||
                  view === ClientView.CLIENT_FUNDS ||
                  view === ClientView.PROPOSALS ? (
                    <>
                      <Button
                        preset="heroMini"
                        mini
                        onClick={() => setEditModalVisible(true)}
                      >
                        <Edit
                          style={{
                            marginRight: 8,
                            position: "relative",
                            bottom: 2,
                          }}
                          width={20}
                        />
                        Rename
                      </Button>
                      <Button
                        preset="heroMini"
                        mini
                        onClick={() => setDeleteConfirmVisible(true)}
                        style={{ marginLeft: 5 }}
                      >
                        <Trash
                          style={{
                            marginRight: 8,
                            position: "relative",
                            bottom: 2,
                          }}
                          width={20}
                        />
                        <span>Delete</span>
                      </Button>
                    </>
                  ) : (
                    <>
                      {view === ClientView.MODELS && (
                        <>
                          <Button
                            preset="heroMini"
                            mini
                            onClick={() => setEditModalVisible(true)}
                          >
                            <Edit
                              style={{
                                marginRight: 8,
                                position: "relative",
                                bottom: 2,
                              }}
                              width={20}
                            />
                            Rename
                          </Button>
                        </>
                      )}
                    </>
                  )
                }
              />
            </>
          )}

          {renderView(user)}
        </>
      ) : (
        <LoadingModal
          message={
            <>
              <h2>Loading Account Details</h2>
              <a
                onClick={() => {
                  auth.signOut();
                }}
                style={{
                  color: "#008080",
                  textDecoration: "underline",
                }}
              >
                Signout
              </a>
            </>
          }
          isActive={true}
        />
      )}
    </div>
  ) : (
    <></>
  );
}
