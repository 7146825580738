import { useState, useEffect } from "react";
import axios from "axios";
import PlaybookPositions, {
  categories,
  PlaybookPosition,
} from "../../models/Resonate/playbook";
import { Row, Col } from "react-flexbox-grid";
import { isMobile, isTablet } from "react-device-detect";
import { PLAYBOOK_DEFINITIONS } from "../../constants";
import { PlaybookButton } from "../Portfolios/PlaybookButton";
import circleInfo from "../../images/circle-info-light-blue.svg";
import SelectMenu from "../SelectMenu";
import Button from "../Button";
import models from "../../constants";
import { hostUrl } from "../Firebase";
import Logo from "../../images/scoreboard-logo.svg";
import { Drawer } from "antd";
import { IPlaybook } from "../../models";
interface PlaybookProps {
  visible: boolean;
  onClose: () => void;
  setPlaybook: (playbook: IPlaybook) => void;
  resetPlaybook: () => void;
  playbook: IPlaybook;
}

export function AdvancedSearchValues(props: PlaybookProps) {
  const [playbookIsZero, setPlaybookIsZero] = useState<boolean>(false);
  const [playbookMenuTitle, setPlaybookMenuTitle] = useState<string>("");
  const [clientPlaybook, setClientPlaybook] = useState<PlaybookPositions>({});
  const [profileCompleted, setProfileCompleted] = useState<boolean>(false);
  const [screeningDefinition, setScreeningDefinition] = useState("");
  const [explainPlaybook, setExplainPlaybook] = useState<boolean>(false);

  const renderValueButton = (number: number, key: string) => {
    switch (number) {
      case -3 || -2 || -1:
        return (
          <PlaybookButton
            title={0}
            wordTitle="Exclude"
            selected={checkSelected(key, number as PlaybookPosition)}
            onClick={() => {
              updatePlaybook(key, number as PlaybookPosition);
              setPlaybookIsZero(false);
              setPlaybookMenuTitle("Custom Profile");
            }}
          />
        );
      case 1 || 2 || 3:
        return (
          <PlaybookButton
            title={0}
            wordTitle="Include"
            selected={checkSelected(key, number as PlaybookPosition)}
            onClick={() => {
              updatePlaybook(key, number as PlaybookPosition);
              setPlaybookIsZero(false);
              setPlaybookMenuTitle("Custom Profile");
            }}
          />
        );
      case 0:
        return (
          <PlaybookButton
            title={0}
            wordTitle="Off"
            selected={checkSelected(key, number as PlaybookPosition)}
            onClick={() => {
              updatePlaybook(key, number as PlaybookPosition);
              setPlaybookIsZero(false);
              setPlaybookMenuTitle("Custom Profile");
            }}
          />
        );
    }
  };

  const updatePlaybook = (category: string, value: PlaybookPosition) => {
    let updatedPlaybook = clientPlaybook;
    updatedPlaybook[category] = value;
    setClientPlaybook({ ...updatedPlaybook });
  };

  const handlePlaybookSave = (playbookMenuTitle: string) => {
    alert("save");
  };

  const checkSelected = (
    category: string,
    value: PlaybookPosition
  ): boolean => {
    if (Object.keys(clientPlaybook).includes(category)) {
      switch (true) {
        case value < 0 && clientPlaybook[category] < 0:
          return true;
        case value > 0 && clientPlaybook[category] > 0:
          return true;
        case value == 0 && clientPlaybook[category] == 0:
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const setProfile = (profile: { [key: string]: PlaybookPosition }) => {
    Object.entries(profile).forEach(([key, value]) => {
      updatePlaybook(key, value >= 0 ? 0 : value);
    });
  };

  const retrieveDefinition = (key: any) => {
    setScreeningDefinition((PLAYBOOK_DEFINITIONS as any)[key]);
  };

  return (
    <>
      <Drawer
        open={props.visible}
        // afterOpenChange={() => {
        //   setPlaybookEdited(false);
        //   setPlaybookMenuTitle(activePlaybook?.playbookName as string);
        // }}
        placement="left"
        width={window.innerWidth < 1030 ? "100%" : "70%"}
        title="Screening by Values"
        onClose={() => (
          props.onClose(),
          props.setPlaybook({ ...props.playbook, positions: clientPlaybook })
        )}
      >
        <div>
          <div>
            <Row style={{ backgroundColor: "#ffffff", padding: "20px" }}>
              <Col xs={4}>
                <SelectMenu
                  title="Preset Profiles"
                  persisentTitle={playbookMenuTitle}
                  activeState={playbookMenuTitle}
                  fullWidth
                  onClick={(value: string, title?: string) => {
                    setProfile(models[value]);
                    setPlaybookIsZero(false);
                    setPlaybookMenuTitle(title as string);
                  }}
                  options={[
                    {
                      title: "Christian (Catholic USCCB)",
                      value: "catholic",
                    },
                    {
                      title: "Christian (Protestant)",
                      value: "christian",
                    },
                  ]}
                />
              </Col>
              <Col xs={2} xsOffset={6}>
                <Button
                  onClick={() => {
                    setProfile(models["empty"]);
                    // props.setPlaybook({
                    //   ...props.playbook,
                    //   positions: models["EMPTY"],
                    // });
                    setPlaybookIsZero(true);
                    setPlaybookMenuTitle("Custom Profile");
                  }}
                  fullWidth
                >
                  Reset Values
                </Button>
              </Col>

              {/* <Col xs={2}>
                <Button
                  preset="formPrimary"
                  fullWidth
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  onClick={() => {
                    handlePlaybookSave(playbookMenuTitle);
                  }}
                >
                  Save Profile
                </Button>
              </Col> */}
            </Row>
          </div>
          <Row style={{ padding: "20px 30px" }}>
            <Col>
              {/* <h1
              style={{
                fontWeight: 700,
                fontSize: "2.5em",
                marginBottom: 10,
                color: "#062a49",
              }}
            >
              Let's get personal.
            </h1> */}
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  marginBottom: 0,
                }}
              >
                Note: If you click <strong>Exclude</strong>, that value will be
                excluded from your search results. If you click{" "}
                <strong>Include</strong>, that value will be included in your
                search results. If you click <strong>Off</strong>, that value
                will be ignored in your search results.
              </p>

              {/* <Button
                mini
                style={{ display: "inline-block", position: "static" }}
                onClick={() => setExplainPlaybook(!explainPlaybook)}
              >
                How do I use this?
              </Button> */}

              {explainPlaybook && (
                <>
                  <Row
                    style={{
                      marginTop: 20,
                      paddingTop: 20,
                      borderTop: "1px solid rgb(224, 231, 231)",
                    }}
                  >
                    <Col xs={12} sm={6}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 5,
                        }}
                      >
                        Your financial advisor would like to know what values
                        you want to support or oppose with your investment
                        portfolio. Please select the issues you want to
                        “Support” or “Oppose”, or those which are simply “Not
                        Important” to you in your investment portfolio. For
                        example, selecting <strong>-3</strong> for
                        “Abortifacients” means that you “Strongly Oppose”
                        abortifacient manufacturing and distribution companies
                        and want them minimized or excluded from your portfolio.
                        Selecting <strong>2</strong> for “Business Ethics” means
                        you want to “Support” companies which demonstrate above
                        average business ethics and elevate their position in
                        your portfolio.
                      </p>
                    </Col>
                    <Col xs={12} sm={6}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 15,
                        }}
                      >
                        The selections you make below will generate your
                        client's Resonate Score algorithm that can be used to
                        quickly and easily identify which stocks or funds are
                        most aligned and least aligned with your client's
                        personal values and empower them to make more informed
                        investment decisions to support what they care about
                        most.
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                          color: "#062a49",
                          marginBottom: 5,
                        }}
                      >
                        Fill out the profile and then click “Save Profile”. Your
                        responses will be automatically attached to your profile
                        and your advisor will be notified.
                      </p>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "rgb(232 235 235)",
                      padding: 0,
                      marginTop: 10,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "#062a49",
                        marginBottom: 0,
                        textAlign: "center",
                        padding: "5px 0",
                        display: "block",
                        width: "100%",
                        borderBottom: "2px solid rgb(215, 222, 222)",
                      }}
                    >
                      Screening Profile Legend:
                    </p>
                    <Col xs={12}>
                      <Row>
                        {[
                          { label: "Strongly Oppose", value: -3 },
                          { label: "Oppose", value: -2 },
                          { label: "Somewhat Oppose", value: -1 },
                          { label: "Not Important", value: 0 },
                          { label: "Somewhat Support", value: 1 },
                          { label: "Support", value: 2 },
                          { label: "Strongly Support", value: 3 },
                        ].map((button) => (
                          <Col
                            xs={12}
                            sm={1.5}
                            style={{
                              borderRight:
                                button.value === 3
                                  ? "0"
                                  : "2px solid rgb(215, 222, 222)",
                              padding: 10,
                              borderBottom: "2px solid rgb(215, 222, 222)",
                              textAlign: "center",
                            }}
                          >
                            <Row>
                              <Col
                                xs={4}
                                sm={12}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                  paddingTop: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PlaybookButton
                                    title={button ? button?.value : 0}
                                    style={{ marginRight: 5 }}
                                    selected={checkSelected(
                                      "yo",
                                      button?.value as PlaybookPosition
                                    )}
                                    onClick={() => {}}
                                  />
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                sm={12}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "0.9em",
                                    fontWeight: 600,
                                    height: 30,
                                    lineHeight: "1.1em",
                                    margin: "5px 0",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {button?.label}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {Object.entries(categories).map(([key, value]) => (
            <Row
              style={{
                padding: "10px 20px",
                borderTop: "1px solid #e0e7e7",
                marginTop: 0,
              }}
              className="playbookCriteriaRow"
            >
              {[-3, -2, -1, 0, 1, 2, 3].map((number) => (
                <Col>{renderValueButton(number, key)}</Col>
              ))}
              <Col xs={12} sm={5} style={{ paddingTop: 8, paddingBottom: 8 }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginBottom: 0,
                  }}
                >
                  {screeningDefinition ===
                  (PLAYBOOK_DEFINITIONS as any)[key] ? (
                    <a
                      style={{
                        color: "rgb(6, 42, 73)",
                        textDecoration: "none",
                      }}
                      onClick={() => setScreeningDefinition("")}
                    >
                      {value}{" "}
                      <img
                        src={circleInfo}
                        width="16"
                        style={{ marginLeft: 2, marginTop: -4 }}
                      />
                    </a>
                  ) : (
                    <a
                      style={{
                        color: "rgb(6, 42, 73)",
                        textDecoration: "none",
                      }}
                      onClick={() => retrieveDefinition(key)}
                    >
                      {value}{" "}
                      <img
                        src={circleInfo}
                        width="16"
                        style={{ marginLeft: 2, marginTop: -4 }}
                      />
                    </a>
                  )}
                </p>
              </Col>
              {screeningDefinition === (PLAYBOOK_DEFINITIONS as any)[key] && (
                <Col xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <p style={{ fontSize: "1em", marginBottom: 0 }}>
                    {screeningDefinition}
                  </p>
                </Col>
              )}
            </Row>
          ))}
        </div>
      </Drawer>
    </>
  );
}
