import { Plus } from "@styled-icons/feather/Plus";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import { PortfolioTemplate } from ".";
import LoadingIcon from "../../images/dual-ring-loading-grey.svg";
import Search from "../../images/search-icon-black.svg";
import { IClient, IPlaybook, Model, Portfolio } from "../../models";
import { UniversalSearchResultItem } from "../../models/Resonate/api";
import Button from "../Button";
import DollarInput from "../DollarInput";
import {
  doCreateOrUpdateModel,
  doUpdatePortfolio,
  hostUrl,
} from "../Firebase";

interface TickerSearchProps {
  portfolio: Portfolio | Model;
  setActivePortfolio: (p: Portfolio) => void;
  addCallback: () => void;
  activePlaybook: IPlaybook | null;
  client: IClient;
  template: any;
}

export default function TickerSearch(props: TickerSearchProps) {
  const [tickerQuery, setTickerQuery] = useState("");
  const [portfolioIsSearching, setPortfolioIsSearching] = useState("");
  const [tickerResults, setTickerResults] = useState<
    Array<UniversalSearchResultItem> | []
  >([]);
  const [displayDollarValue, setDisplayDollarValue] = useState<boolean>(false);
  const p = props.portfolio;
  const { setActivePortfolio } = props;
  const [hasResults, setHasResults] = useState(false);
  const [addedTickers, setAddedTickers] = useState<
    Array<{ instrumentId: number; positionSize: number }>
  >([]);

  const [dollarInputValue, setDollarInputValue] = useState<number | null>(null);

  const dollarRef: React.RefObject<HTMLInputElement> = useRef(null);

  const handleAddTickerToPortfolio = (portfolio: Portfolio) => {
    let newPortfolio = portfolio;

    let allHoldings = newPortfolio.holdings.concat(addedTickers);

    newPortfolio.holdings = allHoldings.filter(
      (item, pos) => allHoldings.indexOf(item) == pos
    );

    setDisplayDollarValue(false);
    if (props.template === PortfolioTemplate.MODEL) {
      doCreateOrUpdateModel(newPortfolio as Model);
    } else {
      doUpdatePortfolio(props.client, newPortfolio, props.template);
    }
    setTickerQuery("");
    setAddedTickers([]);
    toast.success("Ticker added successfully", {
      autoClose: 1000 // 1 seconds
    });
    props.addCallback();
    // setHasResults(false);
  };

  const handleEnterAddTicker = (
    portfolio: Portfolio,
    amount: number | null
  ) => {
    let holdingIds = portfolio.holdings.map((holding) => holding.instrumentId);
    if (amount && amount > 0) {
      if (holdingIds.includes(tickerResults[0].id)) {
        toast.warning("Cannot add duplicate ticker",{ autoClose: 1000} );
        return;
      }

      addedTickers.push({
        instrumentId: tickerResults[0].id,
        positionSize: amount,
      });
      setAddedTickers(addedTickers);
      handleAddTickerToPortfolio(portfolio);
    } else {
      toast.warning("Ticker value must be greater than zero");
    }
  };

  useEffect(() => {
    if (tickerQuery === "") {
      setHasResults(false);
      setTickerResults([]);
    } else {
      const delayDebounce = setTimeout(() => {
        axiosQuery();
      }, 800);
      return () => clearTimeout(delayDebounce);
    }
  }, [tickerQuery]);

  const axiosQuery = () => {
    setHasResults(false);
    Axios.get(`${hostUrl}/search/${tickerQuery}`)
      .then((response) => {
        let results = response.data as Array<UniversalSearchResultItem>;
        setTickerResults(results);
        setHasResults(true);
      })
      .catch(() => {
        setHasResults(false);
      });
  };

  return (
    <div className="cardRowContent cardRowContentTickerSearch">
      <Row>
        {/* <Col sm={4} xs={12}> */}
        <Col xs={12} sm={displayDollarValue ? 4 : 12}>
          <input
            style={{
              backgroundImage: `url(${Search})`,
            }}
            type="text"
            onFocus={() => setDisplayDollarValue(false)}
            className="tickerSearch"
            placeholder={
              p.holdings.length > 0 ? `Add Ticker` : `Add Your First Ticker`
            }
            value={
              displayDollarValue
                ? tickerResults[0].name
                : portfolioIsSearching === p.id
                ? tickerQuery
                : ""
            }
            onChange={(e) => {
              setTickerQuery(e.target.value);
              setActivePortfolio(p);
              setPortfolioIsSearching(p.id);
            }}
            onKeyDownCapture={(k) => {
              if (k.keyCode === 13 && tickerResults.length) {
                // handleEnterAddTicker(p);
                setDisplayDollarValue(true);
              }
            }}
          />
          {hasResults &&
          tickerQuery !== "" &&
          portfolioIsSearching === p.id &&
          !displayDollarValue ? (
            <div className="contextMenuContainer" id="tickerSearchResults">
              <ul>
                {tickerResults.map(
                  (item: UniversalSearchResultItem, index: number) => (
                    <li
                      key={item.name}
                      onClick={() => {
                        let data: Array<UniversalSearchResultItem> =
                          tickerResults;
                        data.unshift(data.splice(index, 1)[0]);
                        setTickerResults([...data]);
                        setDisplayDollarValue(true);
                      }}
                    >
                      <a>
                        <Row>
                          <Col xs={2}>
                            <h4>
                              <strong>{item.ticker}</strong>
                            </h4>
                          </Col>
                          <Col
                            xs={9}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <p>{item.name}</p>
                          </Col>
                        </Row>
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          ) : (
            !hasResults &&
            tickerQuery !== "" &&
            portfolioIsSearching === p.id && (
              <div className="contextMenuContainer" id="tickerSearchResults">
                <ul>
                  <li>
                    <a href="#">
                      <img width="30" src={LoadingIcon} />
                      Loading...
                    </a>
                  </li>
                </ul>
              </div>
            )
          )}
        </Col>
        <Col xl={2} sm={3} xs={6}>
          <div
            className="inputContainer dollarContainer"
            style={{ marginBottom: 0 }}
          >
            {displayDollarValue ? (
              <DollarInput
                key={p.id + "-search"}
                onSave={(value) => {
                  handleEnterAddTicker(p, value);
                }}
                onLoadValue={null}
                onChange={setDollarInputValue}
                autoFocus
                isAddTickerInput={true}
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col sm={2} xs={6}>
          {displayDollarValue && (
            <Button
              style={{ padding: "0.7em 1em 0.5em" }}
              onClick={() => {
                handleEnterAddTicker(p, dollarInputValue);
              }}
              leftAlign={true}
              iconWidth={true}
            >
              <Plus
                height={20}
                style={{ marginRight: 8, position: "relative", bottom: 2 }}
              />
              Add
            </Button>
          )}
        </Col>
        <Col xs={12} sm={4}>
          {/* <ul className="pointScore">
                      <li className="pointPositive">
                        <i>
                          <PlusSmall size="10" />
                        </i>
                        <span>Loading...</span>
                      </li>
                      <li className="pointNegative">
                        <i>
                          <Minus size="10" />
                        </i>
                        <span>0</span>
                      </li>
                    </ul> */}
        </Col>
      </Row>
    </div>
  );
}
