import { useContext, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import ThemeContext from "../../../contexts/ThemeContext";
import { IClient } from "../../../models";
import Button from "../../Button";
import { doCreateClient, doUpdateClient } from "../../Firebase";
import Modal from "../../Modal";
import { PortfolioTemplate } from "../../Portfolios";

interface CreateClientModalProps {
  isActive: boolean;
  onClose: (callbackData?: any) => void;
  client?: IClient;
  edit?: boolean;
  onCreateClientCallback?: (client: IClient) => void;
}

export function CreateClientModal(props: CreateClientModalProps) {
  const [clientFirstName, setClientFirstName] = useState(
    props.client ? props.client.name : ""
  );

  const [clientLastName, setClientLastName] = useState(
    props.client ? props.client.lastName : ""
  );

  const [clientEmail, setClientEmail] = useState(
    props.client ? props.client.email : ""
  );

  const { edit } = props;

  const { theme, setTheme } = useContext(ThemeContext);

  const handleSubmit = () => {
    const clientData: IClient = {
      name: clientFirstName,
      lastName: clientLastName,
      email: clientEmail,
      playbooks: props.client
        ? props.client.playbooks
        : [
            {
              active: true,
              clientCreated: false,
              playbookName: "Preset Profiles",
              positions: {},
              dateSaved: new Date(),
              id: uuidv4(),
            },
          ],
      id: props.client ? props.client.id : uuidv4(),
      portfolios: props.client ? props.client.portfolios : [],
      dateCreated:
        props.client && props.client.dateCreated
          ? props.client.dateCreated
          : new Date(),
      platform: props.client ? props.client.platform : [theme],
    };

    if (edit) {
      doUpdateClient(clientData, PortfolioTemplate.PORTFOLIO)
        .then(() => {
          toast.success("Client updated", {autoClose: 1000 });
          if (props.onCreateClientCallback)
            props.onCreateClientCallback(clientData);
          props.onClose(clientData);
        })
        .catch((e) => {
          toast.error("Client update failed", { autoClose: 1000 });
        });
    } else {
      doCreateClient(clientData, theme)
        .then(() => {
          toast.success("Client created!", {autoClose: 1000 });
          if (props.onCreateClientCallback)
            props.onCreateClientCallback(clientData);
          props.onClose();
          setClientEmail("");
          setClientFirstName("");
          setClientFirstName("");
        })
        .catch(() => {
          toast.error("Client creation failed!", { autoClose: 1000 });
          props.onClose();
        });
    }
  };

  return (
    <Modal
      modalTitle={edit ? "Edit Client" : "Add New Client"}
      isActive={props.isActive}
      onClose={props.onClose}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="client-name">Client First Name:</label>
              <input
                type="text"
                id="client-name"
                value={clientFirstName}
                onChange={(e) => setClientFirstName(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="client-name">Client Last Name:</label>
              <input
                type="text"
                id="client-name"
                value={clientLastName}
                onChange={(e) => setClientLastName(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="inputContainer">
              <label htmlFor="client-email">Client Email:</label>
              <input
                type="email"
                id="client-email"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
                placeholder="–"
              />
            </div>
          </Col>
        </Row>
      </form>
      <Row>
        <Col xs={12} sm={6}>
          <Button fullWidth onClick={props.onClose}>
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button preset="formPrimary" fullWidth onClick={handleSubmit}>
            {edit ? "Save" : "Add"}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
